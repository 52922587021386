import { useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

const Verify = () => {
    const { code } = useParams();
    const history = useHistory();

    //useEffect not needed here???

    useEffect( () => {
        fetch(`/api/verify-email?code=${code}`)
            .then( (result) => result.json() )
            .then( (result) => {
                if( 'err_msg' in result ) history.push('/');
                else history.push('/signin');
            })
        ;
    });

    return null;
}
 
export default Verify;