import { useState } from "react";
import useFetch from "../useFetch";
import isMobilePhone from 'validator/lib/isMobilePhone';

import '../../css/mobile/user.css';
import defaultProfileImg from '../../img/default-profile.png';

const UserProfile = ({ isAuthenticated, recheckAuthentication }) => {
    const [ middleName, setMiddleName ] = useState(null);
    const [ contactNumber, setContactNumber ] = useState(null);
    const [ isPending, setIsPending ] = useState(false);
    const [ errors, setErrors ] = useState([]); //set to empty array
    const [ message, setMessage ] = useState('');
    const [ fileSelected, setFileSelected ] = useState('');

    const { data: user } = useFetch('/api/user-profile');
    if( user && user.middle_name && middleName === null ) {
        setMiddleName(user.middle_name);
    }
    if( user && user.home_phone && contactNumber === null ) {
        setContactNumber(user.home_phone);
    }

    const handleSubmit = (e) => {
        e.preventDefault(); //will prevent default page load
        
        if( isMobilePhone( contactNumber ) || contactNumber === null || contactNumber === '' ) { //check valid format
            const userProfileForm = document.getElementById('user-profile-form');
            const formData = new FormData(userProfileForm);

            setIsPending(true);

            fetch('/api/user-profile', {
                method: 'POST',
                body: formData
            })
            .then( (result) => result.json() )
            .then( (result) =>  {
                if(result.successfulUpdate) {
                    if( result.result ) user.profile_pic = result.result; //if profile pic was uploaded, show new profile picture

                    setMessage('You have successfully updated your profile.');
                    setErrors('');
                    setFileSelected('');
                    recheckAuthentication(); //call this function to update picture in footer
                    window.scrollTo(0, 0); //scroll to top to see message
                } else if( Array.isArray(result) ) {
                    setErrors(result);
                    setMessage('');
                    window.scrollTo(0, 0); //scroll to top to see message
                } else {
                    setErrors([{ err_msg: 'Unable to update your profile.' }]);
                    setMessage('');
                    window.scrollTo(0, 0); //scroll to top to see message
                }
                setIsPending(false);
            });

        } else {
            setErrors([{ err_msg: 'Invalid contact number format.' }]);
            setMessage('');
            window.scrollTo(0, 0); //scroll to top to see message
        }
    }

    const inputFile = document.getElementById("uploadProfilePicture");
    const handleSelectPhoto = () => inputFile.click();
    const handlePhotoChange = () => {
        if( inputFile.files.length > 0 ) setFileSelected(inputFile.files[0].name);
        else setFileSelected('');
    }
    
    return (
        <div className="user-profile d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">My Profile</h3>
            { isAuthenticated && user &&

                <div className="d-flex justify-content-center mt-2">
                    <div className="user-form p-4 border rounded">
                        <form id="user-profile-form" onSubmit={ handleSubmit } enctype="multipart/form-data">
                            <div className="d-flex flex-column">

                                <div className="form-fields-container">
                                    { errors &&
                                        errors.map( (e) => (
                                            <div className="error mb-2" key={e.err_msg}>
                                                <span>{e.err_msg}</span>
                                            </div>
                                        ) )
                                    }
                                    { message &&
                                        <div className="success-message mb-2">
                                            <span>{message}</span>
                                        </div>
                                    }

                                    <div className="d-flex flex-column">
                                        <label htmlFor="first-name">First Name</label>
                                        <input type="text" id="first-name" className="input-text" value={ user.first_name } disabled />
                                    </div>

                                    <div className="d-flex flex-column mt-3">
                                        <label htmlFor="middle-name">Middle Name</label>
                                        <input type="text" id="middle-name" name="middle_name" className="input-text" value={ middleName } onChange={ (e) => setMiddleName(e.target.value) } maxlength="100" />
                                    </div>

                                    <div className="d-flex flex-column mt-3">
                                        <label htmlFor="last-name">Last Name</label>
                                        <input type="text" id="last-name" className="input-text" value={ user.last_name } disabled />
                                    </div>

                                    <div className="d-flex flex-column mt-3">
                                        <label htmlFor="email">Email</label>
                                        <input type="text" id="email" className="input-text" value={ user.email } disabled />
                                    </div>

                                    <div className="d-flex flex-column mt-3">
                                        <label htmlFor="contact-number">Contact Number</label>
                                        <input type="text" id="contact-number" name="home_phone" className="input-text" value={ contactNumber } onChange={ (e) => setContactNumber(e.target.value) } maxlength="100" />
                                    </div>
                                </div>
                                
                                <div className="profile-picture d-flex flex-column align-items-center mt-3">
                                    { user.profile_pic &&
                                        <img className="user-profile-pic mb-3" src={user.profile_pic} alt={user.first_name + " " + user.last_name} />
                                    }
                                    { !user.profile_pic &&
                                        <img src={defaultProfileImg} alt={user.first_name + " " + user.last_name} width="120" height="120" className="rounded-circle" />
                                    }
                                    <div>
                                        <input type="file" accept=".jpeg,.jpg,.png" onChange={handlePhotoChange} style={{visibility: "hidden", width: "1px"}} id="uploadProfilePicture" name="uploadProfilePicture"></input>
                                        <a onClick={handleSelectPhoto} className="btn btn-primary">
                                            <i class="bi bi-file-earmark-image"></i> Select Photo
                                        </a>
                                    </div>
                                    <p>Please select a jpg, jpeg, or png file for your profile picture. File size must be lower than 1MB. </p>
                                    { fileSelected &&
                                        <p className="fileSelected">File selected: { fileSelected }</p>
                                    }
                                </div>
                            </div>
                            
                            <div className="d-flex flex-column mt-3">
                                { !isPending && 
                                    <button className="btn btn-primary">
                                        <i class="bi bi-save"></i> Save
                                    </button> 
                                }
                                { isPending && 
                                    <button className="btn btn-primary" type="button" disabled>
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving ...
                                    </button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            }
        </div>
    );
}
 
export default UserProfile;