import { useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import useFetch from "../../useFetch";

import queryString from 'query-string';

const SearchForm = ({ path }) => {
    const history = useHistory();
    let queries = queryString.parse( useLocation().search ); //query string

    const { data: categoryList } = useFetch('/api/category-list'); //get category list
    const [ searchSelectedCategory, setSearchSelectedCategory ] = useState('All Categories');
    const [ searchKeywords, setSearchKeywords ] = useState('');
    const [ showSearchModal, setShowSearchModal ] = useState(null);

    const handleCloseSearchModal = () => setShowSearchModal(null);

    const handleShowSearch = (e) => {
        e.preventDefault(); //will prevent default page load
        setShowSearchModal(true);
        setSearchKeywords(''); //clear textbox
        setSearchSelectedCategory('All Categories'); //set to default
    }

    const handleCategoryChange = (cat) => {
        setSearchSelectedCategory(cat);
    }

    const handleSubmitSearch = (e) => {
        e.preventDefault(); //will prevent default page load
        setShowSearchModal(null);
        history.push(`${path}search?keyword=${encodeURIComponent(searchKeywords)}&category=${encodeURIComponent(searchSelectedCategory)}&page=1`);
    }

    useEffect( () => {
        const k = queries.keyword;
        const c = queries.category;

        if( k !== undefined ) setSearchKeywords(k);
        if( c !== undefined ) setSearchSelectedCategory(c);
    }, [] );

    return (
        <>
            <Link onClick={handleShowSearch}>
                <i className="bi bi-search"></i>
            </Link>
            <Modal show={showSearchModal} onHide={handleCloseSearchModal} className="mobile-modal-content" size="md" centered>
                <Modal.Header className="mobile-modal-header">
                    <button onClick={handleCloseSearchModal} type="button" id="close-modal-1" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="mobile-modal-body">
                    <div className="home-search my-2">
                        <form onSubmit={ handleSubmitSearch } className="search my-2 my-xl-0">
                            <div className="mr-1">
                                <div className="btn-group" role="group">
                                    <button id="btnGroupDrop1" type="button" className="btn-categories btn-grad dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        {searchSelectedCategory}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                        <li><span className="dropdown-item" onClick={ () => handleCategoryChange('All Categories') }>All Categories</span></li>

                                        { categoryList && categoryList.map( (category) => (
                                            <li key={ category.descr }>
                                                <span className="dropdown-item" onClick={ () => handleCategoryChange(category.descr) }>{category.descr}</span>
                                            </li>
                                        ) ) }

                                        <li><span className="dropdown-item" onClick={ () => handleCategoryChange('All Categories') }>&nbsp;</span></li> {/* chrome specific fix: not showing the last category */}
                                    </ul>
                                </div>
                            </div>

                            <div className="input-group mt-2">
                                <input value={searchKeywords} onChange={ (e) => setSearchKeywords(e.target.value) } type="text" className="form-control" placeholder="Find great products" />
                                <div className="input-group-append">
                                    <button className="btn-search btn-grad" type="submit">SEARCH</button>  
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
        
    );
}
 
export default SearchForm;