import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ProductList = ({ path, title, titleAsLink, listType, limit }) => {
    const [ products, setProducts ] = useState([]);

    useEffect( () => {
        if( limit !== "0" ) {
            fetch(`/api/product-list?listType=${listType}&limit=${limit}`)
                .then( res => { return res.json(); } )
                .then( result => { 
                    if( Array.isArray(result) ) { //if returned an array of results
                        setProducts(result);
                    }
                })
            ;
        }
    }, [] );

    return (
        <div className="mobile-product-list">
            <div className="d-flex my-1">
                { titleAsLink &&
                    <h5>{title}</h5>
                    // <Link to={ 'product-list/' + listType }>
                    //     <h5>{title}</h5>        
                    // </Link>
                }
                { !titleAsLink && <h4>{title}</h4> }
            </div>

            <div className="prod-list-container d-flex overflow-auto">

                { products.map( (p) => (
                    <Link to={ path + 'product/' + p.product_id} className="prod-link" key={p.product_id}>
                        <div className="product-container d-flex">
                            <div className="product-image" style={{ backgroundImage: `url(${p.prod_thumbnail})` }}></div>
                            <div className="product-details">
                                <span>{ p.prod_name.slice(0, 14) } { p.prod_name.length > 14 && '...' }</span>
                                {/* <div className="seller-rating">
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                </div> */}
                                <br />
                                <span>{p.prod_currency} {p.prod_price_with_fees.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            </div>
                            
                        </div>
                    </Link>
                ) ) }
                
            </div>
        </div>
    );
}
 
export default ProductList;