import { useState, useEffect } from "react";

import "../../css/mobile/seller-center.css";

import ShippedOrderDetails from "./UserShippedOrderDetails"
import UserReceiveOrder from "./UserReceiveOrder"
import ShippedOrderItems from "./UserShippedOrderItems"

const UserShippedOrders = () => {
        const [shippedOrders, setShippedOrders] = useState([]);
        const [rerenderToggle, setRerenderToggle] = useState(true); //used to trigger rerender after an insert order
    
        useEffect(() => {
            fetch('/api/user-shipped-orders')
                .then( res => { return res.json(); } )
                .then( data => { setShippedOrders(data); } );
        }, [rerenderToggle] );

        const triggerRerender = () => {
            setRerenderToggle( !rerenderToggle ); //set to opposite boolean value, just so state is changed and would trigger rerender
        }

    return(
        <div className="d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Shipped Orders</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="user-order p-3 border rounded">
                    {shippedOrders.length === 0 &&
                        <p className="text-center">No shipped orders found.</p>
                    }
                    {shippedOrders.length > 0 &&
                        <>
                            { shippedOrders.map( ( orders, index ) => (
                                <div key={index}>
                                    <span className="ml-1 title-limit" data-bs-toggle="tooltip" data-bs-html="true" title={orders.order_id}>Order # <strong className="mr-2">{orders.order_id}</strong> </span>
                                    <span className="ml-1 title-limit">Date Ordered: <strong>{orders.order_date}</strong></span>
                                        <div className="p-2 border border-secondary rounded mb-2">
                                            <small>
                                                <span>Status: <strong>{orders.order_status}</strong></span><br />
                                                <span>Date Shipped: <strong>{orders.shipped_date}</strong></span><br />
                                                <ShippedOrderItems orders={orders} index={index} triggerRerender={triggerRerender} shippedDate={orders.shipped_date} />
                                                <UserReceiveOrder orders={orders} index={index} triggerRerender={triggerRerender} />
                                                <ShippedOrderDetails orders={orders} index={index} />
                                            </small>
                                    </div>
                                </div>
                            ) ) }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default UserShippedOrders;