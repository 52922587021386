import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import MobileEnsureAuthenticated from './mobile/reusable/EnsureAuthenticated';
import MobileForwardAuthenticated from './mobile/reusable/ForwardAuthenticated';
import MobileFooter from './mobile/reusable/Footer';
import MobileHeader from './mobile/reusable/Header';
import MobileHome from './mobile/Home';
import MobileLegalDisclaimer from './mobile/LegalDisclaimer';
import MobileLegalMenu from './mobile/LegalMenu';
import MobileLegalPrivacyPolicy from './mobile/LegalPrivacyPolicy'
import MobileLegalTermsAndConditions from './mobile/LegalTermsAndConditions';
import MobileProductDetails from './mobile/ProductDetails';
import MobileRegister from './mobile/Register';
import MobileResetPassword from './mobile/ResetPassword';
import MobileResetPasswordSave from './mobile/ResetPasswordSave';
import MobileSearch from './mobile/Search';
import MobileSellerAddProduct from './mobile/SellerCenterAddProduct';
import MobileSellerMenu from './mobile/SellerCenterMenu';
import MobileSellerOrders from './mobile/SellerCenterOrders';
import MobileSellerProducts from './mobile/SellerCenterProducts';
import MobileSellerRefundRequests from './mobile/SellerCenterRefundRequests';
import MobileSellerRefundHistory from './mobile/SellerCenterRefundHistory';
import MobileSellerShippedOrders from './mobile/SellerCenterShippedOrders';
import MobileSignIn from './mobile/SignIn';
import MobileSignOut from './mobile/SignOut';
import MobileTitlesAndDescriptions from './mobile/reusable/TitlesAndDescriptions';
import MobileUserAddresses from './mobile/UserAddresses';
import MobileUserChangePassword from './mobile/UserChangePassword';
import MobileUserCheckout from './mobile/UserCheckout';
import MobileUserMenu from './mobile/UserMenu';
import MobileUserOrders from './mobile/UserOrders';
import MobileUserPaymentOptions from './mobile/UserPaymentOptions';
import MobileUserProfile from './mobile/UserProfile';
import MobileUserReceivedOrders from './mobile/UserReceivedOrders';
import MobileUserReturns from './mobile/UserReturns';
import MobileUserSecurityQuestions from './mobile/UserSecurityQuestions';
import MobileUserShippedOrders from './mobile/UserShippedOrders';
import MobileUserShoppingCart from './mobile/UserShoppingCart';
import MobileUserWishListAndFavorites from './mobile/UserWishListAndFavorites';


import MobileAddService from './mobile/services/AddService';
import MobileClientDeclinedBookings from './mobile/services/ClientDeclinedBookings';
import MobileClientMyBookings from './mobile/services/ClientMyBookings';
import MobileClientMyPendingRequests from './mobile/services/ClientMyPendingRequests';
import MobileManageServices from './mobile/services/ManageServices';
import MobileProviderBookedClients from './mobile/services/ProviderBookedClients';
import MobileProviderBookingRequests from './mobile/services/ProviderBookingRequests';
import MobileProviderDeclinedClients from './mobile/services/ProviderDeclinedClients';
import MobileServiceDetails from './mobile/services/ServiceDetails';
import MobileServicesMenu from './mobile/services/ServicesMenu';
import MobileUserBookService from './mobile/services/UserBookService';

// import EnsureAuthenticated from './EnsureAuthenticated';
// import Footer from './Footer';
// import ForwardAuthenticated from './ForwardAuthenticated';
// import Header from './Header';
// import Home from "./Home";
// import Home2 from "./Home2";
// import ProductDetails from './ProductDetails';
// //import ProductListPage from './ProductListPage';
// import Register from "./Register";
// import ResetPassword from "./ResetPassword";
// //import ResetPasswordSave from "./ResetPasswordSave";
// import Search from "./Search";
// import SellerCenter from "./SellerCenter";
// import SellerCenterAddProduct from "./SellerCenterAddProduct";
// import SellerCenterHistory from "./SellerCenterProductsHistory";
// import SellerCenterOrders from "./SellerCenterOrders";
// import SellerCenterProducts from "./SellerCenterProducts";
// import SellerCenterReturns from "./SellerCenterReturns";
// import SellerCenterReviews from "./SellerCenterReviews";
// import SellerCenterShippedOrders from "./SellerCenterShippedOrders";
// import SignIn from "./SignIn";
// import SignOut from './SignOut';
// import UserAddresses from './UserAddresses';
// import UserChangePassword from './UserChangePassword';
// import UserCheckout from './UserCheckout';
// import UserOrders from './UserOrders';
// import UserOrdersToReceive from './UserOrdersToReceive';
// import UserPaymentOptions from './UserPaymentOptions';
// import UserProductReviews from './UserProductReviews';
// import UserProfile from "./UserProfile";
// import UserReturns from './UserReturns';
// import UserSecurityQuestions from "./UserSecurityQuestions";
// import UserShoppingCart from './UserShoppingCart';
// import UserWishList from './UserWishList';

import Verify from "./Verify";


function App() {
    const [ isAuthenticated, setIsAuthenticated ] = useState(null);
    const [ firstName, setFirstName ] = useState(null);
    const [ profilePicture, setProfilePicture ] = useState(null);
    const [ recheckAuthToggle, setRecheckAuthToggle ] = useState(true); //toggle used to trigger rerender and authentication re-check

    const [ signInMessage, setSignInMessage ] = useState('');
    const [ signInMessageShown, setSignInMessageShown ] = useState(false);

    const [showHomeIntro, setShowHomeIntro] = useState(true);
    const setToFalseShowHomeIntro = () => setShowHomeIntro(false);

    const [ isProdAndIncompleteEnvVariables, setIsProdAndIncompleteEnvVariables ] = useState(false); //false by default
    const [ isProd, setIsProd ] = useState(false);

    const path = "/"; //was "/mobile/"
    
    useEffect( () => {
        fetch('/api/verify-user-authentication-cookie')
            .then( res => { return res.json(); } )
            .then( user => { 
                setIsAuthenticated( user.isAuthenticated );
                setFirstName( user.first_name );
                setProfilePicture( user.profile_pic );
                setIsProdAndIncompleteEnvVariables(user.isProdAndIncompleteEnvVariables);
                setIsProd(user.isProd);
            } );
    }, [recheckAuthToggle] );

    const recheckAuthentication = () => {
        //set to opposite boolean value, just so state will change and that would trigger rerender to recheck authentication
        setRecheckAuthToggle( !recheckAuthToggle );
    }

    const handleSetSignInMessage = msg => setSignInMessage(msg); //function to set sign in message
    const handleSetSignInMessageShown = value => setSignInMessageShown(value); //function to set is sign in message shown

    return (
        <Router>
            <div className="App">
                { isProdAndIncompleteEnvVariables &&
                    <Switch>
                        <Route path='*'>
                            <div style={{color: 'white', textAlign: 'center', marginTop: '20px'}}>Trivut Marketplace is currently updating. Please try again.</div>
                        </Route>
                    </Switch>
                }
                { !isProdAndIncompleteEnvVariables &&
                    <Switch>
                        <Route exact path={ path }>
                            <div className="mobile">
                                <MobileTitlesAndDescriptions page="home" />
                                <MobileHeader isHome={true} />
                                <MobileHome path={path} showHomeIntro={showHomeIntro} setToFalseShowHomeIntro={setToFalseShowHomeIntro} isProd={isProd} />
                                <MobileFooter page="home" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'legal-menu' }>
                            <MobileTitlesAndDescriptions page="legal-menu" />
                            <div className="mobile">
                                <MobileHeader />
                                <MobileLegalMenu path={path} />
                                <MobileFooter page="legal-menu" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'disclaimer' }>
                            <MobileTitlesAndDescriptions page="disclaimer" />
                            <div className="mobile">
                                <MobileHeader />
                                <MobileLegalDisclaimer />
                                <MobileFooter page="disclaimer" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'privacy-policy' }>
                            <MobileTitlesAndDescriptions page="privacy-policy" />
                            <div className="mobile">
                                <MobileHeader />
                                <MobileLegalPrivacyPolicy />
                                <MobileFooter page="privacy-policy" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'terms-and-conditions' }>
                            <MobileTitlesAndDescriptions page="terms-and-conditions" />
                            <div className="mobile">
                                <MobileHeader />
                                <MobileLegalTermsAndConditions path={path} />
                                <MobileFooter page="terms-and-conditions" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'product/:id' }>
                            <MobileTitlesAndDescriptions page="product-details" />
                            <div className="mobile">
                                <MobileHeader />
                                <MobileProductDetails isAuthenticated={isAuthenticated} path={path} />
                                <MobileFooter page="product-details" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'register' }>
                            <MobileTitlesAndDescriptions page="register" />
                            <div className="mobile">
                                <MobileForwardAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileRegister />
                                <MobileFooter page="register" path={path} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'reset-password' }>
                            <MobileTitlesAndDescriptions page="reset-password" />
                            <div className="mobile">
                                <MobileForwardAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileResetPassword />
                                <MobileFooter path={path} isProd={isProd} />
                            </div>
                        </Route>

                        <Route path={ path + 'reset-password/:code' }>
                            <MobileTitlesAndDescriptions page="reset-password" />
                            <div className="mobile">
                                <MobileForwardAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileResetPasswordSave handleSetSignInMessage={handleSetSignInMessage} handleSetSignInMessageShown={handleSetSignInMessageShown} />
                                <MobileFooter path={path} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'search' }>
                            <MobileTitlesAndDescriptions page="search" />
                            <div className="mobile">
                                <MobileHeader />
                                <MobileSearch path={path} />
                                <MobileFooter page="search" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'seller-center/add-product' }>
                            <MobileTitlesAndDescriptions page="seller-add-product" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileSellerAddProduct isAuthenticated={isAuthenticated} />
                                <MobileFooter page="seller-add-products" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'seller-center/menu' }>
                            <MobileTitlesAndDescriptions page="seller-menu" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileSellerMenu path={path} firstName={firstName} />
                                <MobileFooter page="seller-menu" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'seller-center/orders' }>
                            <MobileTitlesAndDescriptions page="seller-orders" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileSellerOrders isAuthenticated={isAuthenticated} />
                                <MobileFooter page="seller-orders" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'seller-center/products' }>
                            <MobileTitlesAndDescriptions page="seller-products" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileSellerProducts isAuthenticated={isAuthenticated} />
                                <MobileFooter page="seller-products" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'seller-center/return-refund-requests' }>
                            <MobileTitlesAndDescriptions page="seller-return-refund" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileSellerRefundRequests isAuthenticated={isAuthenticated} />
                                <MobileFooter page="seller-return-refund" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'seller-center/refund-history' }>
                            <MobileTitlesAndDescriptions page="seller-refund-history" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileSellerRefundHistory isAuthenticated={isAuthenticated} />
                                <MobileFooter page="seller-refund-history" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'seller-center/shipped-orders' }>
                            <MobileTitlesAndDescriptions page="seller-shipped-orders" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileSellerShippedOrders isAuthenticated={isAuthenticated} />
                                <MobileFooter page="seller-shipped-orders" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'service/:id' }>
                            <MobileTitlesAndDescriptions page="services-details" />
                            <div className="mobile">
                                <MobileHeader />
                                <MobileServiceDetails isAuthenticated={isAuthenticated} path={path} />
                                <MobileFooter page="services-details" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'services/add' }>
                            <MobileTitlesAndDescriptions page="services-add" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileAddService path={path} />
                                <MobileFooter page="services-add" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'services/client/declined-bookings' }>
                            <MobileTitlesAndDescriptions page="services-client-declined-bookings" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileClientDeclinedBookings path={path} profilePicture={profilePicture} />
                                <MobileFooter page="services-client-declined-bookings" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>
                        
                        <Route exact path={ path + 'services/client/my-bookings' }>
                            <MobileTitlesAndDescriptions page="services-client-my-bookings" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileClientMyBookings path={path} profilePicture={profilePicture} />
                                <MobileFooter page="services-client-my-bookings" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'services/client/my-pending-requests' }>
                            <MobileTitlesAndDescriptions page="services-client-my-pending-requests" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileClientMyPendingRequests path={path} profilePicture={profilePicture} />
                                <MobileFooter page="services-client-my-pending-requests" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'services/manage' }>
                            <MobileTitlesAndDescriptions page="services-manage" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileManageServices path={path} />
                                <MobileFooter page="services-manage" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'services/menu' }>
                            <MobileTitlesAndDescriptions page="services-menu" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileServicesMenu path={path} />
                                <MobileFooter page="services-menu" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>
                        
                        <Route exact path={ path + 'services/provider/booked-clients' }>
                            <MobileTitlesAndDescriptions page="services-provider-booked-clients" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileProviderBookedClients path={path} profilePicture={profilePicture} />
                                <MobileFooter page="services-provider-booked-clients" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>
                        
                        <Route exact path={ path + 'services/provider/booking-requests' }>
                            <MobileTitlesAndDescriptions page="services-provider-booking-requests" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileProviderBookingRequests path={path} profilePicture={profilePicture} />
                                <MobileFooter page="services-provider-booking-requests" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'services/provider/declined-clients' }>
                            <MobileTitlesAndDescriptions page="services-provider-declined-clients" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileProviderDeclinedClients path={path} profilePicture={profilePicture} />
                                <MobileFooter page="services-provider-declined-clients" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'signin' }>
                            <MobileTitlesAndDescriptions page="signin" />
                            <div className="mobile">
                                <MobileForwardAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileSignIn
                                    signInMessage={signInMessage} signInMessageShown={signInMessageShown} 
                                    handleSetSignInMessage={handleSetSignInMessage} handleSetSignInMessageShown={handleSetSignInMessageShown} 
                                />
                                <MobileFooter page="signin" path={path} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'signout' }>
                            <MobileSignOut path={path} />
                        </Route>

                        <Route exact path={ path + 'user/addresses' }>
                            <MobileTitlesAndDescriptions page="user-addresses" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileUserAddresses isAuthenticated={isAuthenticated} />
                                <MobileFooter page="user-addresses" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'user/book-service/:id' }>
                            <MobileTitlesAndDescriptions page="user-book-service" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileUserBookService path={path} />
                                <MobileFooter page="user-book-service" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'user/change-password' }>
                            <MobileTitlesAndDescriptions page="user-change-password" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileUserChangePassword path={path} />
                                <MobileFooter page="user-change-password" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'user/checkout' }>
                            <MobileTitlesAndDescriptions page="user-checkout" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileUserCheckout />
                                <MobileFooter page="user-checkout" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'user/menu' }>
                            <MobileTitlesAndDescriptions page="user-menu" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileUserMenu path={path} firstName={firstName} />
                                <MobileFooter page="user-menu" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'user/orders' }>
                            <MobileTitlesAndDescriptions page="user-orders" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileUserOrders path={path} firstName={firstName} />
                                <MobileFooter page="user-orders" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'user/payment-options' }>
                            <MobileTitlesAndDescriptions page="user-payment-options" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileUserPaymentOptions />
                                <MobileFooter page="user-payment-options" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'user/profile' }>
                            <MobileTitlesAndDescriptions page="user-profile" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileUserProfile isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                                <MobileFooter page="user-profile" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'user/received-orders' }>
                            <MobileTitlesAndDescriptions page="user-received-orders" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileUserReceivedOrders />
                                <MobileFooter page="user-received-orders" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'user/returns' }>
                            <MobileTitlesAndDescriptions page="user-returns" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileUserReturns />
                                <MobileFooter page="user-returns" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'user/security-questions' }>
                            <MobileTitlesAndDescriptions page="user-security-questions" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileUserSecurityQuestions />
                                <MobileFooter page="user-security-questions" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'user/shipped-orders' }>
                            <MobileTitlesAndDescriptions page="user-shipped-orders" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileUserShippedOrders path={path} />
                                <MobileFooter page="user-shipped-orders" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'user/shopping-cart' }>
                            <MobileTitlesAndDescriptions page="user-shopping-cart" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileUserShoppingCart path={path} />
                                <MobileFooter page="user-shopping-cart" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>

                        <Route exact path={ path + 'user/wish-list-and-favorites' }>
                            <MobileTitlesAndDescriptions page="user-wish-list-and-favorites" />
                            <div className="mobile">
                                <MobileEnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} path={path} />
                                <MobileHeader />
                                <MobileUserWishListAndFavorites path={path} isProd={isProd} />
                                <MobileFooter page="user-wish-list-and-favorites" path={path} isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} isProd={isProd} />
                            </div>
                        </Route>
                        


                        {/* <Route exact path="/">
                            <Home isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                        </Route>
                        <Route exact path="/home2">
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <Home2 />
                            <Footer />
                        </Route>
                        <Route path="/product/:id">
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <ProductDetails isAuthenticated={isAuthenticated} />
                            <Footer />
                        </Route>
                        <Route path="/product-list/:listType">
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <ProductListPage />
                            <Footer />
                        </Route>
                        <Route exact path="/register">
                            <ForwardAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <Register />
                            <Footer />
                        </Route>
                        <Route exact path="/reset-password">
                            <ForwardAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <ResetPassword />
                            <Footer />
                        </Route>
                        <Route path="/reset-password/:code">
                            <ForwardAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <ResetPasswordSave handleSetSignInMessage={handleSetSignInMessage} handleSetSignInMessageShown={handleSetSignInMessageShown} />
                            <Footer />
                        </Route>
                        <Route exact path="/search">
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <Search />
                            <Footer />
                        </Route>
                        <Route exact path="/signin">
                            <ForwardAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <SignIn 
                                signInMessage={signInMessage} signInMessageShown={signInMessageShown} 
                                handleSetSignInMessage={handleSetSignInMessage} handleSetSignInMessageShown={handleSetSignInMessageShown} 
                            />
                            <Footer />
                        </Route>
                        <Route exact path="/signout">
                            <SignOut />
                        </Route>
                        <Route exact path="/user/addresses">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <UserAddresses isAuthenticated={isAuthenticated} />
                            <Footer />
                        </Route>
                        <Route exact path="/user/change-password">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <UserChangePassword />
                            <Footer />
                        </Route>
                        <Route exact path="/user/checkout">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <UserCheckout />
                            <Footer />
                        </Route>
                        <Route exact path="/user/orders">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <UserOrders isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Footer />
                        </Route>
                        <Route exact path="/user/to-receive">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <UserOrdersToReceive isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Footer />
                        </Route>
                        <Route exact path="/user/payment-options">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <UserPaymentOptions />
                            <Footer />
                        </Route>
                        <Route exact path="/user/product-reviews">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <UserProductReviews isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Footer />
                        </Route>
                        <Route exact path="/user/profile">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <UserProfile isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Footer />
                        </Route>
                        <Route exact path="/user/returns">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <UserReturns isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Footer />
                        </Route>
                        <Route exact path="/user/security-questions">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <UserSecurityQuestions />
                            <Footer />
                        </Route>
                        <Route exact path="/user/shopping-cart">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <UserShoppingCart />
                            <Footer />
                        </Route>
                        <Route exact path="/user/wish-list">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <UserWishList />
                            <Footer />
                        </Route>
                        <Route exact path="/seller-center/products">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <SellerCenterProducts isAuthenticated={isAuthenticated} />
                            <Footer />
                        </Route>
                        <Route exact path="/seller-center/add-product">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <SellerCenterAddProduct isAuthenticated={isAuthenticated} />
                            <Footer />
                        </Route>
                        <Route exact path="/seller-center/products/history">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <SellerCenterHistory isAuthenticated={isAuthenticated} />
                            <Footer />
                        </Route>
                        <Route exact path="/seller-center/orders">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <SellerCenterOrders isAuthenticated={isAuthenticated} />
                            <Footer />
                        </Route>
                        <Route exact path="/seller-center/shipped-orders">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <SellerCenterShippedOrders isAuthenticated={isAuthenticated} />
                            <Footer />
                        </Route>
                        <Route exact path="/seller-center/returns">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <SellerCenterReturns isAuthenticated={isAuthenticated} />
                            <Footer />
                        </Route>
                        <Route exact path="/seller-center/reviews">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <SellerCenterReviews isAuthenticated={isAuthenticated} />
                            <Footer />
                        </Route>
                        <Route exact path="/seller-center">
                            <EnsureAuthenticated isAuthenticated={isAuthenticated} recheckAuthentication={recheckAuthentication} />
                            <Header isAuthenticated={isAuthenticated} firstName={firstName} profilePicture={profilePicture} />
                            <SellerCenter isAuthenticated={isAuthenticated} />
                            <Footer />
                        </Route> */}


                        
                        <Route path="/verify/:code">
                            <Verify />
                        </Route>
                        <Route path='*'>
                            <MobileTitlesAndDescriptions page="error-404" />
                            <div style={{color: 'white', textAlign: 'center', marginTop: '20px'}}>Error 404 - page not found</div>
                        </Route>
                    </Switch>
                }
            </div>
        </Router>
    );
}

export default App;