import { useEffect } from "react";

import Categories from "./reusable/Categories";
import ServiceList from "./reusable/services/ServiceList";
import ProductList from "./reusable/ProductList";

import bg_001 from '../../img/home/bg-001.jpg';
import bg_002 from '../../img/home/bg-002.jpg';
import bg_003 from '../../img/home/bg-003.jpg';

const Home = ({ path, showHomeIntro, setToFalseShowHomeIntro, isProd }) => {
    
    useEffect(() => {
        setTimeout(function(){
            setToFalseShowHomeIntro();
        }, 7000);
    }, []);
    
    return (
        <div className="mobile-home">
            { showHomeIntro &&
                <div className="app-intro">
                    <div className="app-intro-text">
                        <div><i className="moving-cart bi bi-cart4 bi-md align-middle" style={{color: '#FFD700'}}></i></div>
                        T<span className="smaller-font-size">RIVUT</span> M<span className="smaller-font-size">ARKETPLACE</span>
                        {/* <div className="mt-3" style={{fontSize: '.8rem', color: 'rgb(219 219 219)'}}>Products | Services</div> */}
                    </div>
                </div>
            }


            <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                    <img src={bg_001} class="d-block w-100" alt="..." />
                    </div>
                    <div class="carousel-item">
                    <img src={bg_002} class="d-block w-100" alt="..." />
                    </div>
                    <div class="carousel-item">
                    <img src={bg_003} class="d-block w-100" alt="..." />
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>

            <hr class="hr-products hr-text my-4" data-content="Products" />

            <Categories />

            <div className="my-3 px-3">
                <ProductList path={path} title="Recommended For You" titleAsLink={true} listType="random" limit="7" />
            </div>
            <div className="my-3 px-3">
                <ProductList path={path} title="Latest Additions" titleAsLink={true} listType="latest" limit="7" />
            </div>
            {/* <div className="px-3">
                <ProductList path={path} title="Used Items" titleAsLink={true} listType="used" limit="7" />
            </div> */}


            {/* { !isProd && */}
                <>
                    <hr class="hr-services hr-text my-4" data-content="Services" />
                    <div className="my-3 px-3">
                        <ServiceList path={path} title="Recommended For You" titleAsLink={true} listType="random" limit="7" />
                    </div>
                    <div className="my-3 px-3">
                        <ServiceList path={path} title="With Ratings" titleAsLink={true} listType="with-ratings" limit="7" />
                    </div>
                    <div className="my-3 px-3">
                        <ServiceList path={path} title="Latest Additions" titleAsLink={true} listType="latest" limit="7" />
                    </div>
                </>
            {/* } */}
            
            { !isProd &&
                <hr class="hr-looking-for hr-text my-4" data-content="Looking for" />
            }
            
        </div>
    );
}

export default Home;