import { useState, useEffect } from "react";

const UserShipReturnItem = ({returns, index, triggerRerender}) => {
    const [returnID] = useState(returns.return_id);
    const [itemID] = useState(returns.order_item_id);
    const [productID] = useState(returns.product_id);
    const [returnReason] = useState(returns.return_reason);
    const [showReturnOptions, setShowReturnOptions] = useState(true);
    const [returnOption, setReturnOption] = useState("");
    const [courier, setCourier] = useState("");
    const [trackingCode, setTrackingCode] = useState("");
    const [isPending, setIsPending] = useState(false);
    const [showShippingInfo, setShowShippingInfo] = useState(true);

    const [showReturnItemBtn, setShowReturnItemBtn] = useState(true);
    const [showReturnStatus, setShowReturnStatus] = useState(false);

    const changeCourier = (selectedCourier) => { setCourier(selectedCourier) }

    useEffect(() => {
            if (returnReason === "Invalid Courier/Tracking Details") {
                setShowReturnStatus(true);
                setShowReturnItemBtn(false);
            } else if (returnReason === "Item not received") {
                setShowReturnStatus(true);
                setShowReturnItemBtn(false);
            } else {
                setShowReturnStatus(false);
                setShowReturnItemBtn(true);
            }
    }, [triggerRerender] );

//    const returnOptions = (selectedOption) => {
//        if (selectedOption === "return") {
//            setShowShippingInfo(true);
//        } else {
//            setShowShippingInfo(false);
//        }
//        setReturnOption(selectedOption);
//    }

    const handleSubmit = (e) => {
        e.preventDefault(); //will prevent default page load

                // const returnForm = document.getElementById("return-form-" + index);
                // const formData = new FormData(returnForm);

                setIsPending(true);

                const returnItemDetails = {
                    product_id: productID,
                    // return_option: returnOption,
                    courier: courier,
                    tracking_code: trackingCode
                };
    
                fetch(`/api/user-return-item/${returnID}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(returnItemDetails)
                })
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( "successfulUpdate" in result && result.successfulUpdate ) {
                        document.getElementById("btn-close-modal-" + index).click(); //close the modal
                        setIsPending(false);
                        // triggerRerender(); //trigger rerender of parent component
                        window.location.href = "/user/returns";
                    }
                    else { //unsuccessful insert
                        alert("Something went wrong!");
                    }
                });
    
                // fetch(`/api/user-return-item/${returnID}`, {
                //     method: "POST",
                //     body: formData
                // })
                // .then( (result) => result.json() )
                // .then( (result) => {
                //     if( "successfulInsert" in result && result.successfulInsert ) {
                //         document.getElementById("btn-close-modal-" + index).click();
                //         // triggerRerender(); //trigger rerender of parent component
                //         window.location.href = "/user/returns"
                //     } else {
                //         alert("An error has occurred!");
                //     }
                //     setIsPending(false);
                // });
    }

    return(
        <>
            {
                showReturnItemBtn?
                    <button type="button" className="btn btn-warning btn-sm pb-2" style={{width: "160px", height: "35px"}} data-bs-toggle="modal" data-bs-target={"#return-item-modal-" + index}>
                        <small>Ship Return Item</small>
                    </button>
                :null
            }
            {
                showReturnStatus?
                    <><strong>Status: </strong><span className="badge bg-success">Refund Approved</span></>
                :null
            }
            {/* <form id={"return-form-" + index} onSubmit={handleSubmit} encType="multipart/form-data"> */}
            <form onSubmit={handleSubmit}>
                <div className="modal fade" id={"return-item-modal-" + index} tabIndex="-1" aria-labelledby={"return-item-label-" + index} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id={"return-item-label-" + index}>Return Item</h5>
                                <button type="button" id={"btn-close-modal-" + index} className="btn-close btn-close-white btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex flex-column">
                                   Return Item:
                                   <span className="title-limit fw-light">{returns.return_product}</span>
                                   {/* <input id="returns" name="returns" type="text" value={ returnID } hidden  />
                                   <input id="item" name="item" type="text" value={ itemID } hidden  />
                                   <input id="product" name="product" type="text" value={ productID } hidden  /> */}
                                </div>
                                    {/* {
                                       showReturnOptions?
                                           <div className="d-flex flex-column">
                                               <label htmlFor="options">Return Options: </label>
                                               <select id="options" name="options" className="form-select form-select-sm small-text" style={{height: '38px'}} value={ returnOption } onChange={ (e) => returnOptions(e.target.value) } >
                                                   <option defaultValue value="">Select Option...</option>
                                                   <option value="return">I will return and ship item for refund</option>
                                                   <option value="noReturn">Return not applicable (eg. broken glass, shattered ceramic, etc.)</option>
                                               </select>
                                           </div>
                                       :null
                                   } */}
                                   {/* {
                                       showShippingInfo?
                                           <> */}
                                               <div className="d-flex flex-column mt-1">
                                                   <label htmlFor="courier">Courier: </label>
                                                   <select id="courier" name="courier" className="form-select form-select-sm small-text" style={{height: '38px'}} value={ courier } onChange={ (e) => changeCourier(e.target.value) } >
                                                       <option defaultValue value="">Select Courier...</option>
                                                       <option value="2GO Express">2GO Express</option>
                                                       <option value="Air 21">Air 21</option>
                                                       <option value="Airspeed">Airspeed</option>
                                                       <option value="AP Cargo">AP Cargo</option>
                                                       <option value="DHL">DHL</option>
                                                       <option value="Grab Express">Grab Express</option>
                                                       <option value="J&T Express">J&T Express</option>
                                                       <option value="JRS Express">JRS Express</option>
                                                       <option value="Lalamove">Lalamove</option>
                                                       <option value="LBC">LBC</option>
                                                       <option value="Ninja Van">Ninja Van</option>
                                                       <option value="UPS">UPS</option>
                                                       <option value="XDE Logistics">XDE Logistics</option>
                                                   </select>
                                               </div>
                                               <div className="d-flex flex-column mt-1">
                                                   <label htmlFor="tracking">Tracking Code: </label>
                                                   <input id="tracking" name="tracking" type="text" className="form-control form-control-sm small-text" maxLength="200" style={{height: '38px'}} value={ trackingCode } onChange={ (e) => setTrackingCode(e.target.value) } />
                                               </div>
                                           {/* </>
                                       :null
                                   } */}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><small>Cancel</small></button>
                                { !isPending && 
                                    <button type="submit" className="btn btn-success btn-sm"><small>Return/Refund</small></button>
                                }
                                { isPending && 
                                    <button className="btn btn-secondary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )

}

export default UserShipReturnItem;

// const UserShipReturnItem = ({returns, index, triggerRerender}) => {
//     const [returnID] = useState(returns.return_id);
//     const [itemID] = useState(returns.order_item_id);
//     const [productID] = useState(returns.product_id);
//     const [returnReason] = useState(returns.return_reason);
//     const [showReturnOptions, setShowReturnOptions] = useState(false);
//     const [returnOption, setReturnOption] = useState("");
//     const [courier, setCourier] = useState("");
//     const [trackingCode, setTrackingCode] = useState("");
//     const [isPending, setIsPending] = useState(false);
//     const [showShippingInfo, setShowShippingInfo] = useState(false)

//     const changeCourier = (newCourier) => { setCourier(newCourier) }

//     const returnOptions = (newOption) => {
//         if (newOption === "return") {
//             setShowShippingInfo(true);
//         } else {
//             setShowShippingInfo(false);
//         }
//         setReturnOption(newOption);
//     }

//     useEffect(() => {
//         if (returnReason === "Invalid Courier/Tracking Details" || returnReason === "Item not received" || returnReason === "Damaged or faulty item"){
//             setShowReturnOptions(true);
//             setShowShippingInfo(false);
//         } else {
//             setShowReturnOptions(false);
//             setShowShippingInfo(true);
//         }
//     },[] );

//     const handleSubmitReturn = (e) => {
//         e.preventDefault(); //will prevent default page load

//         const returnForm = document.getElementById("return-form" + index);
//         const formData = new FormData(returnForm);

//         console.log(returnForm.returnid);
//         console.log(returnForm.itemid);
//         console.log(returnForm.productid);
//         console.log(itemID);

//         setIsPending(true);

//         fetch(`/api/user-return-item/${returnID}`, {
//             method: "POST",
//             body: formData
//         })
//         .then( (result) => result.json() )
//         .then( (result) => {
//             if( "successfulInsert" in result && result.successfulInsert ) {
//                 document.getElementById("btn-close-return-modal-" + index).click();
//                 // triggerRerender(); //trigger rerender of parent component
//                 window.location.href = "/user/returns"
//             } else {
//                 alert("An error has occurred!");
//             }
//                 setIsPending(false);
//         });
            
//     }

//     return (
//         <>
            
//             <button type="button" className="btn btn-warning btn-sm pb-2" style={{width: "120px", height: "35px"}} data-bs-toggle="modal" data-bs-target={"#item-return-modal-" + index}>
//                 <small>Return Item</small>
//             </button>
//             {index}
//             <form id={"ret-form-" + index} onSubmit={handleSubmitReturn} encType="multipart/form-data">
//                 <div className="modal fade" id={"item-return-modal-" + index} tabIndex="-1" aria-labelledby={"order-refund-label-" + index} aria-hidden="true">
//                     <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm ">
//                         <div className="modal-content">
//                             <div className="modal-header">
//                                 <h5 className="modal-title" id={"order-refund-label-" + index}>Return Item</h5>
//                                 <button type="button" id={"btn-close-return-modal-" + index} className="btn-close btn-close-white btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
//                             </div>
//                             <div className="modal-body">
//                                 <div className="d-flex flex-column">
//                                     Return Item:
//                                     <span className="title-limit fw-light">{returns.return_product}</span>
//                                     { returnID } { itemID } { productID }
//                                     <input id="returns" name="returns" type="text" value={ returnID } readOnly  />
//                                     <input id="item" name="item" type="text" value={ itemID } readOnly  />
//                                     <input id="product" name="product" type="text" value={ productID } readOnly  />
//                                 </div>
//                                 <div>
//                                 {returns.return_reason}
//                                     {
//                                         showReturnOptions?
//                                             <div className="d-flex flex-column">
//                                                 <label htmlFor="options">Return Options: </label>
//                                                 <select id="options" name="options" className="form-select form-select-sm small-text" style={{height: '38px'}} value={ returnOption } onChange={ (e) => returnOptions(e.target.value) } >
//                                                     <option defaultValue value="">Select Option...</option>
//                                                     <option value="return">I will return and ship item for refund</option>
//                                                     <option value="noReturn">Return not applicable (eg. broken glass, shattered ceramic, etc.)</option>
//                                                 </select>
//                                             </div>
//                                         :null
//                                     }
//                                     {
//                                         showShippingInfo?
//                                             <>
//                                                 <div className="d-flex flex-column mt-1">
//                                                     <label htmlFor="courier">Courier: </label>
//                                                     <select id="courier" name="courier" className="form-select form-select-sm small-text" style={{height: '38px'}} value={ courier } onChange={ (e) => changeCourier(e.target.value) } >
//                                                         <option defaultValue value="">Select Courier...</option>
//                                                         <option value="2GO Express">2GO Express</option>
//                                                         <option value="Air 21">Air 21</option>
//                                                         <option value="Airspeed">Airspeed</option>
//                                                         <option value="AP Cargo">AP Cargo</option>
//                                                         <option value="DHL">DHL</option>
//                                                         <option value="Grab Express">Grab Express</option>
//                                                         <option value="J&T Express">J&T Express</option>
//                                                         <option value="JRS Express">JRS Express</option>
//                                                         <option value="Lalamove">Lalamove</option>
//                                                         <option value="LBC">LBC</option>
//                                                         <option value="Ninja Van">Ninja Van</option>
//                                                         <option value="UPS">UPS</option>
//                                                         <option value="XDE Logistics">XDE Logistics</option>
//                                                     </select>
//                                                 </div>
//                                                 <div className="d-flex flex-column mt-1">
//                                                     <label htmlFor="tracking">Tracking Code: </label>
//                                                     <input id="tracking" name="tracking" type="text" className="form-control form-control-sm small-text" maxLength="200" style={{height: '38px'}} value={ trackingCode } onChange={ (e) => setTrackingCode(e.target.value) } />
//                                                 </div>
//                                             </>
//                                         :null
//                                     }
//                                 </div>
//                             </div>
//                             <div className="modal-footer">
//                                 <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><small>Cancel</small></button>
//                                 { !isPending && 
//                                     <button type="submit" className="btn btn-success btn-sm"><small>Submit</small></button>
//                                 }
//                                 { isPending && 
//                                     <button className="btn btn-success btn-sm" type="button" disabled>
//                                         <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
//                                     </button>
//                                 }
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </form>
//         </>
//     )
// }

// export default UserShipReturnItem;