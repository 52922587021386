import { useState } from "react";

const UserAddressEdit = ({ address, index, triggerRerender, countries }) => { 
    const [ editAddressID ] = useState( address.address_id );
    const [ editAddressType, setEditAddressType ] = useState( address.addr_type );
    const [ editAddressIsDefault, setEditAddressIsDefault ] = useState( address.is_default );
    const [ editAddressAddr1, setEditAddressAddr1 ] = useState( address.address1 );
    const [ editAddressAddr2, setEditAddressAddr2 ] = useState( address.address2 );
    const [ editAddressCity, setEditAddressCity ] = useState( address.city );
    const [ editAddressState, setEditAddressState ] = useState( address.state_territory );
    const [ editAddressZip, setEditAddressZip ] = useState( address.postal_code );
    const [ editAddressCountry, setEditAddressCountry ] = useState( address.country );
    
    const [ isPending, setIsPending ] = useState(false);
    const [ formUnsubmitted, setFormUnsubmitted ] = useState(true);

    const handleEditAddress = (e) => {
        e.preventDefault(); //will prevent default page load

        setFormUnsubmitted(false);

        //check required fields
        if( editAddressAddr1.trim() !== "" && editAddressCity.trim() !== "" && editAddressState.trim() !== "" && editAddressZip.trim() !== "" && editAddressCountry.trim() !== "" ) {
            setIsPending(true);

            const editAddress = {
                is_default: editAddressIsDefault,
                addr_type: editAddressType,
                addr1: editAddressAddr1,
                addr2: editAddressAddr2,
                city: editAddressCity,
                state: editAddressState,
                zip: editAddressZip,
                country: editAddressCountry
            };

            fetch(`/api/user-edit-address/${editAddressID}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(editAddress)
            })
            .then( (result) => result.json() )
            .then( (result) =>  {
                if( 'successfulUpdate' in result && result.successfulUpdate ) { //successful edit
                    document.getElementById(`close-modal-${index}`).click(); //close the modal
                    
                    setFormUnsubmitted(true); //set default

                    triggerRerender(); //trigger rerender of parent component
                }
                else { //unsuccessful update
                    alert("Something went wrong!");
                }
                setIsPending(false);
            });
        }
    }

    return (
        <form onSubmit={handleEditAddress}>
            <div className="modal" id={ "modal-edit-address-" + index } tabIndex="-1" aria-labelledby={ "modal-edit-address-title-" + index } aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header mobile-modal-header">
                            <h5 className="modal-title" id={ "modal-edit-address-title-" + index }>Edit address</h5>
                            <button type="button" id={ "close-modal-" + index } className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex flex-column">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id={ "is-default-address-" + index } 
                                        checked={editAddressIsDefault} onChange={ (e) => setEditAddressIsDefault(e.target.checked) } />
                                    <label className="form-check-label" htmlFor={ "is-default-address-" + index }>Set as default address</label>
                                </div>

                                <div className="address-type d-sm-flex mt-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="select-address-type" id={ "home-address-" + index }
                                            value="Home" onChange={ (e) => setEditAddressType(e.target.value) }
                                            defaultChecked={ editAddressType === 'Home' } />
                                        <label className="form-check-label" htmlFor={ "home-address-" + index }>Home</label>
                                    </div>
                                    <div className="form-check ml-sm-4">
                                        <input className="form-check-input" type="radio" name="select-address-type" id={ "office-address-" + index }
                                            value="Office" onChange={ (e) => setEditAddressType(e.target.value) }
                                            defaultChecked={ editAddressType === 'Office' } />
                                        <label className="form-check-label" htmlFor={ "office-address-" + index }>Office</label>
                                    </div>
                                    <div className="form-check ml-sm-4">
                                        <input className="form-check-input" type="radio" name="select-address-type" id={ "other-address-" + index }
                                            value="Other" onChange={ (e) => setEditAddressType(e.target.value) }
                                            defaultChecked={ editAddressType === 'Other' } />
                                        <label className="form-check-label" htmlFor={ "other-address-" + index }>Other</label>
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <input type="text" id={ "edit-address1-" + index } placeholder="Address 1" 
                                        className={ formUnsubmitted || editAddressAddr1 ? 'input-text' : 'input-text border-color-red' } 
                                        value={ editAddressAddr1 } onChange={ (e) => setEditAddressAddr1(e.target.value) }/>
                                </div>

                                <div className="mt-3">
                                    <input type="text" id={ "edit-address2-" + index } placeholder="Address 2" className="input-text"
                                        value={ editAddressAddr2 } onChange={ (e) => setEditAddressAddr2(e.target.value) } />
                                </div>

                                <div className="mt-3">
                                    <input type="text" id={ "edit-address-city-" + index } placeholder="City"
                                        className={ formUnsubmitted || editAddressCity ? 'input-text' : 'input-text border-color-red' }
                                        value={ editAddressCity } onChange={ (e) => setEditAddressCity(e.target.value) } />
                                </div>

                                <div className="mt-3">
                                    <input type="text" id={ "edit-address-state-" + index } placeholder="State/Province"
                                        className={ formUnsubmitted || editAddressState ? 'input-text' : 'input-text border-color-red' }
                                        value={ editAddressState } onChange={ (e) => setEditAddressState(e.target.value) } />
                                </div>

                                <div className="mt-3">
                                    <input type="text" id={ "edit-address-zip-" + index } placeholder="Postal Code"
                                        className={ formUnsubmitted || editAddressZip ? 'input-text' : 'input-text border-color-red' }
                                        value={ editAddressZip } onChange={ (e) => setEditAddressZip(e.target.value) } />
                                </div>

                                <div className="mt-3">
                                    { countries &&
                                        <select className="form-select my-1" onChange={ (e) => setEditAddressCountry(e.target.value) } defaultValue={ editAddressCountry } value={editAddressCountry}>
                                            { countries.map( (c) => (
                                                <option key={ c.country } value={c.country}>
                                                    {c.country}
                                                </option>
                                            ) ) }
                                        </select>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary">
                                <i className="bi bi-save"></i> Update Address
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
 
export default UserAddressEdit;