import { useState } from "react";

const SellerCenterEditProduct = ({products, index, triggerRerender}) => {
    const [productID] = useState(products.product_id);
    const [isActive, setIsActive] = useState(1);
    const [productName, setProductName] = useState(products.product_name);
    const [productDescription, setProductDescription] = useState(products.product_description);
    const [count, setCount] = useState(products.product_description.length);
    const [price, setPrice] = useState(products.product_price);
    const [currency, setCurrency] = useState(products.product_currency);
    const [quantity, setQuantity] = useState(products.product_quantity);
    const [location, setLocation] = useState(products.product_location);
    const [sku, setSku] = useState(products.product_sku);
    const [manufacturer, setManufacturer] = useState(products.product_manufacturer);
    const [serialNumber, setSerialNumber] = useState(products.product_serial_number);
    const [model, setModel] = useState(products.product_model_name);
    const [modelNumber, setModelNumber] = useState(products.product_model_number);
    const [warrantyType, setWarrantyType] = useState(products.product_warranty);
    const [warrantyPeriod, setWarrantyPeriod] = useState(products.product_warranty_period);
    const [dimensionWidth, setDimensionWidth] = useState(products.product_dim_width);
    const [dimensionLength, setDimensionLength] = useState(products.product_dim_length);
    const [dimensionHeight, setDimensionHeight] = useState(products.product_dim_height);
    const [weight, setWeight] = useState(products.product_weight);
    const [isPending, setIsPending] = useState(false);

    const descriptionCount = (descr) => {
        setProductDescription(descr);
        setCount(descr.length);
    }

    const changeCurrency = (currency) => { setCurrency(currency) }
    const changeLocation = (newLocation) => { setLocation(newLocation) }
    const changeWarrantyType = (warrantyType) => { setWarrantyType(warrantyType) }

    const handleEditProduct = (e) => {
        e.preventDefault(); //will prevent default page load
        setIsPending(true);

        let prod_status = "active"

        if(document.getElementById(`is-active${index}`).checked){
            prod_status = "active"
        } else {
            prod_status = "on-hold"
        }

        if(productName.trim() !== "" &&  productDescription.trim() !== "" && price !== "" && quantity !== "" && location !== "Select Province..." ) {
            const updatedProduct = {
                product_status: prod_status,
                name: productName,
                description: productDescription,
                price: price,
                price_currency: currency,
                quantity: quantity,
                location: location,
                sku: sku,
                manufacturer: manufacturer,
                serial: serialNumber,
                model: model,
                model_num: modelNumber,
                warranty_type: warrantyType,
                warranty_expiry: warrantyPeriod,
                dim_width: dimensionWidth,
                dim_length: dimensionLength,
                dim_height: dimensionHeight,
                weight: weight
            };

            fetch(`/api/seller-center/edit-product/${productID}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedProduct)
            })
            .then( (result) => result.json() )
            .then( (result) =>  {
                console.log(result);
                if( 'successfulUpdate' in result && result.successfulUpdate ) { //successful edit
                    document.getElementById(`btn-close-modal-${index}`).click(); //close the modal

                    triggerRerender(); //trigger rerender of parent component
                    setIsPending(false);
                }
                else { //unsuccessful update
                    alert("Something went wrong!");
                }
            });
        }
    }

    return(
        <>
            <button type="button" className="btn btn-warning btn-sm" data-bs-toggle="modal" data-bs-target={"#edit-product-modal-" + index}>
                <small>Update</small>
            </button>
            <form onSubmit={handleEditProduct}>
                <div className="modal fade" id={"edit-product-modal-" + index} tabIndex="-1" aria-labelledby={"edit-product-label-" + index} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <p className="modal-title edit-title-limit" id={"edit-product-label-" + index}>{products.product_name}</p>
                                <button type="button" id={"btn-close-modal-" + index} className="btn-close btn-close-white btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="form-check form-switch">
                                    {products.product_status === 'active' &&
                                        <input className="form-check-input" type="checkbox" id={"is-active" + index} checked={isActive} onChange={ (e) => setIsActive(e.target.checked) }/>
                                    }
                                    {products.product_status !== 'active' &&
                                        <input className="form-check-input" type="checkbox" id={"is-active" + index} onChange={ (e) => setIsActive(e.target.checked) }/>
                                    }
                                    <label className="form-check-label product-listing" htmlFor={"is-active" + index}>Available</label>
                                </div>
                                <div className="row mb-1">
                                    <label htmlFor="name" className="col-sm-2 col-form-label product-listing">Product title: </label>
                                    <div className="col-sm-10">
                                        <input id={"name" + index} name={"name" + index} type="text" className="form-control product-listing" maxLength="200" value={ productName } onChange={ (e) => setProductName(e.target.value) } required />
                                    </div>
                                </div>
                                <div className="row">
                                    <label htmlFor={"description" + index} className="col-sm-2 col-form-label product-listing">Description: </label>
                                    <div className="col-sm-10">
                                        <textarea id={"description" + index} name={"description" + index} className="form-control product-listing mb-1" rows="3" maxLength="2000" value={ productDescription } onChange={ (e) => descriptionCount(e.target.value) } required></textarea>
                                        <p className="text-end"><small>{count}/2000</small></p>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <label htmlFor={"price" + index} className="col-sm-2 col-form-label product-listing">Price: </label>
                                    <div className="col-sm-4">
                                        <div className="input-group mb-3">
                                            <select id={"currency" + index} name={"currency" + index} className="form-select product-listing" value={ currency } onChange={ (e) => changeCurrency(e.target.value) } required>
                                                <option value="PHP" defaultValue>PHP</option>
                                                <option value="USD">USD</option>
                                            </select>
                                            <input id={"price" + index} name={"price" + index} type="number" aria-label="price" className="form-control price-edit-field product-listing" value={ price } onChange={ (e) => setPrice(e.target.value) } required/>
                                        </div>
                                    </div>
                                    <label htmlFor={"quantity" + index} className="col-sm-2 col-form-label product-listing">Qty: </label>
                                    <div className="col-sm-4">
                                        <input id={"quantity" + index} name={"quantity" + index} type="number" aria-label="quantity" className="form-control product-listing" value={ quantity } onChange={ (e) => setQuantity(e.target.value) } required/>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <label htmlFor={"location" + index} className="col-sm-2 col-form-label product-listing">Location: </label>
                                    <div className="col-sm-4">
                                        <select id={"location" + index} name={"location" + index} className="form-select product-listing" value={location} onChange={ (e) => changeLocation(e.target.value) } required>
                                            <option defaultValue>Select Province...</option>
                                            <option value="Abra">Abra</option>
                                            <option value="Agusan del Norte">Agusan del Norte</option>
                                            <option value="Agusan del Sur">Agusan del Sur</option>
                                            <option value="Aklan">Aklan</option>
                                            <option value="Albay">Albay</option>
                                            <option value="Antique">Antique</option>
                                            <option value="Apayao">Apayao</option>
                                            <option value="Aurora">Aurora</option>
                                            <option value="Basilan">Basilan</option>
                                            <option value="Bataan">Bataan</option>
                                            <option value="Batanes">Batanes</option>
                                            <option value="Batangas">Batangas</option>
                                            <option value="Benguet">Benguet</option>
                                            <option value="Biliran">Biliran</option>
                                            <option value="Bohol">Bohol</option>
                                            <option value="Bukidnon">Bukidnon</option>
                                            <option value="Bulacan">Bulacan</option>
                                            <option value="Cagayan">Cagayan</option>
                                            <option value="Camarines Norte">Camarines Norte</option>
                                            <option value="Camarines Sur">Camarines Sur</option>
                                            <option value="Camiguin">Camiguin</option>
                                            <option value="Capiz">Capiz</option>
                                            <option value="Catanduanes">Catanduanes</option>
                                            <option value="Cavite">Cavite</option>
                                            <option value="Cebu">Cebu</option>
                                            <option value="Cotabato">Cotabato</option>
                                            <option value="Davao de Oro">Davao de Oro</option>
                                            <option value="Davao del Norte">Davao del Norte</option>
                                            <option value="Davao del Sur">Davao del Sur</option>
                                            <option value="Davao Occidental">Davao Occidental</option>
                                            <option value="Davao Oriental">Davao Oriental</option>
                                            <option value="Dinagat Islands">Dinagat Islands</option>
                                            <option value="Eastern Samar">Eastern Samar</option>
                                            <option value="Guimaras">Guimaras</option>
                                            <option value="Ifugao">Ifugao</option>
                                            <option value="Ilocos Norte<">Ilocos Norte</option>
                                            <option value="Ilocos Sur">Ilocos Sur</option>
                                            <option value="Iloilo">Iloilo</option>
                                            <option value="Isabela">Isabela</option>
                                            <option value="Kalinga">Kalinga</option>                                                
                                            <option value="La Union">La Union</option>
                                            <option value="Laguna">Laguna</option>
                                            <option value="Lanao del Norte">Lanao del Norte</option>
                                            <option value="Lanao del Sur">Lanao del Sur</option>
                                            <option value="Leyte">Leyte</option>
                                            <option value="Maguindanao">Maguindanao</option>
                                            <option value="Marinduque">Marinduque</option>
                                            <option value="Masbate">Masbate</option>
                                            <option value="Misamis Occidental">Misamis Occidental</option>
                                            <option value="Misamis Oriental">Misamis Oriental</option>
                                            <option value="Mountain Province">Mountain Province</option>
                                            <option value="Negros Occidental">Negros Occidental</option>
                                            <option value="Negros Oriental">Negros Oriental</option>
                                            <option value="Northern Samar">Northern Samar</option>
                                            <option value="Nueva Ecija">Nueva Ecija</option>
                                            <option value="Nueva Vizcaya">Nueva Vizcaya</option>
                                            <option value="Occidental Mindoro">Occidental Mindoro</option>
                                            <option value="Oriental Mindoro">Oriental Mindoro</option>
                                            <option value="Palawan">Palawan</option>
                                            <option value="Pampanga">Pampanga</option>
                                            <option value="Pangasinan">Pangasinan</option>
                                            <option value="Quezon">Quezon</option>
                                            <option value="Quirino">Quirino</option>
                                            <option value="Rizal">Rizal</option>
                                            <option value="Romblon">Romblon</option>
                                            <option value="Samar">Samar</option>
                                            <option value="Sarangani">Sarangani</option>
                                            <option value="Siquijor">Siquijor</option>
                                            <option value="Sorsogon">Sorsogon</option>
                                            <option value="South Cotabato">South Cotabato</option>
                                            <option value="Southern Leyte">Southern Leyte</option>
                                            <option value="Sultan Kudarat">Sultan Kudarat</option>
                                            <option value="Sulu">Sulu</option>
                                            <option value="Surigao del Norte">Surigao del Norte</option>
                                            <option value="Surigao del Sur">Surigao del Sur</option>
                                            <option value="Tarlac">Tarlac</option>
                                            <option value="Tawi-Tawi">Tawi-Tawi</option>
                                            <option value="Zambales">Zambales</option>
                                            <option value="Zamboanga del Norte">Zamboanga del Norte</option>
                                            <option value="Zamboanga del Sur">Zamboanga del Sur</option>
                                            <option value="Zamboanga Sibugay">Zamboanga Sibugay</option>
                                            <option value="Metro Manila">Metro Manila</option>
                                        </select>
                                    </div>
                                    <label htmlFor={"sku" + index} className="col-sm-2 col-form-label product-listing">SKU: </label>
                                    <div className="col-sm-4">
                                        <input id={"sku" + index} name={"sku" + index} type="text" className="form-control product-listing" maxLength="200" value={ sku } onChange={ (e) => setSku(e.target.value) } />
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <label htmlFor={"manufacturer" + index} className="col-sm-2 col-form-label product-listing">Manufacturer: </label>
                                    <div className="col-sm-4">
                                        <input id={"manufacturer" + index} name={"manufacturer" + index} type="text" className="form-control product-listing" maxLength="200" value={ manufacturer } onChange={ (e) => setManufacturer(e.target.value) } />
                                    </div>
                                    <label htmlFor={"serial" + index} className="col-sm-2 col-form-label product-listing">Serial: </label>
                                    <div className="col-sm-4">
                                        <input id={"serial" + index} name={"serial" + index} type="text" className="form-control product-listing" maxLength="200" value={ serialNumber } onChange={ (e) => setSerialNumber(e.target.value) } />
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <label htmlFor={"model" + index} className="col-sm-2 col-form-label product-listing">Model: </label>
                                    <div className="col-sm-4">
                                        <input id={"model" + index} name={"model" + index} type="text" className="form-control product-listing" maxLength="200" value={ model } onChange={ (e) => setModel(e.target.value) } />
                                    </div>
                                    <label htmlFor={"model-number" + index} className="col-sm-2 col-form-label product-listing">Model: </label>
                                    <div className="col-sm-4">
                                        <input id={"model-number" + index} name={"model-number" + index} type="text" className="form-control product-listing" maxLength="200" value={ modelNumber } onChange={ (e) => setModelNumber(e.target.value) } />
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <label htmlFor={"warranty-type" + index} className="col-sm-2 col-form-label product-listing">Warranty Type: </label>
                                    <div className="col-sm-4 pt-2">
                                        <select id={"warranty-type" + index} name={"warranty-type" + index} className="form-select product-listing" value={ warrantyType } onChange={ (e) => changeWarrantyType(e.target.value) }>
                                            <option disabled>Select warranty...</option>
                                            <option value="international">International Warranty</option>
                                            <option value="manufacturer">Manufacturer Warranty</option>
                                            <option value="supplier">Supplier Warranty</option>
                                            <option value="personal">Personal Warranty</option>
                                            <option value="no-warranty" defaultValue>No Warranty</option>
                                        </select>
                                    </div>
                                    <label htmlFor={"warranty-expiry" + index} className="col-sm-2 col-form-label product-listing">Warranty Expiry: </label>
                                    <div className="col-sm-4 pt-2">
                                        {products.product_warranty_period === "" &&
                                            <input id={"warranty-expiry" + index} name={"warranty-expiry" + index} placeholder="MM/DD/YYYY" type="date" className="form-control product-listing" value={ warrantyPeriod } onChange={ (e) => setWarrantyPeriod(e.target.value) } />
                                        }
                                        {products.product_warranty_period !== "" &&
                                            <input id={"warranty-expiry" + index} name={"warranty-expiry" + index} placeholder="MM/DD/YYYY" type="text" className="form-control product-listing" value={ warrantyPeriod } onChange={ (e) => setWarrantyPeriod(e.target.value) } />
                                        }
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <label htmlFor={"width" + index} className="col-sm-2 col-form-label product-listing">Dimension: </label>
                                    <div className="col-sm-3">
                                        <div className="input-group">
                                            <input id={"width" + index} name={"width" + index} type="number" className="form-control edit-product-listing" placeholder="width(cm)" aria-label="Width(cm)" aria-describedby="width-addon" value={ dimensionWidth } onChange={ (e) => setDimensionWidth(e.target.value) } />
                                            <span className="input-group-text edit-product-listing" id="width-addon">cm</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="input-group">
                                            <input id={"length" + index} name={"length" + index} type="number" className="form-control edit-product-listing" placeholder="length(cm)" aria-label="Length(cm)" aria-describedby="length-addon" value={ dimensionLength } onChange={ (e) => setDimensionLength(e.target.value) } />
                                            <span className="input-group-text edit-product-listing" id="length-addon">cm</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div div className="input-group">
                                            <input id={"height" + index} name={"height" + index} type="number" className="form-control edit-product-listing" placeholder="height(cm)" aria-label="Height(cm)" aria-describedby="height-addon" value={ dimensionHeight } onChange={ (e) => setDimensionHeight(e.target.value) } />
                                            <span className="input-group-text edit-product-listing" id="height-addon">cm</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <label htmlFor={"weight" + index} className="col-sm-2 col-form-label product-listing">Weight: </label>
                                    <div className="col-sm-3">
                                        <div className="input-group">
                                            <input id={"weight" + index} name={"weight" + index} type="number" className="form-control edit-product-listing" placeholder="weight(kg)" aria-label="Weight(kg)" aria-describedby="weight-addon" value={ weight } onChange={ (e) => setWeight(e.target.value) } />
                                            <span className="input-group-text edit-product-listing" id="weight-addon">kg</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                { !isPending && 
                                    <button type="submit" className="btn btn-primary btn-sm">Update</button>
                                }
                                { isPending && 
                                    <button className="btn btn-primary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default SellerCenterEditProduct;