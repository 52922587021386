import { useState, useEffect } from "react";

import "../../css/mobile/seller-center.css";

import ProcessOrder from "./SellerCenterProcessOrder"
import SellerOrderItems from "./SellerCenterOrderItems"

const SellerCenterOrders = () => {
    const [sellerOrders, setSellerOrders] = useState([]);
    const [rerenderToggle, setRerenderToggle] = useState(true); //used to trigger rerender after processing orders

    useEffect(() => {
        fetch('/api/seller-center/orders')
            .then( res => { return res.json(); } )
            .then( data => { setSellerOrders(data); } );
    }, [rerenderToggle] );

    const triggerRerender = () => {
        setRerenderToggle( !rerenderToggle ); //set to opposite boolean value, just so state is changed and would trigger rerender
    }

    return(
        <div className="d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Orders</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="seller-form p-3 border rounded">
                    {sellerOrders.length === 0 &&
                        <p className="text-center">No orders found.</p>
                    }
                    {sellerOrders.length > 0 &&
                        <>
                            { sellerOrders.map( ( orders, index ) => (
                                <div key={index}>
                                    <small>
                                        Order # <span className="fw-bold mr-3">{orders.order_id}</span><br/>
                                        Order Date: <span className="fw-bold">{orders.order_date}</span>
                                            <div className="p-2 border border-secondary rounded mb-2">
                                                <div className="p-1 mb-2">
                                                    <SellerOrderItems orders={orders} index={index} triggerRerender={triggerRerender} />
                                                </div>
                                                <div className="text-end mb-2">
                                                    <ProcessOrder orders={orders} index={orders.order_id} triggerRerender={triggerRerender} />
                                                </div>
                                            </div>
                                    </small>
                                </div>
                            ) ) }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default SellerCenterOrders;