import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Favorites from "./reusable/services/Favorites";

import UserSCartWListFavoritesDelete from "./UserSCartWListFavoritesDelete";

import '../../css/mobile/user.css';

const UserWishListAndFavorites = ({ path, isProd }) => {
    const [ wishList, setWishList ] = useState(null);
    const [ rerenderToggle, setRerenderToggle ] = useState(true); //used to trigger rerender after an insert, update, or delete address
    const [ isLoading, setIsLoading ] = useState(true);

    useEffect( () => {
        fetch('/api/user-wish-list')
            .then( res => { return res.json(); } )
            .then( data => {
                setWishList(data);
                setIsLoading(false);
            });
    }, [rerenderToggle] );

    const triggerRerender = () => {
        setRerenderToggle( !rerenderToggle ); //set to opposite boolean value, just so state is changed and would trigger rerender
    }

    return (
        <div className="mobile-container d-flex flex-column">
            <div className="wish-list pt-3 px-3">
                <h3 className="d-flex justify-content-center">
                    <i className="fas fa-tshirt mr-2 text-primary"></i> My Wish List
                </h3>
                
                { isLoading &&
                    <div className="d-flex justify-content-center user-form rounded py-4 border-primary mt-3">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>
                }

                { !isLoading && wishList &&
                    <div className="d-flex flex-column mt-3">
                        
                            { wishList.map( ( product, index ) => (
                                <div className="product-container" key={index}>
                                    <Link to={`${path}product/` + product.product_id}>
                                        <div className="alert" role="alert">
                                            {product.product_name}
                                        </div>
                                    </Link>
                                    <UserSCartWListFavoritesDelete deleteFrom="wish-list" product={product} triggerRerender={triggerRerender} />
                                </div>
                            ) ) }
                            { wishList.length === 0 &&
                                <div className="user-form border border-primary rounded py-4">
                                    <h5 className="text-center">No products available.</h5>
                                </div>
                            }
                    </div>
                }    
            </div>

            { !isProd && 
                <Favorites path={path} />
            }
        </div>
    );
}
 
export default UserWishListAndFavorites;