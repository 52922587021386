import { useState, useEffect } from "react";
import ManageServicesEdit from "./ManageServicesEdit";

const ManageServices = ({ path }) => {
    const [servicesList, setServicesList] = useState([]);
    const [triggerReload, setTriggerReload] = useState(true);
    const [editID, setEditID] = useState(null);
    const [unclosedID, setUnclosedID] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    useEffect( () => {
        fetch('/api/services-manage')
            .then( res => { 
                if(res.ok) return res.json();
                throw new Error('No service found.');
            }).then( result => {
                setServicesList(result);

                setIsLoading(false);
            }).catch( (e) => {
                //alert(e.message);
            })
        ;
    }, [triggerReload] );

    const handleDeleteService = (id, name) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete this service? \n\n${name}`);

        if(confirmDelete) { //proceed with deletion
            fetch(`/api/services-manage-delete?id=${id}`)
                .then( res => { 
                    if(res.ok) return res.json();
                    throw new Error('Unable to delete service.');
                }).then( result => {
                    if( 'successfulDelete' in result && result.successfulDelete ) { //successfully deleted
                        setTriggerReload(!triggerReload);
                    }
                }).catch( (e) => {
                    alert(e.message);
                })
            ;
        }
    }

    const handleEditService = (id, divID) => {
        setEditID(id);

        if( unclosedID === null ) setUnclosedID(id);
        else if( unclosedID !== null && unclosedID === id ) setUnclosedID(null);
        else if( unclosedID !== null && unclosedID !== id ) {
            document.getElementById( 'service-details-' + unclosedID ).classList.remove("show"); //close the unclosed
            setUnclosedID(id);
        }

        window.scrollTo(0, document.getElementById(divID).offsetTop); //scroll to top of service to see message
    }

    const reloadList = () => {
        setTriggerReload(!triggerReload);
    }

    return (
        <div className="services-manage services d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Manage Services</h3>
            
            <div className="d-flex justify-content-center mt-3">
                <div className="user-form border-0">

                    { isLoading && 
                        <div className="d-flex justify-content-center user-form rounded py-4">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </div>
                    }

                    { !isLoading && servicesList.length === 0 &&
                        <div className="d-flex justify-content-center user-form rounded py-4">
                            No services available
                        </div>
                    }

                    { !isLoading && servicesList.length > 0 && servicesList.map( (s, i) => (
                        <div id={ 'div-edit-' + s.service_id } key={s.service_id} className={ i === 0 ? '' : 'mt-3' }>
                            <h6>{s.serv_name}</h6>
                            <div className="user-form p-3 border rounded mt-1">

                                <div className="d-flex">
                                    { s.profile_pic_thumb &&
                                        <img src={s.profile_pic_thumb} alt={'Profile Picture (' + s.service_id + ')' } style={{borderRadius: '5px'}} />
                                    }
                                    <div className="mx-2">
                                        { s.serv_status === "Active" && 
                                            <span className="badge rounded-pill bg-primary status-pill lh-4">{s.serv_status}</span>
                                        }
                                        { s.serv_status === "Inactive" && 
                                            <span className="badge rounded-pill bg-secondary status-pill lh-4">{s.serv_status}</span>
                                        }
                                        
                                        <h6 className="mt-2">
                                            { 'Date Added: ' + s.insert_dt }
                                        </h6>
                                        <div>
                                            <button onClick={ () => handleDeleteService(s.service_id, s.serv_name) } className="btn btn-sm btn-danger mt-2 mr-2">Delete</button>
                                            <button onClick={ () => handleEditService( s.service_id, 'div-edit-' + s.service_id ) } className="btn btn-sm btn-warning mt-2" data-bs-toggle="collapse" data-bs-target={ '#service-details-' + s.service_id } aria-expanded="false" aria-controls={ '#service-details-' + s.service_id }>Update</button>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="collapse" id={ 'service-details-' + s.service_id }>
                                    <hr />
                                    { editID === s.service_id && 
                                        <ManageServicesEdit id={s.service_id} divID={ 'div-edit-' + s.service_id } reloadList={reloadList} />
                                    }
                                </div>
                            </div>

                        </div>
                    ))}
                    
                </div>
            </div>
        </div>
    );
}
 
export default ManageServices;