import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import RatingStars from '../RatingStars'

const ServiceList = ({ path, title, titleAsLink, listType, limit }) => {
    const [ services, setServices ] = useState([]);

    useEffect( () => {
        if( limit !== "0" ) {
            fetch(`/api/service-list?listType=${listType}&limit=${limit}`)
                .then( res => { return res.json(); } )
                .then( result => { 
                    if( Array.isArray(result) ) { //if returned an array of results
                        setServices(result);
                    }
                })
            ;
        }
    }, [] );

    return (
        <>
            { services && services.length > 0 &&
                <div className="mobile-service-list">
                    <div className="d-flex my-1">
                        { titleAsLink &&
                            <h5>{title}</h5>
                        }
                        { !titleAsLink && <h4>{title}</h4> }
                    </div>

                    <div className="serv-list-container d-flex overflow-auto">

                        { services.map( (s) => (
                            <Link to={ path + 'service/' + s.service_id} className="serv-link" key={s.service_id}>
                                <div className="service-container d-flex">
                                    <div className="service-image" style={{ backgroundImage: `url(${s.serv_thumbnail})` }}></div>
                                    <div className="service-details">
                                        <span>{ s.serv_name.slice(0, 14) } { s.serv_name.length > 14 && '...' }</span>
                                        <br />
                                        <span>{s.serv_payment_currency} {s.serv_payment_rate}</span>
                                        <br />
                                        <RatingStars fontSize="1rem" currentRating={ s.average_rating } allowChange={ false } />
                                    </div>
                                </div>
                            </Link>
                        ) ) }
                        
                    </div>
                </div>
            }
        </>
    );
}
 
export default ServiceList;