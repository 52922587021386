import { useState } from "react";

const SellerCenterReturnsDecline = ({returns, index}) => {
    const [returnID] = useState(returns.return_id);
    const [isPending, setIsPending] = useState(false);
    const [declineReason, setDeclineReason] = useState("");
    const [showDeliveryFields, setShowDeliveryFields] = useState(false);
    const [courier, setCourier] = useState("");
    const [trackingCode, setTrackingCode] = useState("");
    const [showTrackingCode, setShowTrackingCode] = useState(true);

    const changeReason = (selectedReason) => { 
        if (selectedReason === "I will ship the missing item/accessory") {
            setShowDeliveryFields(true);
        } else if (selectedReason === "Here are the correct courier/tracking details") {
            setShowDeliveryFields(true);
        } else if (selectedReason === "I will re-ship the product") {
            setShowDeliveryFields(true);
        } else {
            setShowDeliveryFields(false);
        }
        setDeclineReason(selectedReason);
    }
    
    const changeCourier = (selectedCourier) => {
        if (selectedCourier === "Personal Delivery") {
            setShowTrackingCode(false);
        } else {
            setShowTrackingCode(true);
        }
        setCourier(selectedCourier)
    }

    const handleDeclineRefund = (e) => {
        e.preventDefault(); //will prevent default page load

        setIsPending(true);

        const refundDecline = {
            return_reason: declineReason,
            courier: courier,
            tracking_code: trackingCode
        };

        fetch(`/api/seller-center/refund-decline/${returnID}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(refundDecline)
        })
        .then( (result) => result.json() )
        .then( (result) =>  {
            if( "successfulUpdate" in result && result.successfulUpdate ) {
                setIsPending(false);
                document.getElementById("btn-close-modal-" + index).click(); //close the modal
                // triggerRerender(); //trigger rerender of parent component
                window.location.href = "/seller-center/return-refund-requests";
            }
            else { //unsuccessful insert
                alert("Something went wrong!");
            }
        });
    }

    return (
        <>
            <button type="button" className="btn btn-danger btn-sm" style={{width: "130px", height: "35px"}} data-bs-toggle="modal" data-bs-target={"#decline-refund-modal-" + index}>
                <small>Decline</small>
            </button>
            <form id={"return-form" + index} onSubmit={handleDeclineRefund}>
                <div className="modal fade" id={"decline-refund-modal-" + index} tabIndex="-1" aria-labelledby={"decline-refund-label-" + index} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title edit-title-limit" id={"order-refund-label-" + index}>Decline Return/Refund Request</h5>
                                <button type="button" id={"btn-close-modal-" + index} className="btn-close btn-close-white btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex flex-column">
                                    <strong>Decline Reason:</strong>
                                </div>
                                <div className="d-flex flex-column mt-1">
                                    <select id="reason" name="reason" className="form-select form-select-sm small-text" style={{height: '38px'}} value={ declineReason } onChange={ (e) => changeReason(e.target.value) } required>
                                        <option defaultValue value="">Select Reason...</option>
                                        <option value="Shipped product is still in transit (as per courier Tracking)">Shipped product is still in transit (as per courier Tracking)</option>
                                        <option value="Reported issue is specified in product page">Reported issue is specified in product page</option>
                                        <option value="Product sold for parts only and not functional as described in product description">Product sold for parts only and not functional as described in product description</option>
                                        <option value="I will re-ship the product">I will re-ship the product</option>
                                        <option value="I will ship the missing item/accessory">I will ship the missing item/accessory</option>
                                        <option value="Here are the correct courier/tracking details">Here are the correct courier/tracking details</option>
                                    </select>
                                </div>
                                {
                                    showDeliveryFields?
                                        <>
                                            <div className="d-flex flex-column mt-1">
                                                <label htmlFor="courier" className="fw-bold">Courier: </label>
                                                <select id="courier" name="courier" className="form-select form-select-sm small-text" style={{height: '38px'}} value={ courier } onChange={ (e) => changeCourier(e.target.value) } >
                                                    <option defaultValue value="">Select Courier...</option>
                                                    <option value="Personal Delivery">Personal Delivery</option>
                                                    <option value="2GO Express">2GO Express</option>
                                                    <option value="Air 21">Air 21</option>
                                                    <option value="Airspeed">Airspeed</option>
                                                    <option value="AP Cargo">AP Cargo</option>
                                                    <option value="DHL">DHL</option>
                                                    <option value="Grab Express">Grab Express</option>
                                                    <option value="J&T Express">J&T Express</option>
                                                    <option value="JRS Express">JRS Express</option>
                                                    <option value="Lalamove">Lalamove</option>
                                                    <option value="LBC">LBC</option>
                                                    <option value="Ninja Van">Ninja Van</option>
                                                    <option value="UPS">UPS</option>
                                                    <option value="XDE Logistics">XDE Logistics</option>
                                                </select>
                                            </div>
                                            {
                                                showTrackingCode?
                                                    <div className="d-flex flex-column mt-1">
                                                        <strong>Tracking Code:</strong>
                                                        <input id="tracking-code" name="tracking-code" type="text" className="form-control form-control-sm input-text small-text" maxLength="200" value={ trackingCode } onChange={ (e) => setTrackingCode(e.target.value) } required />
                                                    </div>
                                                :null
                                            }
                                        </>
                                    :null
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><small>Cancel</small></button>
                                { !isPending && 
                                    <button type="submit" className="btn btn-danger btn-sm"><small>Return/Refund</small></button>
                                }
                                { isPending && 
                                    <button className="btn btn-secondary btn-sm" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default SellerCenterReturnsDecline;