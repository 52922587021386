import { useState } from "react";

const UserReceiveOrder = ({orders, triggerRerender}) => {
    const [isPending, setIsPending] = useState(false);

    const handleReceive = (e) => {
        e.preventDefault(); //will prevent default page load

        setIsPending(true);

        if( window.confirm("I confirm that I have received my order and verified the contents to be satisfactory.") ) {
            fetch(`/api/user-receive-order/${orders.order_id}`)
            .then( (result) => result.json() )
            .then( (result) =>  {
                if( 'successfulUpdate' in result && result.successfulUpdate ) { //successful update
                    setIsPending(false);
                    // triggerRerender(); //trigger rerender of parent component
                    window.location.href = "/user/shipped-orders"
                }
                else { //unsuccessful update
                     alert("Something went wrong!");
                }
            });
        } else setIsPending(false);
    }

    return(
        <>
            { !isPending && 
                <button type="button" className="mr-2 btn btn-success btn-sm" onClick={handleReceive}>
                    <small>Order Received</small>
                </button>
            }
            { isPending && 
                <button className="mr-2 btn btn-warning btn-sm" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                </button>
            }
        </>
    )

}

export default UserReceiveOrder;