import { useState } from "react";

import "../../css/mobile/seller-center.css";

const UserOrderReturnRefund = ({items, index, triggerRerender}) => {
    const [itemID] = useState(items.order_item_id);
    const [productID] = useState(items.product_id); 
    const [reason, setReason] = useState("");
    const [returnDescription, setReturnDescription] = useState("");
    const [count, setCount] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showPhotoUploadError, setPhotoUploadError] = useState(false);
    const [photoUploadMessage, setPhotoUploadMessage] = useState("");
    const [isPending, setIsPending] = useState(false);

    const changeReason = (newReason) => { setReason(newReason) };

    const descriptionCount = (descr) => {
        setReturnDescription(descr);
        setCount(descr.length);
    }

    const maxSelect = (e) => {
        let files = e.target.files // create file object
            if (files.length > 3) {
              const msg = "Max of only 3 images can be uploaded."
              e.target.value = null // discard selected file
              setPhotoUploadError(true);
              setPhotoUploadMessage(msg);
              console.log(msg);
      
              return false;
            }
            return true;
     }
    
    const checkMimeType = (e) => {
        let files = e.target.files     //getting file object
        let err = ""                    //define message container
        const types = ["image/png", "image/jpeg", "image/gif"] // list allow mime type
        for(var x = 0; x<files.length; x++) {  // loop access array
            if (types.every(type => files[x].type !== type)) {
            err += files[x].type+" is not a supported format\n";
            }
        };
        if (err !== "") {
            e.target.value = null
            if (e.target.name === "photos") {  
                setPhotoUploadError(true);
                setPhotoUploadMessage(err);
                console.log(err);
                
                return false;
            }
        } else {
            setPhotoUploadError(false);
        }
        return true;
    }

    const checkFileSize=(e)=>{
        let files = e.target.files
        let size = 10000000;
        let err = "";
        for(var x = 0; x<files.length; x++) {
            if (files[x].size > size) {
            err += files[x].type+" is too large, please select a smaller sized file\n";
            }
        };
        if (err !== "") {
            e.target.value = null
            if (e.target.name === "photos") {    //cover input field upload
                setPhotoUploadError(true);
                setPhotoUploadMessage(err);
                console.log(err);
                return false
            }
        }
        return true;
    }

    const handleProductImages = (e) => {
		if (e.target.files) {
            if(maxSelect(e) && checkMimeType(e) && checkFileSize(e) ) {
                const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));
    
                setSelectedFiles(filesArray);
                Array.from(e.target.files).map(
                    (file) => URL.revokeObjectURL(file) // avoid memory leak
                );
            }
		}
	};

    const handleSubmit = (e) => {
        e.preventDefault(); //will prevent default page load

        if(reason.trim() !== "" && selectedFiles !== "" && returnDescription.trim() !== "") {
            if( window.confirm("Are you sure you want to request return/refund for this item?") ) {
                const returnForm = document.getElementById("return-form" + index);
                const formData = new FormData(returnForm);

                setIsPending(true);
    
                fetch(`/api/user-returns/${itemID}`, {
                    method: "POST",
                    body: formData
                })
                .then( (result) => result.json() )
                .then( (result) => {
                    if( "successfulUpdate" in result && result.successfulUpdate ) {
                        document.getElementById("btn-close-modal" + index).click();
                        // triggerRerender(); //trigger rerender of parent component
                        window.location.href = "/user/shipped-orders"
                    } else {
                        alert("An error has occurred!");
                        window.location.href = "/user/shipped-orders"
                    }
                    setIsPending(false);
                });
            }
        }
    }

    return(
        <>
            <button type="button" className="btn float-end btn-warning btn-sm pb-2 ml-1" style={{width: "170px", height: "35px"}} data-bs-toggle="modal" data-bs-target={"#order-refund-modal-" + index}>
                <small>Request Return/Refund</small>
            </button>
            <form id={"return-form" + index} onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="modal fade" id={"order-refund-modal-" + index} tabIndex="-1" aria-labelledby={"order-refund-label-" + index} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title edit-title-limit" id={"order-refund-label-" + index}>Request Return/Refund</h5>
                                <button type="button" id={"btn-close-modal" + index} className="btn-close btn-close-white btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex flex-column">
                                    Return/Refund Item:
                                    <span className="title-limit fw-light">{items.order_name}</span>
                                    <input id="item" name="item" type="text" className="form-control" value={ itemID } readOnly hidden />
                                    <input id="product" name="product" type="text" className="form-control" value={ productID } readOnly hidden />
                                </div>
                                <div>
                                    <div className="d-flex flex-column">
                                        <label htmlFor="reason">Reason: <span className="text-danger"><strong>*</strong></span></label>
                                        <select id="reason" name="reason" className="form-select form-select-sm small-text" style={{height: '38px'}} value={ reason } onChange={ (e) => changeReason(e.target.value) } required>
                                            <option defaultValue value="">Select Reason...</option>
                                            <option value="Wrong Item/does not match description or photos">Wrong Item/does not match description or photos</option>
                                            <option value="Incomplete Product/Missing Accessories">Incomplete Product/Missing Accessories</option>
                                            <option value="Damaged or faulty item">Damaged or faulty item</option>
                                            <option value="Counterfeit">Counterfeit</option>
                                            <option value="Expired Item">Expired Item (For consumables items only)</option>
                                            <option value="Invalid Courier/Tracking Details">Invalid Courier/Tracking Details</option>
                                            <option value="Item not received">I did not receive ordered item</option>
                                        </select>
                                    </div>
                                    <div className="d-flex flex-column mt-1" style={{lineHeight: "20px"}}>
                                        <label htmlFor="photos">Photos of items: </label>
                                        <small><i>Max of (3) photos of items with issue/s in jpg, jpeg and png format</i></small>
                                        <input id="photos" name="photos" className="form-control form-control-sm small-text mt-1" type="file" accept=".jpeg,.jpg,.png" onChange={handleProductImages} multiple />
                                        {
                                            showPhotoUploadError?
                                                <div className="alert alert-danger mt-1" role="alert">
                                                    <small>{photoUploadMessage}</small>
                                                </div>
                                            :null
                                        }
                                    </div>
                                    <div className="d-flex flex-column mt-1">
                                        <label htmlFor="reason">Return Details: <span className="text-danger"><strong>*</strong></span></label>
                                        <textarea id="description" name="description" className="form-control form-control-sm small-text" rows="2" maxLength="1000" value={ returnDescription } onChange={ (e) => descriptionCount(e.target.value) } required></textarea>
                                        <p className="text-end"><small>{count}/1000</small></p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><small>Cancel</small></button>
                                { !isPending && 
                                    <button type="submit" className="btn btn-success btn-sm"><small>Return/Refund</small></button>
                                }
                                { isPending && 
                                    <button className="btn btn-primary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default UserOrderReturnRefund;