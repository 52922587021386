import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';

import Comments from "../reusable/services/Comments";

import 'react-calendar/dist/Calendar.css';
import '../../../css/mobile/service-provider-calendar.css'

const ProviderBookedClients = ({ profilePicture }) => {
    const [ selectedDate, setSelectedDate ] = useState(new Date());
    const [ bookings, setBookings ] = useState([]);
    const [ selectedDateBookings, setSelectedDateBookings ] = useState([]);
    const [ otherBookings, setOtherBookings ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ showHideText, setShowHideText ] = useState("Show");

    const [ isDatePast, setIsDatePast ] = useState(false);

    const daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    useEffect( () => {
        //get current date
        const curDate = new Date();
        const d = curDate.getDate(); //get date
        const m = curDate.getMonth() + 1; //get month
        const y = curDate.getFullYear(); //get year

        fetch(`/api/service-provider-booked-clients?y=${y}&m=${m}&d=${d}`)
            .then( res => { 
                if(res.ok) return res.json();
                throw new Error('No booking found.');
            }).then( data => {
                if(data) { //check if data is not null
                    setBookings(data);
                    setIsLoading(false);
                } else throw new Error('No booking found.'); //data is null, send error
            }).catch( (e) => {
                //alert(e.message);
            })
        ;
    }, [] );

    useEffect( () => { //run after setBookings
        if( bookings.length > 0 ) handleSelectDate(selectedDate); //set selectedDateBookings
    }, [bookings] );

    const handleMarkDates = ({ date, view }) => {
        if( bookings.length > 0 ) { //has bookings
            const d = date.getDate();
            const m = date.getMonth() + 1;
            const y = date.getFullYear();

            for( let i = 0; i < bookings.length; i++ ) {
                if( !bookings[i].isExpired && bookings[i].mdy ) { //not expired and has start date
                    if( bookings[i].mdy.m === m && bookings[i].mdy.d === d && bookings[i].mdy.y === y ) {
                        return 'booked-client'; // your class name
                    }
                }
            }
        }
    }

    const handleSelectDate = (date) => {
        //assign bookings to selected date bookings or other bookings
        if( bookings.length > 0 ) { 
            const d = date.getDate();
            const m = date.getMonth() + 1;
            const y = date.getFullYear();

            let loc_selectedDateBookings = [], loc_otherBookings = [];

            let parent_booking;

            for( let i = 0; i < bookings.length; i++ ) {
                if( 'service_id' in bookings[i] ) parent_booking = { ...bookings[i] }; //copy parent booking

                if( !bookings[i].isExpired ) { //not expired
                    if( bookings[i].mdy ) { //has date to compare
                        if( bookings[i].mdy.m === m && bookings[i].mdy.d === d && bookings[i].mdy.y === y ) { //booking date is equal to selected date
                            if( 'service_id' in bookings[i] ) { //service_id is available --> parent booking
                                loc_selectedDateBookings.push( bookings[i] ); //push to selected date bookings
                            }
                            else {
                                let b = { ...parent_booking }; //copy parent booking
                                b.isExpired = bookings[i].isExpired; //copy child's isExpired value
                                b.mdy = bookings[i].mdy; //copy child's mdy value

                                loc_selectedDateBookings.push( b ); //push to selected date bookings

                                //remove parent from other bookings
                                let temp_otherBookings = [];
                                for( let j = 0; j < loc_otherBookings.length; j++ ) {
                                    if( loc_otherBookings[j].service_booking_id !== b.service_booking_id ) {
                                        temp_otherBookings.push( loc_otherBookings[j] );
                                    }
                                }
                                loc_otherBookings = [ ...temp_otherBookings ];
                            }
                        } else { //booking date it not equal to selected date
                            if( 'service_id' in bookings[i] ) loc_otherBookings.push( bookings[i] ); //if parent, push to other bookings
                            else { //if child --> no booking details
                                //search for existence before pushing parent booking
                                if( (loc_selectedDateBookings.find( r => r.service_booking_id === parent_booking.service_booking_id )) === undefined ) {
                                    if( (loc_otherBookings.find( r => r.service_booking_id === parent_booking.service_booking_id )) === undefined ) {
                                        let b = { ...parent_booking }; //copy parent booking
                                        b.isExpired = bookings[i].isExpired; //copy child's isExpired value
                                        b.mdy = bookings[i].mdy; //copy child's mdy value
    
                                        loc_otherBookings.push( b );
                                    }
                                }
                                
                            }
                        }
                    }
                } //else --> may later show expired bookings
            }
            setSelectedDateBookings(loc_selectedDateBookings);
            setOtherBookings(loc_otherBookings);
        }

        //set default to hide other bookings
        let div = document.getElementById( 'other-bookings' );
        if( div !== null ) {
            div.classList.remove("show"); //hide other bookings
            setShowHideText("Show");
        }

        //check if selected date is from the past
        const curDate = new Date();
        const d = curDate.getDate(); //get date
        const m = curDate.getMonth() + 1; //get month
        const y = curDate.getFullYear(); //get year

        const currentDate = new Date(m + "/" + d + "/" + y); //current date without time

        const dd = date.getDate(); //get date
        const mm = date.getMonth() + 1; //get month
        const yy = date.getFullYear(); //get year

        const selectedDate = new Date(mm + "/" + dd + "/" + yy); //selected date without time

        setIsDatePast( currentDate > selectedDate ); //set is date past
    }

    const handleShowHide = () => {
        if( showHideText === "Show" ) setShowHideText("Hide");
        else if( showHideText === "Hide" ) setShowHideText("Show");
    }

    return (
        <div className="provider-booked-clients services service-details book-service d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center mb-3">Booked Clients</h3>

            <div className="d-flex justify-content-center">
                { !isLoading &&
                    <Calendar onChange={setSelectedDate} value={selectedDate} calendarType="US" 
                        tileClassName={({ date, view }) =>  handleMarkDates({ date, view }) }
                        onClickDay={ (value, event) => handleSelectDate(value) }
                    />
                }
            </div>

            { !isLoading && bookings.length === 0 &&
                <div className="d-flex justify-content-center user-form rounded py-4 px-3 mt-4">
                    <p>You have no available bookings.</p>
                </div>
            }

            { !isLoading && bookings.length > 0 &&
                <div>
                    { isDatePast &&
                        <div className="d-flex justify-content-center user-form rounded py-4 px-3 mt-4">
                            Unable to show past bookings.
                        </div>
                    }
                    { !isDatePast && selectedDateBookings.length === 0 &&
                        <div className="d-flex justify-content-center user-form rounded py-4 px-3 mt-4">
                            <p>
                                { 'You have no available bookings for ' }
                                { daysOfTheWeek[selectedDate.getDay()] + ', ' }
                                { selectedDate.getMonth() + 1 + '/' }
                                { selectedDate.getDate() + '/' }
                                { selectedDate.getFullYear() + '.' }
                            </p>
                        </div>
                    }

                    { !isDatePast && selectedDateBookings.length > 0 && 
                        <div className="service-details w-100 mt-4">
                            <div className="alert" role="alert">
                                <h6>
                                    { 'Your booking(s) for ' }
                                    { daysOfTheWeek[selectedDate.getDay()] + ', ' }
                                    { selectedDate.getMonth() + 1 + '/' }
                                    { selectedDate.getDate() + '/' }
                                    { selectedDate.getFullYear() + ':' }
                                </h6>
                            </div>

                            { selectedDateBookings.map( (b, i) => (
                                <div key={b.service_booking_id} className="alert" role="alert">
                                    <div>
                                        <div className="mb-1 font-color-gold"><b>{b.serv_name}</b></div>
                                    </div>

                                    <h6>
                                        {b.serv_payment_currency} {b.serv_payment_rate} ({b.serv_payment_unit})
                                    </h6>

                                    {/* <h6>Booking date: {b.booking_insert_dt}</h6> */}

                                    <hr />

                                    <div className="d-flex flex-row">
                                        <div className="mr-1">
                                            <b><i className="bi bi-person-fill" style={{color: '#dc3545'}}></i></b>
                                        </div>
                                        <div>
                                            <div>{b.client_name}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div className="mr-1">
                                            <b><i class="bi bi-telephone-fill" style={{color: '#dc3545'}}></i></b>
                                        </div>
                                        <div>
                                            <div>{b.client_contact_num}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div className="mr-1">
                                            <b><i class="bi bi-geo-alt-fill" style={{color: '#dc3545'}}></i></b>
                                        </div>
                                        <div>
                                            <div>{b.client_address}</div>
                                        </div>
                                    </div>

                                    { b.start_dt_2 &&
                                        <div className="d-flex flex-row">
                                            <div className="mr-1">
                                                <b><i className="bi bi-calendar-date-fill" style={{color: '#dc3545'}}></i></b>
                                            </div>
                                            <div>
                                                <div>
                                                    {b.start_dt_2}
                                                    {b.end_dt_2 && ' - ' + b.end_dt_2}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    { b.start_time &&
                                        <div className="d-flex flex-row">
                                            <div className="mr-1">
                                                <b><i class="bi bi-clock-fill" style={{color: '#dc3545'}}></i></b>
                                            </div>
                                            <div>
                                                <div>
                                                    {b.start_time}
                                                    {b.end_time && ' - ' + b.end_time}    
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    { b.note_to_provider &&
                                        <div className="d-flex flex-row">
                                            <div className="mr-1">
                                                <b><i class="bi bi-card-text" style={{color: '#dc3545'}}></i></b>
                                            </div>
                                            <div className="text-break">Note: {b.note_to_provider}</div>
                                        </div>
                                    }

                                    <hr />

                                    <Comments id={b.service_booking_id} allowReply={true} hasComments={b.hasComments} newCommentsCount={b.newCommentsCount} profilePicture={profilePicture} />
                                    
                                </div>
                            ))}
                        </div>
                    }

                    { otherBookings.length > 0 &&
                        <>
                            <hr className="my-4" />
                            <div className="alert d-flex justify-content-between" role="alert">
                                <h6 className="align-self-center">Other bookings:</h6>
                                <button onClick={handleShowHide} className="btn btn-sm btn-services-gold" data-bs-toggle="collapse" data-bs-target="#other-bookings" aria-expanded="false" aria-controls="other-bookings">{showHideText}</button>
                            </div>
                        </>
                    }
                    <div id="other-bookings" class="collapse">
                        {  otherBookings.map( (b, i) => (
                            <div key={b.service_booking_id}>

                                { !b.isExpired &&
                                    <div className="alert" role="alert">
                                        <div>
                                            <div className="mb-1 font-color-gold"><b>{b.serv_name}</b></div>
                                        </div>

                                        <h6>
                                            {b.serv_payment_currency} {b.serv_payment_rate} ({b.serv_payment_unit})
                                        </h6>

                                        {/* <h6>Booking date: {b.booking_insert_dt}</h6> */}

                                        <hr />

                                        <div className="d-flex flex-row">
                                            <div className="mr-1">
                                                <b><i className="bi bi-person-fill" style={{color: '#dc3545'}}></i></b>
                                            </div>
                                            <div>
                                                <div>{b.client_name}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="mr-1">
                                                <b><i class="bi bi-telephone-fill" style={{color: '#dc3545'}}></i></b>
                                            </div>
                                            <div>
                                                <div>{b.client_contact_num}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <div className="mr-1">
                                                <b><i class="bi bi-geo-alt-fill" style={{color: '#dc3545'}}></i></b>
                                            </div>
                                            <div>
                                                <div>{b.client_address}</div>
                                            </div>
                                        </div>

                                        { b.start_dt_2 &&
                                            <div className="d-flex flex-row">
                                                <div className="mr-1">
                                                    <b><i className="bi bi-calendar-date-fill" style={{color: '#dc3545'}}></i></b>
                                                </div>
                                                <div>
                                                    <div>
                                                        {b.start_dt_2}
                                                        {b.end_dt_2 && ' - ' + b.end_dt_2}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        { b.start_time &&
                                            <div className="d-flex flex-row">
                                                <div className="mr-1">
                                                    <b><i class="bi bi-clock-fill" style={{color: '#dc3545'}}></i></b>
                                                </div>
                                                <div>
                                                    <div>
                                                        {b.start_time}
                                                        {b.end_time && ' - ' + b.end_time}    
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        { b.note_to_provider &&
                                            <div className="d-flex flex-row">
                                                <div className="mr-1">
                                                    <b><i class="bi bi-card-text" style={{color: '#dc3545'}}></i></b>
                                                </div>
                                                <div className="text-break">Note: {b.note_to_provider}</div>
                                            </div>
                                        }

                                        <hr />

                                        <Comments id={b.service_booking_id} allowReply={true} hasComments={b.hasComments} newCommentsCount={b.newCommentsCount} profilePicture={profilePicture} />
                                        
                                    </div>
                                }
                                    
                                
                            </div>
                        ))}
                    </div>
                </div>
            }
            
        </div>
    );
}
 
export default ProviderBookedClients;