import { useState } from "react";

import "../../css/mobile/seller-center.css";

const SellerCenterShippedOrderDetails = ({orders, index}) => {
    const [courier] = useState(orders.courier);
    const [trackingCode] = useState(orders.tracking_code);

    const twoGoLink = `https://supplychain.2go.com.ph/CustomerSupport/ttrace/track_status.asp?bl=${trackingCode}`;
    const air21Link = "https://www.air21.com.ph/main/shipment-tracking-dfs.php";
    const airSpeedLink = "https://airspeed.ph/track-cargo/";
    const apCargoLink = "https://apcargo.com.ph/#cargotracker";
    const dhlLink = `https://www.dhl.com/ph-en/home/tracking.html?tracking-id=${trackingCode}&submit=1`;
    const grabLink = "https://www.grab.com/ph/express/";
    const jtLink = "https://www.jtexpress.ph/index/query/gzquery.html";
    const jrsLink = "https://www.jrs-express.com/tracking/";
    const lalamoveLink = "https://www.lalamove.com/en-ph/";
    const lbcLink = "https://www.lbcexpress.com/track/";
    const ninjavanLink = `https://www.ninjavan.co/en-ph/tracking?id=${trackingCode}`;
    const upsLink = "https://www.ups.com/track?loc=en_PH&requester=ST/";
    const xdeLink = "https://www.xde.com.ph/track-trace";

    return(
        <>
            <button type="button" className="btn btn-primary btn-sm" style={{width: "150px"}} data-bs-toggle="modal" data-bs-target={"#shipped-orders-modal-" + index}>
                Order Details
            </button>

            <div className="modal fade" id={"shipped-orders-modal-" + index} tabIndex="-1" aria-labelledby={"shipped-orders-label-" + index} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id={"shipped-orders-label-" + index}>Order Details</h5>
                            <button type="button" id={"btn-close-modal-" + index} className="btn-close btn-close-white btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-start">
                            <small>
                                <div className="row">
                                    <strong>Delivery Status:</strong>
                                </div>
                                <div className="row lh-1">
                                    {orders.order_status === 'shipped' &&
                                        <small><span className="badge rounded-pill bg-primary ml-1">{orders.order_status}</span></small>
                                    }
                                    {orders.order_status === 'received' &&
                                        <small><span className="badge rounded-pill bg-success ml-2s">{orders.order_status}</span></small>
                                    }
                                </div>
                                <div className="row mt-1">
                                    <strong>Name:</strong>
                                </div>
                                <div className="row lh-1">
                                    <span>{orders.delivery_name}</span>
                                </div>
                                {orders.delivery_contact !== '' &&
                                    <>
                                        <div className="row mt-1">
                                            <strong>Phone:</strong>
                                        </div>
                                        <div className="row lh-1">
                                            <span>{orders.delivery_contact}</span>
                                        </div>
                                    </>
                                }
                                <div className="row mt-1">
                                    <strong>Address:</strong>
                                </div>
                                <div className="row lh-1">
                                    <span>{orders.delivery_address}</span>
                                </div>
                                <div className="row mt-1">
                                    <strong>Delivery Method:</strong>
                                </div>
                                <div className="row lh-1">
                                    <span>{orders.courier}</span>
                                </div>
                                { orders.tracking_code !== "" &&
                                    <>
                                        <div className="row mt-1">
                                            <strong>Tracking Code:</strong>
                                        </div>
                                        <div className="row lh-1">
                                            <span>{orders.tracking_code}</span>
                                        </div>
                                        <div className="row mt-1">
                                            <strong>Courier Tracking Link:</strong>
                                        </div>
                                        <div className="row lh-1">
                                            { courier === '2GO Express' &&
                                                <a className="text-primary" rel="noopener noreferrer" href={ twoGoLink } target="_blank">https://supplychain.2go.com.ph</a>
                                            }
                                            { courier === 'Air 21' &&
                                                <a className="text-primary" rel="noopener noreferrer" href={ air21Link } target="_blank">https://www.air21.com.ph</a>
                                            }
                                            { courier === 'Airspeed' &&
                                                <a className="text-primary" rel="noopener noreferrer" href={ airSpeedLink } target="_blank">https://airspeed.ph</a>
                                            }
                                            { courier === 'AP Cargo' &&
                                                <a className="text-primary" rel="noopener noreferrer" href={ apCargoLink } target="_blank">https://apcargo.com.ph</a>
                                            }
                                            { courier === 'DHL' &&
                                                <a className="text-primary" rel="noopener noreferrer" href={ dhlLink } target="_blank">https://www.dhl.com</a>
                                            }
                                            { courier === 'Grab Express' &&
                                                <a className="text-primary" rel="noopener noreferrer" href={ grabLink } target="_blank">https://www.grab.com</a>
                                            }
                                            { courier === 'J&T Express' &&
                                                <a className="text-primary" rel="noopener noreferrer" href={ jtLink } target="_blank">https://www.jtexpress.ph</a>
                                            }
                                            { courier === 'JRS Express' &&
                                                <a className="text-primary" rel="noopener noreferrer" href={ jrsLink } target="_blank">https://www.jrs-express.com</a>
                                            }
                                            { courier === 'Lalamove' &&
                                                <a className="text-primary" rel="noopener noreferrer" href={ lalamoveLink } target="_blank">https://www.lalamove.com</a>
                                            }
                                            { courier === 'LBC' &&
                                                <a className="text-primary" rel="noopener noreferrer" href={ lbcLink } target="_blank">https://www.lbcexpress.com</a>
                                            }
                                            { courier === 'Ninja Van' &&
                                                <a className="text-primary" rel="noopener noreferrer" href={ ninjavanLink } target="_blank">https://www.ninjavan.co</a>
                                            }
                                            { courier === 'UPS' &&
                                                <a className="text-primary" rel="noopener noreferrer" href={ upsLink } target="_blank">https://www.ups.com</a>
                                            }
                                            { courier === 'XDE Logistics' &&
                                                <a className="text-primary" rel="noopener noreferrer" href={ xdeLink } target="_blank">https://www.xde.com.ph</a>
                                            }
                                        </div>
                                    </>
                                }
                                
                                
                            </small>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><small>Close</small></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SellerCenterShippedOrderDetails;