import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import '../../css/mobile/product.css';

const ProductDetails = ({ isAuthenticated, path }) => {
    const { id } = useParams(); //get product id
    const [ product, setProduct ] = useState(null);

    const [ addedToWishList, setAddedToWishList ] = useState(null); //triggers product fetch when updated
    const [ addedToShoppingCart, setAddedToShoppingCart ] = useState(null); //triggers product fetch when updated
    
    const [ isWishListLoading, setIsWishListLoading ] = useState(false);
    const [ isShoppingCartLoading, setIsShoppingCartLoading ] = useState(false);

    const history = useHistory();

    const [ imageShown, setImageShown ] = useState('');

    useEffect( () => {
        fetch(`/api/product/${id}`)
            .then( res => { 
                if(res.ok) return res.json();
                throw new Error('No product found.');
            }).then( data => {
                if(data) { //check if data is not null
                    setProduct(data);
                    if( data.pictures ) setImageShown(data.pictures[0]);
                } else throw new Error('No product found.'); //data is null, send error
            }).catch( (e) => {
                alert(e.message);
            });
    }, [ addedToShoppingCart, addedToWishList ] );

    const [ showMessage, setShowMessage ] = useState(null);
    const handleCloseMessage = () => setShowMessage(null);

    const handleAddToWishList = () => {
        setIsWishListLoading(true);

        if( !isAuthenticated ) history.push(`${path}signin`); //not authenticated
        else {
            fetch(`/api/product/add-to-wish-list/${product.product_id}`)
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( 'successfulInsert' in result && result.successfulInsert ) { //successful add
                        setAddedToWishList(true); //change state to trigger product fetch
                        setIsWishListLoading(false);
                        setShowMessage("You have added this product to your wish list.");
                    }
                    else { //unsuccessful add to wish list
                        alert("Something went wrong!");
                        setIsWishListLoading(false);
                    }
                }).catch( () => setIsWishListLoading(false) )
            ;
        }
    }

    const handleRemoveFromWishList = () => {
        setIsWishListLoading(true);

        if( !isAuthenticated ) history.push(`${path}signin`); //not authenticated
        else {
            fetch(`/api/product/remove-from-wish-list/${product.product_id}`)
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( 'isRemoved' in result && result.isRemoved ) { //successfully removed
                        setAddedToWishList(false); //change state to trigger product fetch
                        setIsWishListLoading(false);
                        setShowMessage("You have removed this product from your wish list.");
                    }
                    else { //unsuccessful remove from wish list
                        alert("Something went wrong!");
                        setIsWishListLoading(false);
                    }
                }).catch( () => setIsWishListLoading(false) )
            ;
        }
    }

    const handleAddToShoppingCart = () => {
        setIsShoppingCartLoading(true);

        if( !isAuthenticated ) history.push(`${path}signin`); //not authenticated
        else {
            fetch(`/api/product/add-to-shopping-cart/${product.product_id}`)
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( 'successfulInsert' in result && result.successfulInsert ) { //successful add
                        setAddedToShoppingCart(true); //change state to trigger product fetch
                        setIsShoppingCartLoading(false);
                        setShowMessage("You have added this product to your shopping cart.");
                    }
                    else { //unsuccessful add to wish list
                        alert("Something went wrong!");
                        setIsShoppingCartLoading(false);
                    }
                }).catch( () => setIsShoppingCartLoading(false) )
            ;
        }
    }

    const handleRemoveFromShoppingCart = () => {
        setIsShoppingCartLoading(true);

        if( !isAuthenticated ) history.push(`${path}signin`); //not authenticated
        else {
            fetch(`/api/product/remove-from-shopping-cart/${product.product_id}`)
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( 'isRemoved' in result && result.isRemoved ) { //successfully removed
                        setAddedToShoppingCart(false); //change state to trigger product fetch
                        setIsShoppingCartLoading(false);
                        setShowMessage("You have removed this product from your shopping cart.");
                    }
                    else { //unsuccessful remove from shopping cart
                        alert("Something went wrong!");
                        setIsShoppingCartLoading(false);
                    }
                }).catch( () => setIsShoppingCartLoading(false) )
            ;
        }
    }

    const handleChangeImage = (img) => {
        setImageShown(img);
    }

    return (
        <div className="pt-3">
            { product &&
                <div className="product-details">
                    <div className="product-image-container px-3">
                        { product.pictures && 
                            // <div className="product-image mx-3" style={{ backgroundImage: `url(${imageShown})` }}></div>
                            <div className="product-image">
                                <img src={imageShown} alt="" />
                            </div>
                        }
                        { !product.pictures && 
                            <div className="product-image mx-3" style={{ backgroundImage: `url(https://via.placeholder.com/400/DDDDDD/FFFFFF?text=Trivut+Marketplace)` }}></div>
                        }
                    </div>
                    <div className="thumbnails-container d-flex overflow-auto ml-3">
                        { product.pictures && product.pictures.map( ( pic, index ) => (
                            <a key={index} onClick={ () => handleChangeImage(pic) }>
                                <img className="product-image-thumb" src={pic} alt={product.prod_name} />
                            </a>
                        ) ) }
                    </div>
                    
                    <div className="product-info mx-3 mt-3 mb-0">
                        { ( product.prod_quantity === 0 || !product.paypal_check ) && 
                            <h6 className="prod-unavailable alert alert-danger mb-3">This product is currently unavailable.</h6>
                        }

                        <h3>{ product.prod_name }</h3>

                        { (product.prod_category_1 || product.prod_category_2 || product.prod_category_3) &&
                            <div className="product-categories d-flex flex-column">
                                <div><span className="badge bg-secondary mt-2">{product.prod_category_1}</span></div>
                                <div><span className="badge bg-secondary mt-2">{product.prod_category_2}</span></div>
                                <div><span className="badge bg-secondary mt-2">{product.prod_category_3}</span></div>
                            </div>
                        }
                        
                        <h5 className="seller my-3">
                            By {product.first_name} {product.last_name}
                            { product.city && product.state_territory &&
                                <span> from {product.city}, {product.state_territory}</span>
                            }
                        </h5>

                        <h4 className="selling-price my-3">{product.prod_currency} {product.prod_price_with_fees.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h4>

                        { product.prod_orig_currency && product.prod_orig_price && 
                            <h5 className="original-price my-3">Original price @ {product.prod_orig_currency} {product.prod_orig_price}</h5> 
                        }

                        { product.prod_quantity !== 0 && product.paypal_check &&
                            <p className="my-3">Quantity Available: { product.prod_quantity }</p>
                        }
                        
                        { product.prod_descr && 
                            <p>{product.prod_descr}</p>
                        }

                        { ( product.prod_manufacturer || product.prod_condition || product.prod_location || product.prod_color ) &&
                            <div className="other-details">
                                <h5 className="other-details my-3 mb-0">Other details:</h5>
                                <ul>
                                    { product.prod_manufacturer && <li><span title="Manufacturer" className="badge bg-primary mx-2 mt-2">Manufacturer: {product.prod_manufacturer}</span></li> }
                                    { product.prod_condition && <li><span title="Condition" className="badge bg-secondary mx-2 mt-2">Condition: {product.prod_condition}</span></li> }
                                    { product.prod_location && <li><span title="Location" className="badge bg-success mx-2 mt-2">Location: {product.prod_location}</span></li> }
                                    { product.prod_color && <li><span title="Color" className="badge bg-danger mx-2 mt-2">Colors: {product.prod_color}</span></li> }

                                    { product.prod_dim_length && 
                                        <li><span title="Length" className="badge bg-warning text-dark mx-2 mt-2">Length: {product.prod_dim_length} { product.prod_dim_unit === "Metric" && "cm" }</span></li>
                                    }
                                    { product.prod_dim_width && 
                                        <li><span title="Width" className="badge bg-warning text-dark mx-2 mt-2">Width: {product.prod_dim_width} { product.prod_dim_unit === "Metric" && "cm" }</span></li>
                                    }
                                    { product.prod_dim_height && 
                                        <li><span title="Height" className="badge bg-warning text-dark mx-2 mt-2">Height: {product.prod_dim_height} { product.prod_dim_unit === "Metric" && "cm" }</span></li>
                                    }
                                    { product.prod_weight && 
                                        <li><span title="Weight" className="badge bg-warning text-dark mx-2 mt-2">Weight: {product.prod_weight} { product.prod_weight_unit === "Metric" && "kg" }</span></li>
                                    }

                                    { product.prod_warranty && product.prod_warranty_period &&
                                        <li><span title="Weight" className="badge bg-info text-dark mx-2 mt-2">Warranty: {product.prod_warranty} ({product.warranty_period})</span></li>
                                    }
                                </ul>
                            </div>
                        }

                        { product.prod_quantity !== 0 && product.paypal_check &&
                            <div className="product-details-buttons mt-3">
                                { !isShoppingCartLoading && ( !('isInShoppingCart' in product) || ( 'isInShoppingCart' in product && !product.isInShoppingCart ) ) &&
                                    <button onClick={handleAddToShoppingCart} className="btn btn-primary">
                                        <i className="bi bi-cart4"></i> Add to Cart
                                    </button>
                                }
                                { !isShoppingCartLoading && 'isInShoppingCart' in product && product.isInShoppingCart &&
                                    <button onClick={handleRemoveFromShoppingCart} className="btn btn-mp-secondary">
                                        <i className="bi bi-cart4"></i> Remove from Cart
                                    </button>
                                }
                                { isShoppingCartLoading &&
                                    <button className="btn btn-primary" type="button" disabled>
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                                    </button>
                                }


                                { !isWishListLoading && ( !('isInWishList' in product) || ( 'isInWishList' in product && !product.isInWishList ) ) &&
                                    <button onClick={handleAddToWishList} className="btn btn-primary mt-3">
                                        <i className="bi bi-star"></i> Add to Wish List
                                    </button>
                                }
                                { !isWishListLoading && 'isInWishList' in product && product.isInWishList &&
                                    <button onClick={handleRemoveFromWishList} className="btn btn-mp-secondary mt-3">
                                        <i className="bi bi-star"></i> Remove from Wish List
                                    </button>
                                }
                                { isWishListLoading &&
                                    <button className="btn btn-primary mt-3" type="button" disabled>
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                                    </button>
                                }
                            </div>
                        }
                        
                    </div>
                </div>
            }

            <Modal show={showMessage} onHide={handleCloseMessage} centered className="mobile-modal">
                <Modal.Body>
                    <h5 className="mb-4 mt-2">{showMessage}</h5>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-success" onClick={handleCloseMessage}>
                            <i class="bi bi-check-circle"></i> Okay
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ProductDetails;