import { useState, useEffect } from "react";
import { useParams } from "react-router";
import RatingStars from "./RatingStars";

import defaultProfileImg from '../../../img/default-profile.png';

const RatingsAndReviews = ({ category, averageRating, totalRatings, fiveStar = 0, fourStar = 0, threeStar = 0, twoStar = 0, oneStar = 0 }) => {
    const { id } = useParams(); //get service id

    const [ filter, setFilter ] = useState("all");
    const [ ratingsAndReviews, setRatingsAndReviews ] = useState([]);

    const [ isLoading, setIsLoading ] = useState(false);

    useEffect( () => {
        setIsLoading(true);

        fetch(`/api/ratings-and-reviews/${category}/${id}/${filter}`)
            .then( res => res.json() )
            .then( res => {
                if( res ) { //check if not null
                    if( 'result' in res ) setRatingsAndReviews(res.result);
                }
                setIsLoading(false);
            } )
        ;
    }, [filter] );

    const changeFilter = (f) => {
        setFilter(f);
    }

    return (
        <div id="ratings-and-reviews-123" className="ratings-and-reviews">
            <h4 className="mb-2">Ratings and Reviews</h4>
            <RatingStars fontSize="1rem" currentRating={ averageRating } allowChange={false} showRating={true} />
            <div>
                <button onClick={ () => changeFilter('all') } className={`btn btn-services-gold p-1 mr-2 mt-2 ${filter === 'all' ? 'selected-and-active' : ''}`} style={{fontSize: '0.8rem'}}>All ({totalRatings})</button>
                <button onClick={ () => changeFilter('5star') } className={`btn btn-services-gold p-1 mr-2 mt-2 ${filter === '5star' ? 'selected-and-active' : ''}`} style={{fontSize: '0.8rem'}}>5<i className="bi bi-star"></i> ({fiveStar})</button>
                <button onClick={ () => changeFilter('4star') } className={`btn btn-services-gold p-1 mr-2 mt-2 ${filter === '4star' ? 'selected-and-active' : ''}`} style={{fontSize: '0.8rem'}}>4<i className="bi bi-star"></i> ({fourStar})</button>
                <button onClick={ () => changeFilter('3star') } className={`btn btn-services-gold p-1 mr-2 mt-2 ${filter === '3star' ? 'selected-and-active' : ''}`} style={{fontSize: '0.8rem'}}>3<i className="bi bi-star"></i> ({threeStar})</button>
                <button onClick={ () => changeFilter('2star') } className={`btn btn-services-gold p-1 mr-2 mt-2 ${filter === '2star' ? 'selected-and-active' : ''}`} style={{fontSize: '0.8rem'}}>2<i className="bi bi-star"></i> ({twoStar})</button>
                <button onClick={ () => changeFilter('1star') } className={`btn btn-services-gold p-1 mr-2 mt-2 ${filter === '1star' ? 'selected-and-active' : ''}`} style={{fontSize: '0.8rem'}}>1<i className="bi bi-star"></i> ({oneStar})</button>
            </div>

            { isLoading &&
                <div className="d-flex justify-content-center user-form rounded py-4">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </div>
            }

            { !isLoading && ratingsAndReviews.length > 0 && ratingsAndReviews.map( (r, index) => (
                <div key={index} className="d-flex mt-3">
                    <div>
                        { r.picture_thumbnail && <img src={r.picture_thumbnail} className="profile-pic rounded-circle" alt="Profile Picture" /> }
                        { !r.picture_thumbnail && <img src={defaultProfileImg} className="profile-pic rounded-circle" alt="Profile Picture" /> }
                    </div>
                    <div className="ml-2" style={{fontSize: '0.8rem'}}>
                        <div style={{marginTop: '-5px'}}>
                            <RatingStars fontSize="0.8rem" currentRating={ r.rating } allowChange={false} />
                        </div>
                        <div style={{color: '#7f7f7f'}}>{r.first_name}</div>
                        <div style={{color: '#7f7f7f'}}>{r.rating_dt_2}</div>

                        { r.review && <div className="text-break" style={{lineHeight: '1.1rem'}}>{r.review}</div> }
                        { !r.review && <div className="text-break font-italic" style={{lineHeight: '1.1rem'}}>No review</div> }
                        
                    </div>
                </div>
            ) ) }

            { !isLoading && filter !== 'all' && ratingsAndReviews.length === 0 && <div className="font-italic mt-3">No ratings and reviews.</div>}
        </div>
    );
}
 
export default RatingsAndReviews;