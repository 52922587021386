import { useState, useEffect, forwardRef } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-bootstrap/Modal';
import { isNumeric, isInt } from 'validator';

import Comments from "../reusable/services/Comments";
import TextAreaCounter from '../reusable/TextAreaCounter';

const ClientDeclinedBookings = ({ profilePicture }) => {
    const [ bookings, setBookings ] = useState([]);

    const [ triggerReload, setTriggerReload ] = useState(true);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isSaving, setIsSaving ] = useState(false);

    const [ resendID, setResendID ] = useState(null);

    const [ showModalSetTime, setShowModalSetTime ] = useState(false);
    const handleSetAndClose = () => {

        if( isNumeric(startHour, { no_symbols: true }) && isInt(startHour, { min: 1, max: 12 } )
                && isNumeric(startMinute, { no_symbols: true }) && isInt(startMinute, { min: 0, max: 59 } )
                && (startAmPm === 'AM' || startAmPm === 'PM' ) ) {

            //set start time and add beginning 0 if needed
            setStartTime( (startHour.length === 1 ? '0' + startHour : startHour)
                + ':' + (startMinute.length === 1 ? '0' + startMinute : startMinute)
                + ' ' + startAmPm );

            if( isNumeric(endHour, { no_symbols: true }) && isInt(endHour, { min: 1, max: 12 } )
                    && isNumeric(endMinute, { no_symbols: true }) && isInt(endMinute, { min: 0, max: 59 } )
                    && (endAmPm === 'AM' || endAmPm === 'PM' ) ) {

                //set end time and add beginning 0 if needed
                setEndTime( (endHour.length === 1 ? '0' + endHour : endHour)
                    + ':' + (endMinute.length === 1 ? '0' + endMinute : endMinute)
                    + ' ' + endAmPm );
            } else setEndTime('');
        } else {
            setStartTime('');
            setEndTime('');
        }
        setShowModalSetTime(false);
    }
    const handleShowModalSetTime = () => setShowModalSetTime(true);

    const [ startTime, setStartTime ] = useState('');
    const [ endTime, setEndTime ] = useState('');

    const [ startHour, setStartHour ] = useState('');
    const [ startMinute, setStartMinute ] = useState('');
    const [ startAmPm, setStartAmPm ] = useState('--');

    const [ endHour, setEndHour ] = useState('');
    const [ endMinute, setEndMinute ] = useState('');
    const [ endAmPm, setEndAmPm ] = useState('--');

    const [ startDate, setStartDate ] = useState('');
    const [ endDate, setEndDate ] = useState('');

    const [ note, setNote ] = useState('');

    const ButtonDate = forwardRef(({ val, onClick, defLabel }, ref) => (
        <button className="btn btn-services-gold mt-2" onClick={onClick} ref={ref} style={{minWidth: '170px'}}>
            <i className="bi bi-calendar-date-fill"></i> { val === '' ? defLabel : val }
        </button>
    ));

    useEffect( () => {
        setIsLoading(true);

        fetch(`/api/service-client-declined-bookings`)
            .then( res => { 
                if(res.ok) return res.json();
                throw new Error('No booking found.');
            }).then( data => {
                if(data) { //check if data is not null
                    
                    setBookings(data);
                    setIsLoading(false);
                    
                } else throw new Error('No booking found.'); //data is null, send error
            }).catch( (e) => {
                alert(e.message);
            })
        ;
    }, [triggerReload] );

    const handleSendBookingRequest = () => {
        if( startDate !== "" ) {
            setIsSaving(true);

            const data = { startDate, endDate, startTime, endTime, note };

            fetch(`/api/service-client-resend-booking-request/${resendID}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then( (result) => result.json() )
            .then( (result) =>  {
                if( 'successfulUpdate' in result && result.successfulUpdate ) {
                    setShowMessage("Your booking request has been sent to the service provider.");
                    setTriggerReload(!triggerReload);
                } else {
                    if( 'err_msg' in result ) alert( result.err_msg );
                    else alert("Unable to process request.");
                }
                setIsSaving(false);
            });
        } else {
            alert("Start date is required.");
        }
    }

    const [ showMessage, setShowMessage ] = useState(null);
    const handleCloseMessage = () => setShowMessage(null);

    const formatDate = (date) => {
        const y = date.getFullYear();
        let m = date.getMonth() + 1;
        let d = date.getDate();

        m = ( m > 9 ? '' : '0' ) + m; //add 0 if single digit
        d = ( d > 9 ? '' : '0' ) + d; //add 0 if single digit

        return m + '/' + d + '/' + y;
    }

    const handleDelete = (id) => {
        if( window.confirm("Are you sure you want to delete this declined booking?") ) { //proceed with deletion
            setIsSaving(true);

            fetch(`/api/service-client-declined-bookings-delete?id=${id}`)
                .then( res => { 
                    if(res.ok) return res.json();
                    throw new Error('Unable to delete booking.');
                }).then( result => {
                    if( 'successfulDelete' in result && result.successfulDelete ) { //successfully deleted
                        setIsSaving(false);
                        setTriggerReload(!triggerReload);
                    }
                }).catch( (e) => {
                    alert(e.message);
                })
            ;
        }
    }

    const handleResend = (id) => {
        clearInputs();
        setResendID(id);
    }

    const handleCancel = () => {
        clearInputs();
        setResendID(null);
    }

    const clearInputs = () => {
        setStartDate('');
        setEndDate('');

        setStartTime('');
        setEndTime('');

        setStartHour('');
        setStartMinute('');
        setStartAmPm('--');

        setEndHour('');
        setEndMinute('');
        setEndAmPm('--');

        setNote('');
    }

    const handleSetHour = (val, type) => {
        if( val === '' || ( isNumeric(val, { no_symbols: true }) && isInt(val, { min: 1, max: 12 } ) && val.length <= 2 ) ) {
            if( type === 'start' ) setStartHour(val);
            else if( type === 'end' ) setEndHour(val);
        }
    }

    const handleSetMinute = (val, type) => {
        if( val === '' || ( isNumeric(val, { no_symbols: true }) && isInt(val, { min: 0, max: 59 } ) && val.length <= 2 ) ) {
            if( type === 'start' ) setStartMinute(val);
            else if( type === 'end' ) setEndMinute(val);
        }
    }

    return (
        <div className="client-approved-bookings book-service service-details services d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center mb-3">Declined Bookings</h3>

            { isLoading && 
                <div className="d-flex justify-content-center user-form rounded py-4">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </div>
            }

            { !isLoading && bookings.length === 0 &&
                <div className="d-flex justify-content-center user-form rounded py-4">
                    You have no declined bookings.
                </div>
            }

            { !isLoading && bookings && bookings.length > 0 && bookings.map( (b, i) => (
                <div key={b.service_booking_id} className="service-details w-100">
                    <div className="alert" role="alert">
                        <div>
                            <div className="mb-1 font-color-gold"><b>{b.serv_name}</b></div>
                        </div>

                        <h6>
                            {b.serv_payment_currency} {b.serv_payment_rate} ({b.serv_payment_unit})
                        </h6>
                        
                        <h6>By {b.provider_name}</h6>

                        { b.note_to_client &&
                            <>
                                <hr />

                                <h6 className="text-break">
                                    <span className="font-color-gold">Reason for declining:</span>
                                    <br />
                                    {b.note_to_client}
                                </h6>
                            </>
                        }

                        <hr />

                        <div className="d-flex flex-row">
                            <div className="mr-1">
                                <b><i className="bi bi-person-fill" style={{color: '#dc3545'}}></i></b>
                            </div>
                            <div>
                                <div>{b.client_name}</div>
                            </div>
                        </div>
                        <div className="d-flex flex-row">
                            <div className="mr-1">
                                <b><i class="bi bi-telephone-fill" style={{color: '#dc3545'}}></i></b>
                            </div>
                            <div>
                                <div>{b.client_contact_num}</div>
                            </div>
                        </div>
                        <div className="d-flex flex-row">
                            <div className="mr-1">
                                <b><i class="bi bi-geo-alt-fill" style={{color: '#dc3545'}}></i></b>
                            </div>
                            <div>
                                <div>{b.client_address}</div>
                            </div>
                        </div>

                        { resendID !== b.service_booking_id && b.start_dt_2 &&
                            <div className="d-flex flex-row">
                                <div className="mr-1">
                                    <b><i className="bi bi-calendar-date-fill" style={{color: '#dc3545'}}></i></b>
                                </div>
                                <div>
                                    <div>
                                        {b.start_dt_2}
                                        {b.end_dt_2 && ' - ' + b.end_dt_2}
                                    </div>
                                </div>
                            </div>
                        }

                        { resendID !== b.service_booking_id && b.start_time &&
                            <div className="d-flex flex-row">
                                <div className="mr-1">
                                    <b><i class="bi bi-clock-fill" style={{color: '#dc3545'}}></i></b>
                                </div>
                                <div>
                                    <div>
                                        {b.start_time}
                                        {b.end_time && ' - ' + b.end_time}    
                                    </div>
                                </div>
                            </div>
                        }

                        { resendID !== b.service_booking_id && b.note_to_provider &&
                            <div className="d-flex flex-row">
                                <div className="mr-1">
                                    <b><i class="bi bi-card-text" style={{color: '#dc3545'}}></i></b>
                                </div>
                                <div className="text-break">Note: {b.note_to_provider}</div>
                            </div>
                        }

                        <hr />
                        <Comments id={b.service_booking_id} allowReply={true} hasComments={b.hasComments} newCommentsCount={b.newCommentsCount} profilePicture={profilePicture} />

                        { resendID === b.service_booking_id &&
                            <>
                                <hr />
                                <div className="d-flex flex-row">
                                    <div className="mr-1">
                                        <b><i className="bi bi-calendar-check" style={{color: '#dc3545'}}></i></b>
                                    </div>
                                    <div className="d-flex flex-column w-100">
                                        <div className="mb-1"><b>Set Date & Time</b></div>
                                        <div className="">
                                            <DatePicker onChange={ (date) => setStartDate( formatDate(date) ) } customInput={<ButtonDate val={startDate} defLabel="Start Date" />} />

                                            <DatePicker onChange={ (date) => setEndDate( formatDate(date) ) } customInput={<ButtonDate val={endDate} defLabel="End Date" />} />

                                            <button className="btn btn-services-gold mt-2" onClick={handleShowModalSetTime} style={{minWidth: '170px'}}>
                                                <i class="bi bi-clock-fill"></i> { startTime === '' ? 'Set Time' : startTime } { endTime === '' ? '' : ' - ' + endTime }
                                            </button>
                                            
                                            <Modal centered backdrop="static" show={showModalSetTime} onHide={handleSetAndClose} className="user-checkout-change-address mobile-modal">
                                                <Modal.Body>
                                                    <div>Start Time</div>
                                                    <div class="input-group mb-2">
                                                        <input type="number" class="form-control" placeholder="HH" value={ startHour } onChange={ (e) => handleSetHour(e.target.value, 'start') } />

                                                        <span class="input-group-text" style={{paddingLeft: '2px', paddingRight: '2px'}}>:</span>
                                                        <input type="number" class="form-control" placeholder="MM" value={ startMinute } onChange={ (e) => handleSetMinute(e.target.value, 'start') } />
                                                        
                                                        <select class="form-select" value={startAmPm} onChange={ (e) => setStartAmPm(e.target.value) }>
                                                            <option value="--">--</option>
                                                            <option value="AM">AM</option>
                                                            <option value="PM">PM</option>
                                                        </select>
                                                    </div>

                                                    <div>End Time</div>
                                                    <div class="input-group mb-2">
                                                        <input type="number" class="form-control" placeholder="HH" value={ endHour } onChange={ (e) => handleSetHour(e.target.value, 'end') } />

                                                        <span class="input-group-text" style={{paddingLeft: '2px', paddingRight: '2px'}}>:</span>
                                                        <input type="number" class="form-control" placeholder="MM" value={ endMinute } onChange={ (e) => handleSetMinute(e.target.value, 'end') } />
                                                        
                                                        <select class="form-select" value={endAmPm} onChange={ (e) => setEndAmPm(e.target.value) }>
                                                            <option value="--">--</option>
                                                            <option value="AM">AM</option>
                                                            <option value="PM">PM</option>
                                                        </select>
                                                    </div>

                                                    <div className="mt-3">
                                                        <button className="btn btn-services-gold" onClick={handleSetAndClose} style={{minWidth: '150px'}}>
                                                            <i className="bi bi-check-circle"></i> Set
                                                        </button>
                                                    </div>
                                                </Modal.Body>
                                            </Modal>

                                        </div>
                                        <div className="mt-2">
                                            <textarea name="txtNote" id="txtNote" rows="2" maxLength="2000" value={ note } onChange={ (e) => setNote(e.target.value) } placeholder="Note to Service Provider" className="form-control w-100"></textarea>
                                            <h6 className="mt-1">
                                                <TextAreaCounter text={note} maxLength="2000" />
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div className="d-flex flex-column">
                                    { !isSaving && 
                                        <button className="btn btn-services-gold w-100" onClick={handleSendBookingRequest}>
                                            <i class="bi bi-book"></i> Resend Booking Request
                                        </button>
                                    }
                                    { isSaving &&
                                        <button className="btn btn-services-gold w-100" type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Sending ...
                                        </button>
                                    }
                                    <button className="btn btn-services-gold w-100 mt-2" onClick={handleCancel}>
                                        <i class="bi bi-x-circle-fill"></i> Cancel
                                    </button>
                                </div>
                            </>
                        }

                        { resendID !== b.service_booking_id && 
                            <>
                                <hr />

                                <button className="btn btn-services-gold ml-2" onClick={ () => handleResend(b.service_booking_id) }>
                                    <i class="bi bi-check-circle-fill"></i> Set & Resend
                                </button>
                                <button className="btn btn-services-gold ml-2" onClick={ () => handleDelete(b.service_booking_id) }>
                                    <i class="bi bi-x-circle-fill"></i> Delete
                                </button>
                            </>
                        }
                    </div>
                </div>
            ))}

            <Modal backdrop="static" show={showMessage} onHide={handleCloseMessage} centered className="mobile-modal">
                <Modal.Body>
                    <h5 className="mb-4 mt-2">{showMessage}</h5>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-success btn-services-gold" onClick={handleCloseMessage}>
                            <i class="bi bi-check-circle"></i> Okay
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
 
export default ClientDeclinedBookings;