import { useState, useEffect } from "react";

import "../../css/mobile/seller-center.css";

import ShippedOrderDetails from "./SellerCenterShippedOrderDetails"
import ShippedOrderItems from "./SellerShippedOrderItems"

const SellerCenterShippedOrders = () => {
    const [sellerShippedOrders, setSellerShippedOrders] = useState([]);

    useEffect(() => {
        fetch('/api/seller-center/shipped-orders')
            .then( res => { return res.json(); } )
            .then( data => { setSellerShippedOrders(data); } );
    }, [] );

    return(
        <div className="d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Shipped Orders</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="seller-form p-3 border rounded">
                    { sellerShippedOrders.length === 0 &&
                        <p className="text-center">No shipped orders found.</p>
                    }
                    { sellerShippedOrders.length > 0 &&
                        <>
                            { sellerShippedOrders.map( ( orders, index ) => (
                                <div key={index}>
                                    <small>
                                        Order # <span className="fw-bold mr-3">{orders.order_id}</span><br/>
                                        Shipped Date: <span className="fw-bold">{orders.shipped_date}</span><br/>
                                        Order Status: <span className="fw-bold">{orders.order_status}</span>
                                            <div>
                                                <div className="mb-2">
                                                    <ShippedOrderItems orders={orders} index={index} />
                                                </div>
                                                <div className="mb-1">
                                                    <ShippedOrderDetails orders={orders} index={index} />
                                                </div>
                                            </div>
                                    </small>
                                </div>
                            ) ) }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default SellerCenterShippedOrders;