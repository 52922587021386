import { useState, useEffect } from "react";

import Comments from "../reusable/services/Comments";

const ClientMyPendingRequests = ({ profilePicture }) => {
    const [ bookings, setBookings ] = useState([]);

    const [ reloadListToggle, setReloadListToggle ] = useState(true);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isSaving, setIsSaving ] = useState(false);

    useEffect( () => {
        setIsLoading(true);

        fetch(`/api/service-client-my-pending-requests`)
            .then( res => { 
                if(res.ok) return res.json();
                throw new Error('No service found.');
            }).then( data => {
                if(data) { //check if data is not null
                    
                    setBookings(data);
                    setIsLoading(false);
                    
                } else throw new Error('No service found.'); //data is null, send error
            }).catch( (e) => {
                alert(e.message);
            })
        ;
    }, [reloadListToggle] );

    const handleCancelRequest = (id) => {
        if( window.confirm("Are you sure you want to cancel this request?") ) { //proceed with cancellation
            setIsSaving(true);

            fetch(`/api/service-client-my-pending-requests-cancel/${id}`)
                .then( res => { 
                    if(res.ok) return res.json();
                    throw new Error('Unable to cancel your pending request.');
                }).then( result => {
                    if( 'successfulCancel' in result && result.successfulCancel ) { //successfully cancelled
                        setIsSaving(false);
                        setReloadListToggle(!reloadListToggle);
                    }
                }).catch( (e) => {
                    alert(e.message);
                })
            ;
        }
    }

    return (
        <div className="client-my-pending-requests book-service service-details services d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center mb-3">My Pending Requests</h3>
            
            { isLoading && 
                <div className="d-flex justify-content-center user-form rounded py-4">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </div>
            }

            { !isLoading && bookings.length === 0 &&
                <div className="d-flex justify-content-center user-form rounded py-4">
                    No pending requests.
                </div>
            }

            { !isLoading && bookings && bookings.length > 0 && bookings.map( (b, i) => (
                <div key={b.service_booking_id} className="service-details w-100">
                    <div className="alert" role="alert">
                        <div>
                            <div className="mb-1 font-color-gold"><b>{b.serv_name}</b></div>
                        </div>

                        <h6>
                            {b.serv_payment_currency} {b.serv_payment_rate} ({b.serv_payment_unit})
                        </h6>

                        <h6>Request date: { b.booking_update_dt_mdy && <>{b.booking_update_dt_mdy}</> }
                            { !b.booking_update_dt_mdy && <>{b.booking_insert_dt}</> }
                        </h6>

                        <hr />

                        { b.start_dt_2 &&
                            <div className="d-flex flex-row">
                                <div className="mr-1">
                                    <b><i className="bi bi-calendar-date-fill" style={{color: '#dc3545'}}></i></b>
                                </div>
                                <div>
                                    <div>
                                        {b.start_dt_2}
                                        {b.end_dt_2 && ' - ' + b.end_dt_2}
                                    </div>
                                </div>
                            </div>
                        }

                        { b.start_time &&
                            <div className="d-flex flex-row">
                                <div className="mr-1">
                                    <b><i class="bi bi-clock-fill" style={{color: '#dc3545'}}></i></b>
                                </div>
                                <div>
                                    <div>
                                        {b.start_time}
                                        {b.end_time && ' - ' + b.end_time}    
                                    </div>
                                </div>
                            </div>
                        }

                        { b.note_to_provider &&
                            <div className="d-flex flex-row">
                                <div className="mr-1">
                                    <b><i class="bi bi-card-text" style={{color: '#dc3545'}}></i></b>
                                </div>
                                <div className="text-break">Note: {b.note_to_provider}</div>
                            </div>
                        }
                        
                        <hr />

                        <Comments id={b.service_booking_id} allowReply={true} hasComments={b.hasComments} newCommentsCount={b.newCommentsCount} profilePicture={profilePicture} />

                        <hr />

                        { isSaving &&
                            <button className="btn btn-services-gold ml-2" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Cancel Request
                            </button>
                        }
                        { !isSaving &&
                            <button className="btn btn-services-gold ml-2" onClick={ () => handleCancelRequest(b.service_booking_id) }>
                                <i className="bi bi-x-circle-fill"></i> Cancel Request
                            </button>
                        }
                        
                    </div>
                </div>
            ))}
        </div>
    );
}
 
export default ClientMyPendingRequests;