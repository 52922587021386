import { useEffect, useState } from "react";
import isEmail from 'validator/lib/isEmail';

const ResetPassword = () => {
    const [ email, setEmail ] = useState('');
    const [ errors, setErrors ] = useState([]); //set to empty array
    const [ message, setMessage ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect( () => {
        //set body background color
        document.body.style.backgroundColor = "#171a26";
        return () => { //unmount
            document.body.style.backgroundColor = "#f5f6f9";
        }
    }, [] );

    const handleSubmit = (e) => {
        e.preventDefault(); //will prevent default page load

        if( email ) { //check if not empty
            if( isEmail(email) ) { //check if email format is valid
                setIsLoading(true);

                fetch('/api/reset-password', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email })
                })
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( Array.isArray(result) ) { //setErrors to result only if returned is an array (of error messages)
                        setErrors( result );
                        setMessage('');
                    }
                    else { //no error
                        setErrors([]); //set to empty array
                        setEmail('');
                        setMessage( result.success_msg );
                    }
                    
                    setIsLoading(false);
                });

            } else { //invalid email format
                setErrors([ { err_msg: 'Please enter a valid email.' } ]);
                setMessage('');
            }
        } else { //empty
            setErrors([ { err_msg: 'Please provide an email.' } ]);
            setMessage('');
        }
    }

    return (
        <div className="reset-password d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Reset Password</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="reset-password-form p-4 border rounded">
                    <h5 className="mb-3">
                        Provide us with the email address you use to sign in here at Trivut Marketplace and we will send you instructions to reset your password.
                    </h5>
                    <h5 className="mb-3">
                        You will need to answer the 3 security questions, we hope, you provided.
                    </h5>

                    <form onSubmit={ handleSubmit }>
                        { errors &&
                            errors.map( (e) => (
                                <div className="error mb-2" key={e.err_msg}>
                                    <span>{e.err_msg}</span>
                                </div>
                            ) )
                        }
                        { message &&
                            <div className="container success-message mb-2">
                                <span>{message}</span>
                            </div>
                        }

                        <div className="d-flex flex-column">
                            <label htmlFor="email">Email Address</label>
                            <input type="text" value={email} onChange={ e => setEmail( e.target.value ) } id="email" className="input-text" />
                        </div>

                        <div className="d-flex flex-column mt-4">
                            { !isLoading && 
                                <button className="btn btn-primary">
                                    <i class="bi bi-lock-fill"></i> Reset Password
                                </button> 
                            }
                            { isLoading && 
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                                </button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;