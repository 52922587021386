import { useState,useEffect } from "react";

import "../../css/mobile/styles.css";

// import UserOrderRefundReturn from "./UserOrderReturnRefund";

const OrderItems = ({receivedOrders, triggerRerender, receivedDate}) => {
    const [orderItems, setOrderItems] = useState([]);
    const [orderID] = useState(receivedOrders.order_id);

    // const [showReturnButton, SetShowReturnButton] = useState(false);
    // const [showDisabledButton, SetShowDisabledButton] = useState(true);

    // // a parse function for mm/dd/yyyy date format
    // function parseDate(str) {
    //     var mdy = str.split('/');
    //     return new Date(mdy[2], mdy[0]-1, mdy[1]);
    // }

    // function datediff(first, second) {
    //     // Take the difference between the dates and divide by milliseconds per day.
    //     // Round to nearest whole number to deal with DST.
    //     return Math.round((second-first)/(1000*60*60*24));
    // }

    // const itemReceivedDate = receivedDate;
   
    // let today = new Date();
    // let dd = today.getDate();
    // let mm = today.getMonth()+1; 
    // const yyyy = today.getFullYear();
    // if (dd<10) {
    //     dd=`0${dd}`;
    // } 
    // if(mm<10) {
    //     mm=`0${mm}`;
    // }
    // today = `${mm}/${dd}/${yyyy}`;

    // const dateDifference = datediff(parseDate(itemReceivedDate), parseDate(today));

    useEffect(() => {
        fetch(`/api/received-order-items/${orderID}`)
            .then( res => { return res.json(); } )
            .then( data => { setOrderItems(data); } );

            // if (dateDifference > 7 ) {
            //     SetShowDisabledButton(true);
            //     SetShowReturnButton(false);
            // } else {
            //     SetShowReturnButton(true);
            //     SetShowDisabledButton(false);
            // };
    }, [] );

    return(
        <>
            {orderItems.length === 0 &&
                <p className="text-center">No received orders found.</p>
            }
            {orderItems.length > 0 &&
                <>
                    { orderItems.map( ( items, index ) => (
                        <div className="p-2 border border-secondary mb-2" key={index}>
                            <div className="p-1">
                                <img src={items.order_thumbnail} className="rounded float-start product-thumb ml-1 mr-2" alt={items.order_name + " image"} title={items.order_name} />
                                <span className="title-limit">{items.order_name}</span> <br /><span className="fw-light">{items.order_currency} {items.order_amount}</span>
                            </div>
                            <div className="row">
                                <span className="ml-1">Condition: {items.order_condition}</span>
                                { items.order_warranty_type !== null &&
                                <span className="ml-1">Warranty: {items.order_warranty_type}</span>
                                }
                                { items.order_warranty_period !== null &&
                                    <span className="ml-1">Warranty Period: {items.order_warranty_period}</span>
                                }
                            </div>
                            {/* { items.order_status === "return/refund" &&
                                <span className="ml-1"><strong>Status: </strong><em>Return/Refund requested</em></span>
                            }
                            { items.order_status !== 'return/refund' &&
                                <>
                                    {   
                                        showReturnButton?
                                            <UserOrderRefundReturn items={items} index={items.item_id} triggerRerender={triggerRerender} />
                                        :null
                                    }
                                    {   
                                        showDisabledButton?
                                            <span class="badge bg-secondary">7-day period for refurn/return has already expired.</span>
                                        :null
                                    }
                                </>
                            } */}
                        </div>
                    ) ) }
                </>
            }         
        </>
    )
}

export default OrderItems;