import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import UserSCartWListFavoritesDelete from "../../UserSCartWListFavoritesDelete";

const Favorites = ({ path }) => {
    const [ favorites, setFavorites ] = useState([]);
    const [ rerenderToggle, setRerenderToggle ] = useState(true); //used to trigger rerender after an insert, update, or delete address
    const [ isLoading, setIsLoading ] = useState(true);

    useEffect( () => {
        fetch('/api/user-service-favorites')
            .then( res => { return res.json(); } )
            .then( data => { 
                setFavorites(data);
                setIsLoading(false);
            });
    }, [rerenderToggle] );

    const triggerRerender = () => {
        setRerenderToggle( !rerenderToggle ); //set to opposite boolean value, just so state is changed and would trigger rerender
    }

    return (
        <div className="favorites mt-4 px-3">
            <h3 className="d-flex justify-content-center">
                <i className='fas fa-tools text-danger mr-2'></i> My Favorites
            </h3>

            { isLoading &&
                <div className="d-flex justify-content-center user-form rounded py-4 border-danger mt-3">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </div>
            }

            { !isLoading && favorites.length === 0 &&
                <div className="d-flex justify-content-center user-form rounded py-4 border-danger mt-3">
                    No services available.
                </div>
            }

            { !isLoading && favorites.length > 0 && favorites.map( ( s, index ) => (
                <div className="service-container mt-3" key={index}>
                    <Link to={`${path}service/` + s.service_id}>
                        <div className="alert" role="alert">
                            {s.serv_name}
                        </div>
                    </Link>
                    <UserSCartWListFavoritesDelete deleteFrom="favorites" service_id={s.service_id} triggerRerender={triggerRerender} />
                </div>
            ) ) }
            

        </div>
    );
}
 
export default Favorites;