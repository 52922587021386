import { useState, useEffect } from "react";

import "../../css/mobile/seller-center.css";

import OrderDetails from "./UserOrderDetails"
import OrderItems from "./OrderItems"

const UserOrders = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        fetch('/api/user-orders')
            .then( res => { return res.json(); } )
            .then( data => { setOrders(data); } );
    }, [] );

    return(
        <div className="d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">My Orders</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="user-order p-3 border rounded">
                    {orders.length === 0 &&
                        <p className="text-center">No orders found.</p>
                    }
                    {orders.length > 0 &&
                        <>
                            { orders.map( ( orders, index ) => (
                                <div key={index}>
                                    <small>
                                        Order # <span className="fw-bold mr-3">{orders.order_id}</span><br/>
                                        Order Date: <span className="fw-bold">{orders.order_date}</span>
                                            <div className="p-2 border border-secondary rounded mb-2">
                                                <span>Status: <strong>Pending delivery</strong></span>
                                                <div className="p-1 mb-2">
                                                    <OrderItems orders={orders} index={index} />
                                                </div>
                                                <div className="row">
                                                    <OrderDetails orders={orders} index={index} />
                                                </div>
                                            </div>
                                    </small>
                                </div>
                            ) ) }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default UserOrders;