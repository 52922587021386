import { useState, useEffect } from "react";

import UserAddressAdd from "./reusable/UserAddressAdd";
import UserAddressDelete from "./reusable/UserAddressDelete";
import UserAddressEdit from "./reusable/UserAddressEdit";

const UserAddresses = ({ isAuthenticated }) => {
    const [ addresses, setAddresses ] = useState(null);
    const [ rerenderToggle, setRerenderToggle ] = useState(true); //used to trigger rerender after an insert, update, or delete address
    const [ countries, setCountries ] = useState([]);
    
    useEffect( () => {
        fetch('/api/user-addresses')
            .then( res => { return res.json(); } )
            .then( data => { setAddresses(data); } )
        ;

        fetch('/api/country-list')
            .then( res => { return res.json(); } )
            .then( data => { setCountries(data) } )
        ;
    }, [rerenderToggle] );
    

    const triggerRerender = () => {
        setRerenderToggle( !rerenderToggle ); //set to opposite boolean value, just so state is changed and would trigger rerender
    }
    
    return (
        <div className="user-addresses d-flex flex-column pt-2 px-3">
            <div className="d-flex justify-content-center align-items-center">
                <h3>Addresses</h3>
                { isAuthenticated && addresses && addresses.length < 3 &&
                    <button className="btn add" data-bs-toggle="modal" data-bs-target="#modal-add-new-address" title="Add new address">
                        <i className="bi bi-plus-circle-fill bi-md"></i>
                    </button>
                }
            </div>

            { isAuthenticated && addresses && 
                <div className="address-list">
                    { addresses.map( ( address, index ) => (
                        <div key={index} className="d-flex justify-content-center mt-2">
                            <div className="address d-flex flex-column">
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex mb-1 mr-auto">
                                        { address.is_default === 1 && 
                                            <span class="badge badge-default">Default</span>
                                        }
                                        <span class="badge badge-addr-type">{ address.addr_type }</span>
                                    </div>
                                    <div className="d-flex">
                                        <button className="btn btn-primary edit-button" data-bs-toggle="modal" data-bs-target={"#modal-edit-address-" + index}>
                                            <i class="bi bi-pencil-square"></i>
                                        </button>

                                        <UserAddressEdit address={address} index={index} triggerRerender={triggerRerender} countries={countries} />
                                        <UserAddressDelete address={address} triggerRerender={triggerRerender} />
                                    </div>
                                </div>
                                
                                <div className="d-flex mt-3">
                                    <div className="field-name mx-1">Address 1:</div>
                                    <div className="mx-1">{ address.address1 }</div>
                                </div>
                                <div className="d-flex">
                                    <div className="field-name mx-1">Address 2:</div>
                                    <div className="mx-1">{ address.address2 }</div>
                                </div>
                                <div className="d-flex">
                                    <div className="field-name mx-1">City:</div>
                                    <div className="mx-1">{ address.city }</div>
                                </div>
                                <div className="d-flex">
                                    <div className="field-name mx-1">State/Province:</div>
                                    <div className="mx-1">{ address.state_territory }</div>
                                </div>
                                <div className="d-flex">
                                    <div className="field-name mx-1">Postal Code:</div>
                                    <div className="mx-1">{ address.postal_code }</div>
                                </div>
                                <div className="d-flex">
                                    <div className="field-name mx-1">Country:</div>
                                    <div className="mx-1">{ address.country }</div>
                                </div>
                            </div>
                        </div>
                    ) ) }

                    <UserAddressAdd triggerRerender={triggerRerender} countries={countries} />
                </div>
            }
            { isAuthenticated && addresses && addresses.length === 0 &&
                <div>
                    <hr className="mt-4" />
                    <h6 className="d-flex justify-content-center my-5">Your address list is empty.</h6>
                </div>
            }
        </div>
    );
}
 
export default UserAddresses;