import { useHistory } from "react-router";
import useFetch from "../useFetch";

const SignOut = ({ path }) => {

    const { data: user } = useFetch('/api/signout');
    const history = useHistory();

    if( user && user.isSignedOut ) {
        history.push(`${path}signin`);
    }

    return null;
}

export default SignOut;