import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import queryString from 'query-string'

import UserSCartWListFavoritesDelete from "./UserSCartWListFavoritesDelete";

import '../../css/mobile/user.css';

const UserShoppingCart = ({ path }) => {
    const [ shoppingCart, setShoppingCart ] = useState([]);
    const [ prodQuantity, setProdQuantity ] = useState([]);
    const [ hasAddress, setHasAddress ] = useState(false);
    const [ hasPaymentMethod, setHasPaymentMethod ] = useState(false);
    const [ checkedProducts, setCheckedProducts ] = useState([]);
    const [ currency, setCurrency ] = useState('');
    const [ rerenderToggle, setRerenderToggle ] = useState(true); //used to trigger rerender after an insert, update, or delete address

    const [ items, setItems ] = useState(0);
    const [ totalAmount, setTotalAmount ] = useState(0);
    
    const [ isBelowMaxLimit, setIsBelowMaxLimit ] = useState(false);
    const [ isAboveMinLimit, setIsAboveMinLimit ] = useState(false);

    const [ showMessage, setShowMessage ] = useState(null);
    const [ showMessageGoToPage, setShowMessageGoToPage ] = useState(null);
    const [ cancelOrderMsgShown, setCancelOrderMsgShown ] = useState(false);
    const handleCloseMessage = () => setShowMessage(null);

    const [ payoutErrorMsgShown, setPayoutErrorMsgShown ] = useState(false);

    const [ showSuccessMessage, setShowSuccessMessage ] = useState(null);
    const [ successOrderMsgShown, setSuccessOrderMsgShown ] = useState(false);
    const handleCloseSuccessMessage = () => setShowSuccessMessage(null);

    let queries = queryString.parse( useLocation().search ); //query string

    useEffect( () => {
        fetch('/api/user-shopping-cart')
            .then( res => { return res.json(); } )
            .then( data => { 
                setShoppingCart(data.result);

                setProdQuantity( "1".repeat(data.result.length).split("").map(Number) ); //generate an array of 1's and set product quantity

                setHasAddress(data.hasAddress);
                setHasPaymentMethod(data.hasPaymentMethod);

                //show successful order message
                if( queries.m === 'success' && !successOrderMsgShown ) {
                    setShowSuccessMessage(true);
                    setSuccessOrderMsgShown(true);
                }

                //show cancel order message
                if( queries.m === 'cancel' && !cancelOrderMsgShown ) {
                    handleOnClickCheckOutMessage('Your order has been cancelled.'); //reuse function
                    setCancelOrderMsgShown(true);
                }

                //show payout error message
                if( queries.m === 'payout-denied' && !payoutErrorMsgShown ) {
                    handleOnClickCheckOutMessage('Paypal denied payout to seller.'); //reuse function
                    setPayoutErrorMsgShown(true);
                }
            } );
    }, [rerenderToggle] );

    const triggerRerender = () => {
        setRerenderToggle( !rerenderToggle ); //set to opposite boolean value, just so state is changed and would trigger rerender
    }

    const handleCheckUncheckProduct = (product, price, e) => {
        const id = product.product_id;
        let qty = parseInt( document.getElementById('qty-' + id).value );
        let cur;

        if( e.target.checked ) {
            if( currency === "" ) {
                setCurrency( product.prod_currency );
                cur = product.prod_currency;
            }
            else {
                //temporary code: one product in one checkout only
                e.target.checked = false;
                alert(`Multiple products in one checkout is currently not supported. Try this functionality next time because this will be added in the near future.`);
                return; //do not execute below code

                cur = currency;
                if( currency !== product.prod_currency ) { //different currency
                    e.target.checked = false;
                    alert(`Multiple currencies in a single checkout is not allowed. Your current checked items use ${currency}.`);
                    return; //do not execute below code
                }
            }
            countItems(cur);

            setCheckedProducts( [ ...checkedProducts, {...product, qty}] ); //add product to checked products
        }
        else {
            if( items - 1 === 0 ) {
                setCurrency(''); //set to empty
                cur = '';
            } else cur = currency;
            countItems(cur);

            setCheckedProducts( checkedProducts.filter(item => item.product_id !== product.product_id ) ); //remove product from checked products
        }
    }

    const handleOnClickCheckOutMessage = (msg, goToPage) => {
        setShowMessage(msg);
        setShowMessageGoToPage(goToPage);
    }

    const handleChangeQuantity = (index, qty_avail) => e => {
        let newArr = [...prodQuantity];
        const val = parseInt(e.target.value);

        if( Number.isInteger( val ) ) {
            if( val < 1 ) newArr[index] = 1; //set to 1 if less than 1
            else if( val > qty_avail ) newArr[index] = qty_avail; //set to qty_avail if more than qty_avail
            else newArr[index] = val; //else set to the value

            countItems();
        }
        else if( Number.isNaN(val) ) {
            newArr[index] = "";
            setItems(0);
            setTotalAmount(0);
        
        } else {
            newArr[index] = 1;
            countItems();
        }

        setProdQuantity(newArr);
    }

    const countItems = (cur) => {
        let totalItems = 0;
        let totAmt = 0;

        for( let i = 0; i < shoppingCart.length; i++ ) {
            let id = shoppingCart[i].product_id;
            
            const cbox = document.getElementById('cbox-' + id);

            if( cbox.checked ) {
                
                let qty = parseInt( document.getElementById('qty-' + id).value );
                const qty_avail = parseInt( document.getElementById('qty-avail-' + id).innerHTML );
                const price = parseFloat( document.getElementById('price-' + id).innerHTML.replace(/,/g, "") );

                if( qty < 1 ) qty = 1; //set back to 1 if less than 1
                if( qty > qty_avail ) qty = qty_avail;
                
                totalItems = totalItems + qty;
                totAmt = totAmt + (price * qty);

                //update checked products
                for( let j = 0; j < checkedProducts.length; j++ ) {
                    if( checkedProducts[j].product_id === id ) {
                        let newCheckedProducts = checkedProducts;
                        newCheckedProducts[j].qty = qty;
                        setCheckedProducts( newCheckedProducts );
                    }
                }

            }
        }
        setItems(totalItems);
        setTotalAmount(totAmt);

        //set isBelowMaxLimit
        if( cur === 'USD' ) { //max limit of 3k for USD
            if( totAmt < 3000 ) setIsBelowMaxLimit(true);
            else setIsBelowMaxLimit(false);
        } else if( cur === 'PHP' ) { //max limit of 150k for PHP
            if( totAmt < 150000 ) setIsBelowMaxLimit(true);
            else setIsBelowMaxLimit(false);
        }

        //set isAboveMinLimit
        if( cur === 'USD' ) { //min limit of 1 for USD
            if( totAmt >= 1 ) setIsAboveMinLimit(true);
            else setIsAboveMinLimit(false);
        } else if( cur === 'PHP' ) { //min limit of 50 for PHP
            if( totAmt >= 50 ) setIsAboveMinLimit(true);
            else setIsAboveMinLimit(false);
        }
    }

    
    return (
        <div className="shopping-cart mobile-container d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">My Shopping Cart</h3>
            { shoppingCart &&
                <div className="d-flex flex-column mt-3">
                    { shoppingCart.map( ( product, index ) => (
                        <div key={product.product_id} className="product-container">
                            <UserSCartWListFavoritesDelete deleteFrom="shopping-cart" product={product} triggerRerender={triggerRerender} />

                            <div className="alert d-flex" role="alert">
                                <div className="d-flex flex-column">

                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id={ "cbox-" + product.product_id }  title="Add/Remove to/from check out"
                                            onChange={ (e) => handleCheckUncheckProduct( product, parseFloat(product.prod_price_with_fees), e ) } />
                                    </div>

                                    <div className="mt-2">
                                        { 'prod_thumbnail' in product && 
                                            <img className="sc-thumbnail" src={product.prod_thumbnail} alt={product.product_name} />
                                        }
                                        { !('prod_thumbnail' in product) && 
                                            <i className="bi bi-image" style={{fontSize: '3rem'}}></i>
                                        }
                                    </div>
                                </div>
                                
                                <div className="ml-3 overflow-auto">
                                    <Link to={`${path}product/` + product.product_id} title="Go to product details">
                                        { product.prod_name.slice(0, 50) } { product.prod_name.length > 50 && '...' }
                                    </Link>
                                    

                                    { (product.prod_category_1 || product.prod_category_2 || product.prod_category_3) &&
                                        <div className="product-categories d-flex flex-column mt-1">
                                            <div><span className="badge bg-secondary mr-1">{product.prod_category_1}</span></div>
                                            <div><span className="badge bg-secondary mr-1">{product.prod_category_2}</span></div>
                                            <div><span className="badge bg-secondary">{product.prod_category_3}</span></div>
                                        </div>
                                    }

                                    <h4 className="sc-price d-flex align-items-center mt-2">
                                        <span className="mr-1">{product.prod_currency}</span>
                                        <span id={ "price-" + product.product_id }>{product.prod_price_with_fees.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </h4>

                                    <div className="d-flex quantity mt-2">
                                        <label htmlFor={ "qty-" + product.product_id }>Quantity</label>
                                        <input id={ "qty-" + product.product_id } type="number" className="form-control mx-2" value={prodQuantity[index]} onChange={ handleChangeQuantity( index, product.prod_quantity ) } />
                                        <span id={ "qty-avail-" + product.product_id }>{product.prod_quantity}</span><span>&nbsp;available</span>
                                    </div>
                                    


                                    { ( product.prod_condition || product.prod_color ) &&
                                        <div className="d-flex flex-column mt-1">
                                            { product.prod_condition && <div><span title="Condition" className="badge bg-primary mr-1">{product.prod_condition}</span></div> }
                                            { product.prod_color && <div><span title="Color" className="badge bg-dark mr-1">Color: {product.prod_color}</span></div> }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        
                    ) ) }
                    { shoppingCart.length > 0 &&
                        <div className="alert total-amount d-flex flex-column" role="alert">
                            <span>Items: {items}</span>
                            <span>
                                Total Amount: {currency} { totalAmount.toFixed(2) //set to 2 decimal places
                                    .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") // add commas if necessary
                                }
                            </span>

                            <div className="check-out mt-3">
                                { items === 0 && <button className="btn btn-primary" onClick={ () => handleOnClickCheckOutMessage('No items selected for checkout.') }><i className="bi bi-cart-check"></i> Check Out</button> }
                                { items > 0 && !hasAddress && <button className="btn btn-primary" onClick={ () => handleOnClickCheckOutMessage('No address found. Please provide your address.', 'address') }><i className="bi bi-cart-check"></i> Check Out</button> }
                                { items > 0 && hasAddress && !hasPaymentMethod && <button className="btn btn-primary" onClick={ () => handleOnClickCheckOutMessage('No payment method found. Please provide one.', 'payment') }><i className="bi bi-cart-check"></i> Check Out</button> }
                                { items > 0 && hasAddress && hasPaymentMethod && !isBelowMaxLimit && <button className="btn btn-primary" onClick={ () => handleOnClickCheckOutMessage('The total amount exceeds the maximum limit.') }><i className="bi bi-cart-check"></i> Check Out</button> }
                                
                                { items > 0 && hasAddress && hasPaymentMethod && isBelowMaxLimit && !isAboveMinLimit && <button className="btn btn-primary" onClick={ () => handleOnClickCheckOutMessage('The total amount is below the minimum limit.') }><i className="bi bi-cart-check"></i> Check Out</button> }
                                
                                { items > 0 && hasAddress && hasPaymentMethod && isBelowMaxLimit && isAboveMinLimit &&
                                    <Link className="btn btn-primary"
                                        to={{
                                            pathname: `${path}user/checkout`,
                                            state: { checkedProducts, currency, totalAmount }
                                        }}> <i className="bi bi-cart-check"></i> Check Out
                                    </Link>
                                }
                            </div>
                        </div>
                    }
                    { shoppingCart.length === 0 &&
                        <h5 className="my-5 text-center">Shopping cart is empty.</h5>
                    }
                </div>
            }
            { !shoppingCart &&
                <div className="d-flex justify-content-center my-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            }

            <Modal show={ showMessage !== '' && showMessage !== null } onHide={handleCloseMessage} centered className="mobile-modal">
                <Modal.Body>
                    <h5 className="mb-4 mt-2">{showMessage}</h5>
                    <hr />
                    <div className="d-flex justify-content-end">
                        { (showMessageGoToPage === '' || showMessageGoToPage === undefined) &&
                            <button className="btn btn-danger" onClick={handleCloseMessage}>
                                <i className="bi bi-exclamation-circle"></i> Okay
                            </button>                        
                        }

                        { showMessageGoToPage === 'address' &&
                            <Link to={`${path}user/addresses`} className="btn btn-danger">
                                <i className="bi bi-exclamation-circle"></i> Okay
                            </Link>                        
                        }

                        { showMessageGoToPage === 'payment' &&
                            <Link to={`${path}user/payment-options`} className="btn btn-danger">
                                <i className="bi bi-exclamation-circle"></i> Okay
                            </Link>                        
                        }
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showSuccessMessage} onHide={handleCloseSuccessMessage} centered className="mobile-modal">
                <Modal.Body>
                    <h5 className="mb-4 mt-2">You have successfully paid and your order has been posted.</h5>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-success" onClick={handleCloseSuccessMessage}>
                            <i className="bi bi-exclamation-circle"></i> Okay
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default UserShoppingCart;