import { useState, useEffect } from "react";
import ExperienceAddEditDelete from "../reusable/services/ExperienceAddEditDelete";
import SkillAddEditDelete from "../reusable/services/SkillAddEditDelete";
import ReferenceAddEditDelete from "../reusable/services/ReferenceAddEditDelete";

import useFetch from "../../useFetch";

const ManageServicesEdit = ({ id, divID, reloadList }) => {
    const { data: categoryList } = useFetch('/api/services/category-list'); //get category list

    const [status, setStatus] = useState("Active");
    const [name, setName] = useState("");
    const [descr, setDescr] = useState("");
    const [cat1, setCat1] = useState("");
    const [currency, setCurrency] = useState("");
    const [payRate, setPayRate] = useState("");
    const [unit, setUnit] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("");
    const [country, setCountry] = useState("");
    const [timeline, setTimeline] = useState("");
    const [contract, setContract] = useState("");
    const [skills, setSkills] = useState([]);
    const [experiences, setExperiences] = useState([]);
    const [references, setReferences] = useState([]);
    const [profilePic, setProfilePic] = useState("");
    const [portfolioImages, setPortfolioImages] = useState([]);

    const [deductibleFees, setDeductibleFees] = useState("");
    const [feeAmount, setFeeAmount] = useState("");
    const [allowRefund, setAllowRefund] = useState(false);
    
    const [descrCount, setDescrCount] = useState(0);
    const [contTermsCount, setContTermsCount] = useState(0);
    const [portfolioImageIDs, setPortfolioImageIDs] = useState([]);
    const [removedPortImgIDs, setRemovedPortImgIDs] = useState([]);
    const [inputFilePortImages, setInputFilePortImages] = useState("");
    const [inputFileProfilePic, setInputFileProfilePic] = useState("");

    const [loading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [errors, setErrors] = useState([]);
    const [message, setMessage] = useState("");

    const descriptionCount = (descr) => {
        setDescr(descr);
        setDescrCount(descr.length);
    }
    const contractTermsCount = (ct) => {
        setContract(ct);
        setContTermsCount(ct.length);
    }

    useEffect( () => {
        fetch(`/api/services-manage-edit?id=${id}`)
            .then( res => { 
                if(res.ok) return res.json();
                throw new Error('Cannot find service.');
            }).then( r => {
                setName( r.serv_name );
                setDescr( r.serv_descr );
                setCat1( r.serv_category_1 );
                setCurrency( r.serv_payment_currency );
                setPayRate( r.serv_payment_rate );
                setUnit( r.serv_payment_unit );
                setProfilePic( r.profile_pic_thumb );
                setCity( r.serv_pref_city );
                setProvince( r.serv_pref_province );
                setCountry( r.serv_pref_country );
                setTimeline( r.serv_timeline );
                setContract( r.serv_contract_terms );
                setStatus( r.serv_status );
                
                let skills_arr = [];
                if( Array.isArray( r.skill ) && Array.isArray( r.skill_level ) ) {
                    if( r.skill.length > 0 && r.skill.length === r.skill_level.length ) {
                        for(let i = 0; i < r.skill.length; i++) {
                            skills_arr.push( { skill: r.skill[i], level: r.skill_level[i] } );
                        }
                    }
                }
                setSkills( skills_arr );

                let experiences_arr = [];
                if( Array.isArray( r.experience ) && Array.isArray( r.years ) ) {
                    if( r.experience.length > 0 && r.experience.length === r.years.length ) {
                        for(let i = 0; i < r.experience.length; i++) {
                            experiences_arr.push( { experience: r.experience[i], years: r.years[i] } );
                        }
                    }
                }
                setExperiences( experiences_arr );

                if( r.serv_references ) setReferences( r.serv_references );

                if( r.portfolio_images_thumb && r.portfolio_image_ids ) {
                    setPortfolioImages( r.portfolio_images_thumb );
                    setPortfolioImageIDs( r.portfolio_image_ids );
                }

                setContTermsCount( r.serv_contract_terms.length );
                setDescrCount(r.serv_descr.length);

                if( r.serv_advance_fee !== null && r.serv_advance_fee !== "" && r.serv_advance_fee !== 0 ) {
                    setDeductibleFees("Advance fee");
                    setFeeAmount(r.serv_advance_fee);
                } else if( r.serv_reservation_fee !== null && r.serv_reservation_fee !== "" && r.serv_reservation_fee !== 0 ) {
                    setDeductibleFees("Reservation fee");
                    setFeeAmount(r.serv_reservation_fee);
                    if( r.serv_res_fee_refundable !== null && r.serv_reservation_fee !== "" && r.serv_reservation_fee ) setAllowRefund(true);
                    else setAllowRefund(false);
                } else {
                    setDeductibleFees("");
                    setFeeAmount("");
                }

                setLoading(false);
            }).catch( (e) => {
                alert(e.message);
            })
        ;
    }, [] );

    const handleSubmit = (e) => {
        e.preventDefault(); //prevent default page load

        if( name !== "" && descr !== "" ) {
            const editServiceForm = document.getElementById('edit-service-form');
            let formData = new FormData(editServiceForm);
            
            setIsSaving(true);

            fetch('/api/services-edit', {
                    method: 'POST',
                    body: formData
                }).then( (result) => result.json()
                ).then( (result) =>  {
                    if(result.successfulUpdate) {
                        setMessage('You have successfully updated a service.');
                        setErrors('');
                        window.scrollTo(0, document.getElementById(divID).offsetTop); //scroll to top of service to see message
                        
                        if( 'profile_pic_thumb' in result ) setProfilePic( result.profile_pic_thumb );
                        if( 'portfolio_image_thumbs' in result ) {
                            let arrPortImgs = [], arrPortImgIDs = [];
                            for( let i = 0; i < result.portfolio_image_thumbs.length; i++ ) {
                                arrPortImgs.push( result.portfolio_image_thumbs[i] );
                                arrPortImgIDs.push( result.portfolio_image_ids[i] );
                            }
                            setPortfolioImages( portfolioImages.concat( arrPortImgs ) );
                            setPortfolioImageIDs( portfolioImageIDs.concat( arrPortImgIDs ) );
                        }
                        setRemovedPortImgIDs([]);
                        clearFields();
                        reloadList();
                    } else if( Array.isArray(result) ) { //returned array of errors
                        setErrors(result);
                        setMessage('');
                        window.scrollTo(0, document.getElementById(divID).offsetTop);
                    } else {
                        setErrors([{ err_msg: 'Unable to update a service.' }]);
                        setMessage('');
                        window.scrollTo(0, document.getElementById(divID).offsetTop);
                    }
                    setIsSaving(false);
                })
            ;
        }
        else {
            // setErrors([{ err_msg: 'Fields marked with asterisk (*) are required.' }]);
            // setMessage('');
            // window.scrollTo(0, document.getElementById(divID).offsetTop); //scroll to top to see message
        }
    }

    const addSkill = (skill, level) => {
        const skill_json = {skill, level};
        setSkills([...skills, skill_json]); //push json to array
    }
    const handleRemoveSkill = (index) => setSkills(sks => sks.filter((s, i) => i !== index)); //remove skill

    const addExperience = (experience, years) => {
        const experience_json = {experience, years};
        setExperiences([...experiences, experience_json]); //push json to array
    }
    const handleRemoveExperience = (index) => setExperiences(exps => exps.filter((e, i) => i !== index)); //remove experience

    const addReference = (reference) => setReferences([...references, reference]); //push reference to array
    const handleRemoveReference = (index) => setReferences(refs => refs.filter((r, i) => i !== index)); //remove reference

    const handleRemovePortfolioImage = (index) => {
        setRemovedPortImgIDs( [...removedPortImgIDs, portfolioImageIDs[index]] );
        setPortfolioImages( pi => pi.filter((p, i) => i !== index) ); //remove portfolio image
        setPortfolioImageIDs( pi => pi.filter((p, i) => i !== index) ); //remove portfolio image id
    }

    const clearFields = () => { //clear input file fields
        setInputFilePortImages("");
        setInputFileProfilePic("");
    }

    const changeStatus = (e) => {
        if( e.target.value === "Active" ) setStatus("Inactive");
        else setStatus("Active");
    }

    return (
        <div className="services-manage-edit">
            { loading &&
                <div className="d-flex justify-content-center">
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </div>
            }

            { !loading &&
                <form id="edit-service-form" onSubmit={handleSubmit} encType="multipart/form-data">
                    { errors &&
                        errors.map( (e) => (
                            <div className="error mb-2" key={e.err_msg}>
                                <span>{e.err_msg}</span>
                            </div>
                        ) )
                    }
                    { message &&
                        <div className="success-message mb-2">
                            <span>{message}</span>
                        </div>
                    }

                    <input type="hidden" name="id" value={id} />

                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" title="Status" checked={ status === 'Active' ? true : false } value={status} onChange={ (e) => changeStatus(e) } style={{width: '2rem', cursor: 'pointer'}} />
                        <span>{status}</span>
                        <input type="hidden" name="serv_status" value={status} />
                    </div>{/* status */}

                    <div className="mt-2">
                        <label htmlFor="name">Service name <span className="text-danger"><strong>*</strong></span></label>
                        <input id="name" name="name" type="text" className="form-control input-text" maxLength="500" value={ name } onChange={ (e) => setName(e.target.value) } required />
                    </div>{/* name */}

                    <div className="mt-2">
                        <div className="d-flex justify-content-between">
                            <label htmlFor="description">Description <span className="text-danger"><strong>*</strong></span></label>
                            <p>{descrCount}/2000</p>
                        </div>
                        <textarea id="description" name="description" className="form-control" rows="2" maxLength="2000" value={ descr } onChange={ (e) => descriptionCount(e.target.value) } required></textarea>
                    </div>{/* description */}

                    <div className="mt-2">
                        <label htmlFor="category1">Service Category <span className="text-danger"><strong>*</strong></span></label>
                        <select id="category1" name="category1" className="form-select" aria-label="Choose service category" value={ cat1 } onChange={ (e) => setCat1(e.target.value) } required>
                            { categoryList && categoryList.map( (category, i) => (
                                <option key={ i }>{category.descr}</option>
                            ) ) }
                        </select>
                    </div>{/* category */}

                    <div>
                        <div className="d-flex flex-column flex-md-row">
                            <div className="mt-2 mr-md-2 flex-fill">
                                <label htmlFor="currency">Currency <span className="text-danger"><strong>*</strong></span></label>
                                <select id="currency" name="currency" className="form-select" value={ currency } onChange={ (e) => setCurrency(e.target.value) } required>
                                    <option value="PHP">PHP</option>
                                    <option value="USD">USD</option>
                                </select>
                            </div>
                            <div className="mt-2 mr-md-2 flex-fill">
                                <label htmlFor="payRate">Pay Rate <span className="text-danger"><strong>*</strong></span></label>
                                <input id="payRate" name="payRate" type="number" aria-label="product-price" className="form-control" value={ payRate } onChange={ (e) => setPayRate(e.target.value) } required/>
                            </div>
                            <div className="mt-2 flex-fill">
                                <label htmlFor="unit">Unit <span className="text-danger"><strong>*</strong></span></label>
                                <select id="unit" name="unit" className="form-select" value={ unit } onChange={ (e) => setUnit(e.target.value) } required>
                                    <option value="per hour">per hour</option>
                                    <option value="per day">per day</option>
                                    <option value="per week">per week</option>
                                    <option value="per month">per month</option>
                                    <option value="per year">per year</option>
                                    <option value="per project">per project</option>
                                    <option value="one time">one time</option>
                                </select>
                            </div>
                        </div>
                    </div>{/* rate */}

                    <div className="user-form p-3 border rounded mt-3">
                        { profilePic &&
                            <img src={profilePic} alt="Profile Picture" style={{borderRadius: '5px', width: '75px', height: '75px'}} />
                        }
                        <div className="mt-2">
                            <label htmlFor="profilePicture">Profile picture (personal or logo)</label>
                            <input id="profilePicture" name="profilePicture" value={ inputFileProfilePic } onChange={ (e) => setInputFileProfilePic(e.target.value) } className="form-control" type="file" accept=".jpeg,.jpg,.png" />
                        </div>
                    </div>{/* profile picture */}

                    <div>
                        <div className="d-flex flex-column flex-md-row">
                            <div className="mt-2 mr-md-2 flex-fill">
                                <label htmlFor="city">Preferred City</label>
                                <input id="city" name="city" type="text" placeholder="Town or City" className="form-control" value={ city } onChange={ (e) => setCity(e.target.value) } />
                            </div>
                            <div className="mt-2 mr-md-2 flex-fill">
                                <label htmlFor="province">Preferred Province</label>
                                <input id="province" name="province" type="text" placeholder="State or Province" className="form-control" value={ province } onChange={ (e) => setProvince(e.target.value) } />
                            </div>
                            <div className="mt-2 flex-fill">
                                <label htmlFor="country">Preferred Country</label>
                                <input id="country" name="country" type="text" placeholder="Country" className="form-control" value={ country } onChange={ (e) => setCountry(e.target.value) } />
                            </div>
                        </div>
                    </div>{/* preferred location */}

                    <div className="mt-2">
                        <label htmlFor="timeline">Timeline or deadline</label>
                        <input id="timeline" name="timeline" type="text" className="form-control input-text" maxLength="500" value={ timeline } onChange={ (e) => setTimeline(e.target.value) } />
                    </div>{/* timeline */}

                    <div className="mt-2">
                        <div className="d-flex justify-content-between">
                            <label htmlFor="contract_terms">Contract terms</label>
                            <p>{contTermsCount}/2000</p>
                        </div>
                        <textarea id="contract_terms" name="contract_terms" className="form-control" rows="2" maxLength="2000" value={ contract } onChange={ (e) => contractTermsCount(e.target.value) }></textarea>
                    </div>{/* contract terms */}

                    <div className="user-form p-3 border rounded mt-3">
                        <label htmlFor="deductibleFees">Deductible fees</label>
                        <select id="deductibleFees" name="deductibleFees" className="form-select" value={deductibleFees} onChange={ (e) => setDeductibleFees(e.target.value) }>
                            <option value=""></option>
                            <option value="Advance fee">Advance fee/Down payment</option>
                            <option value="Reservation fee">Reservation fee</option>
                        </select>
                        { deductibleFees && 
                            <div className="mt-2">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="feeAmount">Fee</label>
                                    { deductibleFees === 'Reservation fee' && 
                                        <div class="form-check">
                                            <input id="allow_refund" name="allow_refund" type="checkbox" class="form-check-input" defaultChecked={allowRefund} style={{width: '1em'}} />
                                            <label class="form-check-label" for="allow_refund">
                                                Allow refund
                                            </label>
                                        </div>
                                    }
                                </div>
                                <input id="fee_amount" name="fee_amount" type="number" placeholder="0" className="form-control" value={feeAmount} onChange={ (e) => setFeeAmount(e.target.value) } />
                            </div>
                        }
                    </div>{/* deductible fees */}


                    <div className="user-form p-3 border rounded mt-3">
                        <div className="d-flex">
                            { portfolioImages.length > 0 && portfolioImages.map( (p, i) => (
                                <div key={i} style={{position: "relative"}} className="mr-3">
                                    <a onClick={ () => handleRemovePortfolioImage(i) } style={{position: "absolute", top: "-5px", right: "-13px"}}>
                                        <i className="bi bi-x-circle-fill" style={{ cursor: "pointer", color: "rgb(255, 215, 0)"}}></i>
                                    </a>
                                    <img src={p} alt="Profile Picture" style={{borderRadius: '5px', width: '60px', height: '60px'}} />
                                </div>
                            ))}
                        </div>
                        <div className="mt-2">
                            <label>Portfilio images (photos of your best work)</label>
                            <input id="portfolioImages" name="portfolioImages" className="form-control" type="file" 
                                value={ inputFilePortImages } onChange={ (e) => setInputFilePortImages(e.target.value) } 
                                accept=".jpeg,.jpg,.png" multiple disabled={ portfolioImages.length >= 3 ? true : false }
                            />
                            <span className="font-italic font-size-point8rem">You are allowed up to three (3) images</span>

                            { portfolioImageIDs && portfolioImageIDs.map( (p, i) => (
                                <input key={i} type="hidden" name="unremovedPortImgIDs[]" value={ p } />
                            ) ) }
                            { removedPortImgIDs && removedPortImgIDs.map( (p, i) => (
                                <input key={i} type="hidden" name="removedPortImgIDs[]" value={ p } />
                            ) ) }
                        </div>
                    </div>{/* portfolio images */}


                    <div className="user-form p-3 border rounded mt-3">
                        <label>Skills</label>
                        { skills.length < 5 &&
                            <SkillAddEditDelete action="add" addSkill={addSkill} />
                        }
                        
                        { skills && skills.map( (s, i) => (
                            <div class="alert alert-warning alert-dismissible fade show" role="alert" key={i}>
                                <span className="mr-2">{s.skill}</span>
                                <span class="badge bg-danger">{s.level}</span>
                                <button type="button" class="btn-close" aria-label="Close" onClick={ () => handleRemoveSkill(i) }></button>

                                <input type="hidden" name="skills[][skill]" value={ s.skill } />
                                <input type="hidden" name="skills[][level]" value={ s.level } />
                            </div>
                        ) ) }
                    </div>{/* skills */}

                    <div className="user-form p-3 border rounded mt-3">
                        <label>Experiences</label>
                        { experiences.length < 5 &&
                            <ExperienceAddEditDelete action="add" addExperience={addExperience} />
                        }

                        { experiences && experiences.map( (e, i) => (
                            <div class="alert alert-warning alert-dismissible fade show" role="alert" key={i}>
                                <span className="mr-2">{e.experience}</span>
                                <span class="badge bg-danger">{e.years}</span>
                                <button type="button" class="btn-close" aria-label="Close" onClick={ () => handleRemoveExperience(i) }></button>
                                
                                <input type="hidden" name="experiences[][experience]" value={ e.experience } />
                                <input type="hidden" name="experiences[][years]" value={ e.years } />
                            </div>
                        ) ) }
                    </div>{/* experiences */}

                    <div className="user-form p-3 border rounded mt-3">
                            <label>References</label>
                            { references.length < 5 &&
                                <ReferenceAddEditDelete action="add" addReference={addReference} />
                            }
                            
                            { references && references.map( (r, i) => (
                                <div class="alert alert-warning alert-dismissible fade show" role="alert" key={i}>
                                    <span className="mr-2">{r}</span>
                                    <button type="button" class="btn-close" aria-label="Close" onClick={ () => handleRemoveReference(i) }></button>

                                    <input type="hidden" name="references[]" value={ r } />
                                </div>
                            ) ) }
                        </div>{/* references */}

                    { !isSaving && 
                        <button id="btn-submit" type="submit" className="btn btn-primary mt-3">
                            <i className="fas fa-tools mx-2"></i> Save Update
                        </button>
                    }
                    { isSaving &&
                        <button type="submit" className="btn btn-primary mt-3" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving
                        </button>
                    }
                </form>
            }
        </div>
    );
}

export default ManageServicesEdit;