import { useState, useEffect } from "react";

import "../../css/mobile/seller-center.css";

import EditProduct from "../mobile/SellerCenterEditProduct"
import DeleteProduct from "../mobile/SellerCenterDeleteProduct"

const SellerCenterProducts = () => {
    const [sellerProducts, setSellerProducts] = useState([]);
    const [rerenderToggle, setRerenderToggle] = useState(true); //used to trigger rerender after an insert, update, or delete products

    useEffect(() => {
        fetch('/api/seller-center/products')
            .then( res => { return res.json(); } )
            .then( data => { setSellerProducts(data); } );
    }, [rerenderToggle] );

    const triggerRerender = () => {
        setRerenderToggle( !rerenderToggle ); //set to opposite boolean value, just so state is changed and would trigger rerender
    }

    return(
        <div className="d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Manage Products</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="seller-form p-3 border rounded">
                    {sellerProducts.length === 0 &&
                        <p className="text-center">No products found.</p>
                    }
                    {sellerProducts.length > 0 &&
                        <>
                            { sellerProducts.map( ( products, index ) => (
                                <div key={index}>
                                    <span className="text-light title-limit"><strong>{products.product_name}</strong> - {products.product_currency}{products.product_price}</span>
                                    <div className="p-2 mb-1 border border-secondary rounded">
                                        <img src={products.product_thumbnail} className="mr-2 rounded float-start product-thumb" alt={products.product_name + " image"} title={products.product_name} />
                                        <small>Status:
                                            { products.product_status === 'active' && 
                                                <span className="ml-1 mr-2 badge rounded-pill bg-primary status-pill lh-4">{products.product_status}</span>
                                            }
                                            { products.product_status === 'on-hold' && 
                                                <span className="ml-1 mr-2 badge rounded-pill bg-warning status-pill text-dark">{products.product_status}</span>
                                            }
                                        </small>
                                        <small>Added: <span className="mr-2 text-light">{products.product_insert_date}</span></small>
                                        <small>Qty: <span className="text-light">{products.product_quantity}</span></small>
                                        <br /><br />
                                        <DeleteProduct products={products} triggerRerender={triggerRerender} />
                                        <EditProduct products={products} index={index} triggerRerender={triggerRerender} />                                        
                                    </div>
                                </div>
                            ) ) }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default SellerCenterProducts;