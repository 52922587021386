import { useEffect } from "react";
import { Redirect } from 'react-router-dom';

const ForwardAuthenticated = ({ isAuthenticated, recheckAuthentication, path }) => {
    useEffect( () => recheckAuthentication(), [] ); //trigger to recheck authentication

    if( isAuthenticated === true ) return <Redirect to={ path + 'user/menu' } />;

    return null;
}
 
export default ForwardAuthenticated;