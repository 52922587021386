import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import RatingStars from "../reusable/RatingStars";
import RatingsAndReviews from "../reusable/RatingsAndReviews";

const ServiceDetails = ({ isAuthenticated, path }) => {
    const { id } = useParams(); //get service id
    const [ service, setService ] = useState(null);

    const [ addedToFavorites, setAddedToFavorites ] = useState(null); //triggers service fetch when updated
    
    const [ isFavoritesLoading, setIsFavoritesLoading ] = useState(false);

    const history = useHistory();

    const [ profilePicture, setProfilePicture ] = useState(null);
    const [ portfolioImages, setPortfolioImages ] = useState(null);
    const [ portfolioImageIndex, setPortfolioImageIndex ] = useState(0);

    useEffect( () => {
        fetch(`/api/service/${id}`)
            .then( res => { 
                if(res.ok) return res.json();
                throw new Error('No service found.');
            }).then( data => {
                if(data) { //check if data is not null
                    setService(data);
                    
                } else throw new Error('No service found.'); //data is null, send error
            }).catch( (e) => {
                alert(e.message);
            })
        ;
    }, [ addedToFavorites ] );

    const [ showMessage, setShowMessage ] = useState(null);
    const handleCloseMessage = () => setShowMessage(null);

    const handleAddToFavorites = () => {
        setIsFavoritesLoading(true);

        if( !isAuthenticated ) history.push(`${path}signin`); //not authenticated
        else {
            fetch(`/api/service/add-to-favorites/${service.service_id}`)
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( 'successfulInsert' in result && result.successfulInsert ) { //successful add
                        setAddedToFavorites(true); //change state to trigger service fetch
                        setIsFavoritesLoading(false);
                        setShowMessage("You have added this service to your favorites.");
                    }
                    else { //unsuccessful add to favorites
                        alert("Something went wrong!");
                        setIsFavoritesLoading(false);
                    }
                }).catch( () => setIsFavoritesLoading(false) )
            ;
        }
    }

    const handleRemoveFromFavorites = () => {
        setIsFavoritesLoading(true);

        if( !isAuthenticated ) history.push(`${path}signin`); //not authenticated
        else {
            fetch(`/api/service/remove-from-favorites/${service.service_id}`)
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( 'isRemoved' in result && result.isRemoved ) { //successfully removed
                        setAddedToFavorites(false); //change state to trigger service fetch
                        setIsFavoritesLoading(false);
                        setShowMessage("You have removed this service from your favorites.");
                    }
                    else { //unsuccessful remove from wish list
                        alert("Something went wrong!");
                        setIsFavoritesLoading(false);
                    }
                }).catch( () => setIsFavoritesLoading(false) )
            ;
        }
    }

    const handleShowPicture = (pic_type, index) => {
        if( profilePicture === null && pic_type === 'profile' ) {
            fetch(`/api/services-get-image/${id}/${pic_type}`)
                .then( res => {
                    if(res.ok) return res.json();
                    throw new Error('Image not found.');
                }).then( data => {
                    if(data) { //check if data is not null
                        setProfilePicture(data.profile_pic);
                    } else throw new Error('No service found.'); //data is null, send error
                }).catch( (e) => {
                    alert(e.message);
                })
            ;
        }
        else if( portfolioImages === null && pic_type === 'portfolio' ) {
            fetch(`/api/services-get-image/${id}/${pic_type}`)
                .then( res => {
                    if(res.ok) return res.json();
                    throw new Error('Image not found.');
                }).then( data => {
                    if(data) { //check if data is not null
                        setPortfolioImageIndex(index);
                        setPortfolioImages(data.portfolio_images);
                    } else throw new Error('No service found.'); //data is null, send error
                }).catch( (e) => {
                    alert(e.message);
                })
            ;
        }
        else if( portfolioImages !== null && pic_type === 'portfolio' ) {
            setPortfolioImageIndex(index);
        }
    }

    return (
        <div className="pt-3">
            { service &&
                <div className="service-details">
                    
                    <div className="service-info mx-3 mt-3 mb-0">
                        { service.serv_status !== 'Active'  && 
                            <h6 className="prod-unavailable alert alert-danger mb-3 text-center">This service is currently unavailable.</h6>
                        }

                        <h3>
                            <i className='fas fa-tools font-color-gold'></i> <span className="font-color-gold">{ service.serv_name }</span>
                        </h3>

                        <RatingStars fontSize="1.2rem" currentRating={ service.average_rating } totalRatings={ service.total_ratings } allowChange={false} showRating={false} />

                        { (service.serv_category_1 || service.serv_category_2 || service.serv_category_3) &&
                            <div className="service-categories d-flex flex-column">
                                <div><span className="badge bg-secondary mt-2">{service.serv_category_1}</span></div>
                                <div><span className="badge bg-secondary mt-2">{service.serv_category_2}</span></div>
                                <div><span className="badge bg-secondary mt-2">{service.serv_category_3}</span></div>
                            </div>
                        }

                        { service.profile_pic_thumb &&
                            <>
                                <a onClick={ () => handleShowPicture('profile') } data-bs-toggle="modal" data-bs-target="#modal-profile-pic" style={{cursor: "pointer"}}>
                                    <img src={service.profile_pic_thumb} alt="Profile Picture" className="mt-3" style={{borderRadius: '5px'}} />
                                </a>

                                <div className="modal" id="modal-profile-pic" tabIndex="-1" aria-labelledby="modal-profile-pic-title" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header mobile-modal-header">
                                                <h5 className="modal-title" id="modal-profile-pic-title">{service.serv_name}</h5>
                                                <button type="button" id="profile-pic-close-modal" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body p-4">
                                                { profilePicture &&
                                                    <img src={profilePicture} alt="Profile Picture" style={{borderRadius: '5px', width: '100%'}} />
                                                }
                                                { !profilePicture &&
                                                    <div className="my-4" style={{textAlign: 'center'}}>
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    </div>
                                                }
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        
                        <h5 className="seller my-3">
                            By {service.first_name} {service.last_name}
                        </h5>

                        <h4 className="selling-price my-3">{service.serv_payment_currency} {service.serv_payment_rate} ({service.serv_payment_unit})</h4>

                        { service.serv_descr && 
                            <p>{service.serv_descr}</p>
                        }

                        { ( service.serv_advance_fee || service.serv_reservation_fee || service.serv_pref_city || service.serv_pref_province || service.serv_pref_country || service.prod_color || service.serv_timeline ) &&
                            <div className="other-details">
                                <h5 className="other-details my-3 mb-0">Other details:</h5>

                                <ul>
                                    { service.serv_advance_fee && <li><span title="Advance Fee" className="badge bg-danger mx-2 mt-2">Advance Fee: <span className="font-color-gold">{service.serv_payment_currency} {service.serv_advance_fee}</span></span></li> }
                                    { service.serv_reservation_fee && <li><span title="Reservation Fee" className="badge bg-danger mx-2 mt-2">Reservation Fee: <span className="font-color-gold">{service.serv_payment_currency} {service.serv_reservation_fee} ({ service.serv_res_fee_refundable ? 'Refundable' : 'Non-refundable' })</span></span></li> }
                                    { service.serv_pref_city && <li><span title="Preferred City" className="badge bg-danger mx-2 mt-2">Preferred City: <span className="font-color-gold">{service.serv_pref_city}</span></span></li> }
                                    { service.serv_pref_province && <li><span title="Preferred Province/State" className="badge bg-danger mx-2 mt-2">Preferred Province/State: <span className="font-color-gold">{service.serv_pref_province}</span></span></li> }
                                    { service.serv_pref_country && <li><span title="Preferred Country" className="badge bg-danger mx-2 mt-2">Preferred Country: <span className="font-color-gold">{service.serv_pref_country}</span></span></li> }
                                    { service.serv_timeline && <li><span title="Timeline" className="badge bg-danger mx-2 mt-2">Timeline: <span className="font-color-gold">{service.serv_timeline}</span></span></li> }
                                </ul>
                            </div>
                        }

                        { service.portfolio_images_thumb &&
                            <div className="other-details">
                                <h5 className="other-details my-3 mb-0">Portfolio Images:</h5>
                                { service.portfolio_images_thumb.map( ( pi, i ) => (
                                    <a key={i} onClick={ () => handleShowPicture('portfolio', i) } data-bs-toggle="modal" data-bs-target="#modal-portfolio-img" style={{cursor: "pointer"}}>
                                        <img src={pi} alt="Portfolio Image" className="mt-2 mr-2" style={{borderRadius: '5px'}} />
                                    </a>
                                ))}

                                <div className="modal" id="modal-portfolio-img" tabIndex="-1" aria-labelledby="modal-portfolio-img-title" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header mobile-modal-header">
                                                <h5 className="modal-title" id="modal-portfolio-img-title">{service.serv_name} (Portfolio)</h5>
                                                <button type="button" id="portfolio-img-close-modal" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body p-4">
                                                { portfolioImages &&    
                                                    <img src={portfolioImages[portfolioImageIndex]} alt="Portfolio Image" style={{borderRadius: '5px', width: '100%'}} />
                                                }
                                                { !portfolioImages &&
                                                    <div className="my-4" style={{textAlign: 'center'}}>
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    </div>
                                                }
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        { service.skills &&
                            <div className="other-details">
                                <h5 className="other-details my-3 mb-0">Skills:</h5>
                                <ul>
                                    { service.skills.map( ( s, i ) => (
                                        <li key={i}>
                                            <span className="badge bg-danger mx-2 mt-2 font-color-gold">{s}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        }

                        { service.experiences &&
                            <div className="other-details">
                                <h5 className="other-details my-3 mb-0">Experiences:</h5>
                                <ul>
                                    { service.experiences.map( ( e, i ) => (
                                        <li key={i}>
                                            <span className="badge bg-danger mx-2 mt-2 font-color-gold">{e}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        }

                        { service.serv_references &&
                            <div className="other-details">
                                <h5 className="other-details my-3 mb-0">References:</h5>
                                <ul>
                                    { service.serv_references.map( ( r, i ) => (
                                        <li key={i}>
                                            <span className="badge bg-danger mx-2 mt-2 font-color-gold">{r}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        }

                        { service.serv_contract_terms &&
                            <div className="mt-3">
                                <h5>Contract Terms:</h5>
                                <small>{service.serv_contract_terms}</small>
                            </div>
                        }

                        { service.serv_status === 'Active'  && 
                            <div className="product-details-buttons">
                                
                                { !isFavoritesLoading && ( !('isInFavorites' in service) || ( 'isInFavorites' in service && !service.isInFavorites ) ) &&
                                    <button onClick={handleAddToFavorites} className="btn btn-services-gold w-100 mt-3">
                                        <i className="bi bi-star"></i> Add to Favorites
                                    </button>
                                }
                                { !isFavoritesLoading && 'isInFavorites' in service && service.isInFavorites &&
                                    <button onClick={handleRemoveFromFavorites} className="btn btn-danger w-100 mt-3">
                                        <i className="bi bi-star"></i> Remove from Favorites
                                    </button>
                                }
                                { isFavoritesLoading &&
                                    <button className="btn btn-services-gold w-100 mt-3" type="button" disabled>
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                                    </button>
                                }

                                { ( !('isMyOwnService' in service) || ( 'isMyOwnService' in service && !service.isMyOwnService ) ) &&
                                    <Link className="btn btn-services-gold w-100 mt-2" to={path + 'user/book-service/' + service.service_id}>
                                        <i className="fas fa-tools mx-2"></i>Book this Service
                                    </Link>
                                }
                            </div>
                        }
                    </div>
                    
                    { service.total_ratings && 
                        <div className="mx-3">
                            <hr />
                            <RatingsAndReviews
                                category="service"
                                averageRating={ service.average_rating }
                                totalRatings={ service.total_ratings }
                                fiveStar={service.five_star_count}
                                fourStar={service.four_star_count}
                                threeStar={service.three_star_count}
                                twoStar={service.two_star_count}
                                oneStar={service.one_star_count}
                            />
                        </div>
                    }
                </div>
            }

            <Modal show={showMessage} onHide={handleCloseMessage} centered className="mobile-modal">
                <Modal.Body>
                    <h5 className="mb-4 mt-2">{showMessage}</h5>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-success btn-services-gold" onClick={handleCloseMessage}>
                            <i class="bi bi-check-circle"></i> Okay
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            
        </div>
    );
}
 
export default ServiceDetails;