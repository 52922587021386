import { useState,useEffect } from "react";

import "../../css/mobile/styles.css";

import UserRefundRequest from "./UserReturnRequest";

const OrderItems = ({orders, index, triggerRerender, shippedDate}) => {
    const [orderItems, setOrderItems] = useState([]);
    const [orderID] = useState(orders.order_id);

    const [showReturnButton, SetShowReturnButton] = useState(false);
    const [showDisabledButton, SetShowDisabledButton] = useState(true);

    // a parse function for mm/dd/yyyy date format
    function parseDate(str) {
        var mdy = str.split('/');
        return new Date(mdy[2], mdy[0]-1, mdy[1]);
    }

    function datediff(first, second) {
        // Take the difference between the dates and divide by milliseconds per day.
        // Round to nearest whole number to deal with DST.
        return Math.round((second-first)/(1000*60*60*24));
    }

    const itemReceivedDate = shippedDate;
   
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth()+1; 
    const yyyy = today.getFullYear();
    if (dd<10) {
        dd=`0${dd}`;
    } 
    if(mm<10) {
        mm=`0${mm}`;
    }
    today = `${mm}/${dd}/${yyyy}`;

    const dateDifference = datediff(parseDate(itemReceivedDate), parseDate(today));


    useEffect(() => {
        fetch(`/api/order-items/${orderID}`)
            .then( res => { return res.json(); } )
            .then( data => { setOrderItems(data); } );

            if (dateDifference > 40 ) {
                SetShowDisabledButton(true);
                SetShowReturnButton(false);
            } else {
                SetShowReturnButton(true);
                SetShowDisabledButton(false);
            };
    }, [triggerRerender] );

    return(
        <>
            {orderItems.length === 0 &&
                <>
                    <p className="text-center">No order items found.</p>
                </>
            }
            {orderItems.length > 0 &&
                <>
                    { orderItems.map( ( items, index ) => (
                        <>
                            <div key={index}>
                                <div className="p-1 pb-2">
                                    <img src={items.order_thumbnail} className="rounded float-start product-thumb mt-1 mr-2" alt={items.order_name + " image"} title={items.order_name} />
                                    {   
                                        showReturnButton?
                                            <>
                                                { items.order_item_status === 'pending' &&
                                                    <UserRefundRequest items={items} index={items.order_item_id} triggerRerender={triggerRerender} />
                                                }
                                            </>
                                        :null
                                    }
                                    <span className="title-limit">{items.order_name}</span> 
                                    <span className="fw-light">{items.order_currency} {items.order_amount}</span><br/>    
                                </div>
                            </div>
                        </>
                    ) ) }
                </>
            }         
        </>
    )
}

export default OrderItems;