import { useState, useEffect } from "react";

const SellerCenterRefundHistory = () => {

    return(
        <div className="d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Returns History</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="user-order p-3 border rounded">
                </div>
            </div>
        </div>
    )
}

export default SellerCenterRefundHistory;