const UserAddressDelete = ({ address, triggerRerender }) => {
    
    const handleClick = () => {
        if( window.confirm("Are you sure you want to delete this address?") ) {
            fetch(`/api/user-delete-address/${address.address_id}`)
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( 'successfulDelete' in result && result.successfulDelete ) { //successful delete
                        triggerRerender(); //trigger rerender of parent component
                    }
                    else { //unsuccessful delete
                        alert("Something went wrong!");
                    }
                })
            ;
        }
    }

    return (
        <button className="btn btn-primary delete-button" onClick={handleClick}>
            <i className="bi bi-trash"></i>
        </button>
    );
}
 
export default UserAddressDelete;