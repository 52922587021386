import { useState } from "react";

const ExperienceAddEditDelete = ({ action, addExperience }) => {
    const [experience, setExperience] = useState("");
    const [years, setYears] = useState("");

    const handleAdd = (e) => {
        e.preventDefault(); //will prevent default page load
        
        if( experience !== "" && years !== "" ) {
            addExperience(experience, years);

            setExperience("");
            setYears("");

            document.getElementById("add-experience-close-modal").click(); //close the modal
        }
    }

    return (
        <>
            { action === 'add' &&
                <>
                    <button type="button" className="btn add" data-bs-toggle="modal" data-bs-target="#modal-add-new-experience" title="Add new experience">
                        <i className="bi bi-plus-circle-fill" style={{color: '#FFD700'}}></i>
                    </button>

                    <div className="modal" id="modal-add-new-experience" tabIndex="-1" aria-labelledby="modal-add-new-experience-title" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header mobile-modal-header">
                                    <h5 className="modal-title" id="modal-add-new-experience-title">Add a experience</h5>
                                    <button type="button" id="add-experience-close-modal" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body p-4">
                                    <input type="text" className="form-control mb-2" placeholder="Input experience" value={experience} onChange={ (e) => setExperience(e.target.value) } />
                                    <select id="yearsOfExperience" name="yearsOfExperience" className="form-select" aria-label="Choose years of experience" value={years} onChange={ (e) => setYears(e.target.value) }>
                                        <option value="">Years of experience</option>
                                        <option value="1 year">1 year</option>
                                        <option value="2 years">2 years</option>
                                        <option value="3 years">3 years</option>
                                        <option value="4 years">4 years</option>
                                        <option value="5 years">5 years</option>
                                        <option value="6 years">6 years</option>
                                        <option value="7 years">7 years</option>
                                        <option value="8 years">8 years</option>
                                        <option value="9 years">9 years</option>
                                        <option value="10 years">10 years</option>
                                        <option value="10+ years">10+ years</option>
                                    </select>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-primary" style={{ maxWidth: '200px' }} onClick={handleAdd}>
                                        <i className="bi bi-plus-circle-fill"></i> Add Experience
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}
 
export default ExperienceAddEditDelete;