import { useEffect } from "react";
import { Link } from "react-router-dom";

const SellerMenu = ({ path, firstName }) => {
    useEffect( () => {
        //set body background color
        document.body.style.backgroundColor = "#171a26";
        return () => { //unmount
            document.body.style.backgroundColor = "#f5f6f9";
        }
    }, [] );

    return (
        <div className="user-menu d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">
                <span className="">Seller Center</span>
            </h3>

            <div className="mt-3">
                <Link to={ path + 'seller-center/add-product' } className="menu-link">
                    Add Product <i class="bi bi-chevron-right"></i>
                </Link>
                <Link to={ path + 'seller-center/products' } className="menu-link">
                    Manage Products <i class="bi bi-chevron-right"></i>
                </Link>
                <Link to={ path + 'seller-center/orders' } className="menu-link">
                    Pending Orders <i class="bi bi-chevron-right"></i>
                </Link>
                <Link to={ path + 'seller-center/shipped-orders' } className="menu-link">
                    Shipped Orders <i class="bi bi-chevron-right"></i>
                </Link>
                <Link to={ path + 'seller-center/return-refund-requests' } className="menu-link">
                    Manage Returns <i class="bi bi-chevron-right"></i>
                </Link>
                {/* <Link to={ path + 'seller-center/returns-history' } className="menu-link">
                    Returns History<i class="bi bi-chevron-right"></i>
                </Link> */}
                {/* <Link to={ path + 'seller-center/product-reviews' } className="menu-link">
                    Product Reviews <i class="bi bi-chevron-right"></i>
                </Link>  */}
            </div>
        </div>
    );
}
 
export default SellerMenu;