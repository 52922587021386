import { useState, useEffect } from "react";

import "../../css/mobile/seller-center.css";

const SellerCenterRefundImages = ({returnId}) => {
    const [returnID] = useState(returnId)
    const [returnImages, setReturnImages] = useState([]);

    useEffect(() => {
        fetch(`/api/seller-center/return-images/${returnID}`)
            .then( res => { return res.json(); } )
            .then( data => { setReturnImages(data); } );
    }, [] );

    return(
        <>
            {returnImages.length === 0 &&
                <></> // display nothing
            }
            {returnImages.length > 0 &&
                <>
                    {returnImages.map( ( images, index) => (
                        <>
                            <img src={images.return_thumbnail} className="ml-1 mr-1 mt-3" style={{width: "40px", height: "40px"}} alt="return photos" data-bs-toggle="modal" data-bs-target={"#return-images-modal-" + images.return_img_id} />
                            <div className="modal fade" id={"return-images-modal-" + images.return_img_id} tabIndex="-1" aria-labelledby={"return-images-label-" + images.return_img_id} aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-sm ">
                                    <div className="modal-content">
                                        {/* <div className="modal-header">
                                            <button type="button" id={"btn-close-modal" + images.return_img_id} className="btn-close btn-close-white btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div> */}
                                        <div className="modal-body text-center">
                                            <img src={images.return_image} style={{maxWidth: "263px", maxHeight: "300px"}} alt="return photos" />
                                        </div>
                                        <div className="modal-footer" style={{height: "70px"}}>
                                            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><small>Close</small></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) ) }
                    <br /><small><i>Click thumbnail to enlarge</i></small>
                </>
            }
        </>
    )
}

export default SellerCenterRefundImages;