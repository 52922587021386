import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import isStrongPassword from 'validator/lib/isStrongPassword';

const UserChangePassword = ({ path }) => {
    const [ currentPass, setCurrentPass ] = useState('');
    const [ newPass, setNewPass ] = useState('');
    const [ newPass2, setNewPass2 ] = useState('');
    const [ passStrength, setPassStrength ] = useState(null);
    const [ isMatch, setIsMatch ] = useState(null);

    const [ errors, setErrors ] = useState([]);
    const [ isSaving, setIsSaving ] = useState('');

    const history = useHistory();

    useEffect( () => {
        //set body background color
        document.body.style.backgroundColor = "#171a26";
        return () => { //unmount
            document.body.style.backgroundColor = "#f5f6f9";
        }
    }, [] );

    const handleSubmit = (e) => {
        e.preventDefault(); //will prevent default page load
        
        setErrors([]); //empty errors array

        if( currentPass !== "" && newPass !== "" && newPass2 !== "" ) { //no empty fields
            if( passStrength && isMatch ) { //passwords match and strong
                setIsSaving(true);
    
                const passwords = { currentPass, newPass, newPass2 };
                
                fetch('/api/user-change-password', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(passwords)
                })
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( result.isSignedOut ) history.push(`${path}signin`); //successful change and signed out, redirect to sign in
                    else if( Array.isArray(result) ) setErrors(result);
                    else setErrors([{ err_msg: 'Unable to change your password.' }]);

                    setIsSaving(false);
                });
            }
        } else {
            setErrors([{ err_msg: 'All fields are required.' }]);
        }
    }

    const handleNewPasswordChange = (e) => {
        const pass = e.target.value;
        setNewPass(pass);
        if( pass.length > 0 ) setPassStrength( isStrongPassword(pass) ); //set password strength
        else setPassStrength(null); //set to null if password is empty

        if( newPass2 === pass ) setIsMatch(true); //confirmation password matches new password
        else setIsMatch(false); //does not match
    }

    const handleConfirmNewPasswordChange = (e) => {
        const pass = e.target.value;
        setNewPass2(pass);
        if( pass.length === 0 ) setIsMatch(null); //set back to null if empty
        else if( newPass === pass ) setIsMatch(true); //confirmation password matches new password
        else setIsMatch(false); //does not match
    }

    return (
        <div className="change-password d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Change Password</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="user-form p-4 border rounded">
                    <h5 className="mb-3">
                        After a successful change of password, you will be signed out and redirected to the sign in page. 
                        You can then use your new password.
                    </h5>
                    <h5 className="mb-3">
                        Your new password must be at least 8 characters in length with at least one lowercase, uppercase, number, and special character.
                    </h5>
                    <form id="user-change-password-form" onSubmit={handleSubmit}>
                        { errors &&
                            errors.map( (e) => (
                                <div className="error mb-2" key={e.err_msg}>
                                    <span>{e.err_msg}</span>
                                </div>
                            ) )
                        }
                        <div className="d-flex flex-column my-3">
                            <label htmlFor="current-password">Current Password</label>
                            <input type="password" id="current-password" className="input-text" value={ currentPass } onChange={(e) => setCurrentPass(e.target.value)} />
                        </div>
                        <div className="d-flex flex-column my-3">
                            <label htmlFor="new-password">
                                <span className="mr-2">New Password</span>
                                { passStrength !== null && passStrength && <span className="pass-strength strong">(Your password is strong)</span> }
                                { passStrength !== null && !passStrength && <span className="pass-strength weak">(Your password is weak)</span> }
                            </label>
                            <input type="password" id="new-password" className="input-text" value={ newPass } onChange={handleNewPasswordChange} />
                        </div>
                        <div className="d-flex flex-column my-3">
                            <label htmlFor="confirm-new-password">
                                <span className="mr-2">Confirm New Password</span>
                                { !isMatch && newPass2.length > 0 && <span className="pass-not-match">(Passwords do not match)</span> }
                            </label>
                            <input type="password" id="confirm-new-password" className="input-text" value={ newPass2 } onChange={handleConfirmNewPasswordChange} />
                        </div>
                        <div className="mt-4">
                            { !isSaving && 
                                <button className="btn btn-primary">
                                    <i className="bi bi-save"></i> Save Password
                                </button> 
                            }
                            { isSaving && 
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving ...
                                </button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default UserChangePassword;