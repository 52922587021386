import { useState, useEffect } from "react";

import RatingStars from "../reusable/RatingStars";
import TextAreaCounter from '../reusable/TextAreaCounter';

const RatingAndReview = ({ category, id, currentRating, currentReview, title }) => {
    const [ review, setReview ] = useState('');
    const [ curReview, setCurReview ] = useState(null);
    const [ rating, setRating ] = useState(0);
    const [ curRating, setCurRating ] = useState(null);

    const [ isSaving, setIsSaving ] = useState(false);

    const saveRatingAndReview = () => {
        if( rating !== 0 ) { //rating has been set
            let apiPath = "";

            if( category === "service" ) apiPath = "/api/rating-and-review-save/service";
            else if( category === "product" ) apiPath = "/api/rating-and-review-save/product";

            setIsSaving(true);

            const data = { id, rating, review };

            fetch( apiPath, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then( result => result.json() )
            .then( r => {
                if( 'successfulInsert' in r && r.successfulInsert && 'result' in r && r.result ) {
                    if( 'rating' in r.result ) setCurRating(r.result.rating);
                    if( 'review' in r.result ) setCurReview(r.result.review);
                }
                setIsSaving(false);
            });
        } else { //no rating
            alert("Please select the number of stars for your rating.");
        }
    }

    useEffect( () => {
        setCurRating(currentRating);
        setCurReview(currentReview);
    }, [] );

    return (
        <>
            { title && curRating === null &&
                <div className="mb-1 font-color-gold"><b>{title}</b></div>
            }
            <RatingStars fontSize="1.5rem" currentRating={ curRating !== null ? curRating : currentRating } setRating={setRating} allowChange={ curRating === null } />

            { curRating && curReview &&
                <div className="text-break">{curReview}</div>
            }

            { curRating && !curReview &&
                <div className="font-italic">No review</div>
            }

            { !curRating &&
                <>
                   <div className="mt-2">
                        <textarea rows="2" maxLength="1000" value={ review } onChange={ (e) => setReview(e.target.value) } placeholder="Leave a review..." className="form-control w-100"></textarea>
                        <h6 className="mt-1">
                            <TextAreaCounter text={review} maxLength="1000" />
                        </h6>
                    </div>

                    { !isSaving &&
                        <button className="btn btn-services-gold mt-2" onClick={saveRatingAndReview}>
                            <i className="bi bi-save"></i> Save Rating & Review
                        </button>
                    }
                    { isSaving &&
                        <button className="btn btn-services-gold mt-2" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...
                        </button>
                    }
                </>
            }

            
        </>
    );
}
 
export default RatingAndReview;