import { useState, useEffect } from "react";

const UserReturnsTrackingDetails = ({returnID, index}) => {
    const [returnTrackingDetails, setReturnTrackingDetails] = useState([]);

    useEffect(() => {
        fetch(`/api/seller-center/return-details/${returnID}`)
            .then( res => { return res.json(); } )
            .then( data => { setReturnTrackingDetails(data); } );
    },[] );

    return(
        <>
            <button type="button" className="btn btn-primary btn-sm mt-2" style={{width: "140px"}} data-bs-toggle="modal" data-bs-target={"#return-tracking-details-modal-" + index}>
                <small>Return Details</small>
            </button>
            <div className="modal fade" id={"return-tracking-details-modal-" + index} tabIndex="-1" aria-labelledby={"return-details-label-" + index} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id={"return-details-label-" + index}>Return Details</h5>
                            <button type="button" id={"btn-close-return-modal" + index} className="btn-close btn-close-white btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {returnTrackingDetails.length === 0 &&
                                <p className="text-center">Return details not found.</p>
                            }
                            {returnTrackingDetails.length > 0 &&
                                <>
                                    { returnTrackingDetails.map( ( returns, index ) => (
                                        <small>
                                            <div className="d-flex flex-column">
                                                <strong>Delivery Status: 
                                                    {returns.return_status === "declined" &&
                                                        <small><span className="badge rounded-pill bg-primary ml-1 mb-2">Shipped</span></small>
                                                    }
                                                </strong>
                                            </div>
                                            <div className="d-flex flex-column mt-1">
                                                <strong>Courier: </strong>
                                                {returns.return_courier}
                                            </div>
                                            <div className="d-flex flex-column mt-1">
                                                <strong>Tracking Code: </strong>
                                                <span>{returns.return_tracking} <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => {navigator.clipboard.writeText(returns.return_tracking)}}><small>Copy to clipboard</small></button></span>
                                            </div>
                                            <div className="d-flex flex-column mt-1">
                                                <strong>Courier Tracking Link: </strong>
                                                { returns.return_courier === '2GO Express' &&
                                                <a className="text-primary" rel="noopener noreferrer" href={"https://supplychain.2go.com.ph/CustomerSupport/ttrace/track_status.asp?bl=" + returns.return_tracking} target="_blank">https://supplychain.2go.com.ph</a>
                                                }
                                                { returns.return_courier === 'Air 21' &&
                                                    <a className="text-primary" rel="noopener noreferrer" href="https://www.air21.com.ph/main/shipment-tracking-dfs.php" target="_blank">https://www.air21.com.ph</a>
                                                }
                                                { returns.return_courier === 'Airspeed' &&
                                                    <a className="text-primary" rel="noopener noreferrer" href="https://airspeed.ph/track-cargo/" target="_blank">https://airspeed.ph</a>
                                                }
                                                { returns.return_courier === 'AP Cargo' &&
                                                    <a className="text-primary" rel="noopener noreferrer" href="https://apcargo.com.ph/#cargotracker" target="_blank">https://apcargo.com.ph</a>
                                                }
                                                { returns.return_courier === 'DHL' &&
                                                    <a className="text-primary" rel="noopener noreferrer" href={"https://www.dhl.com/ph-en/home/tracking.html?tracking-id=" + returns.return_tracking} target="_blank">https://www.dhl.com</a>
                                                }
                                                { returns.return_courier === 'Grab Express' &&
                                                    <a className="text-primary" rel="noopener noreferrer" href="https://www.grab.com/ph/express/" target="_blank">https://www.grab.com</a>
                                                }
                                                { returns.return_courier === 'J&T Express' &&
                                                    <a className="text-primary" rel="noopener noreferrer" href="https://www.jtexpress.ph/index/query/gzquery.html" target="_blank">https://www.jtexpress.ph</a>
                                                }
                                                { returns.return_courier === 'JRS Express' &&
                                                    <a className="text-primary" rel="noopener noreferrer" href="https://www.jrs-express.com/tracking/" target="_blank">https://www.jrs-express.com</a>
                                                }
                                                { returns.return_courier === 'Lalamove' &&
                                                    <a className="text-primary" rel="noopener noreferrer" href="https://www.lalamove.com/en-ph/" target="_blank">https://www.lalamove.com</a>
                                                }
                                                { returns.return_courier === 'LBC' &&
                                                    <a className="text-primary" rel="noopener noreferrer" href="https://www.lbcexpress.com/track/" target="_blank">https://www.lbcexpress.com</a>
                                                }
                                                { returns.return_courier === 'Ninja Van' &&
                                                    <a className="text-primary" rel="noopener noreferrer" href={"https://www.ninjavan.co/en-ph/tracking?id=" + returns.return_tracking} target="_blank">https://www.ninjavan.co</a>
                                                }
                                                { returns.return_courier === 'UPS' &&
                                                    <a className="text-primary" rel="noopener noreferrer" href="https://www.ups.com/track?loc=en_PH&requester=ST/" target="_blank">https://www.ups.com</a>
                                                }
                                                { returns.return_courier === 'XDE Logistics' &&
                                                    <a className="text-primary" rel="noopener noreferrer" href="https://www.xde.com.ph/track-trace" target="_blank">https://www.xde.com.ph</a>
                                                }
                                            </div>
                                        </small>
                                    ) ) }
                                </>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>        
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserReturnsTrackingDetails;