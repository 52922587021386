import { useEffect } from "react";
import { Link } from "react-router-dom";

const LegalMenu = ({ path }) => {
    useEffect( () => {
        //set body background color
        document.body.style.backgroundColor = "#171a26";
        return () => { //unmount
            document.body.style.backgroundColor = "#f5f6f9";
        }
    }, [] );

    return(
        <div className="user-menu d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">
                {/* <span>Legal</span> */}
            </h3>
            <div className="mt-3">
                <Link to={ path + 'terms-and-conditions' } className="menu-link">
                    Terms and Conditions <i class="bi bi-chevron-right"></i>
                </Link>
                <Link to={ path + 'privacy-policy' } className="menu-link">
                    Privacy Policy <i class="bi bi-chevron-right"></i>
                </Link>
                <Link to={ path + 'disclaimer' } className="menu-link">
                    Disclaimer <i class="bi bi-chevron-right"></i>
                </Link>
            </div>
        </div>
    )

}

export default LegalMenu;