import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import '../../css/mobile/register-and-signin.css';

const SignIn = ({ signInMessage, signInMessageShown, handleSetSignInMessage, handleSetSignInMessageShown }) => {
    const history = useHistory();

    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ error, setError ] = useState('');
    const [ isPending, setIsPending ] = useState(false);

    useEffect( () => {
        if( signInMessageShown ) handleSetSignInMessage(''); //if already shown set message to empty
        else handleSetSignInMessageShown(true); //set shown message to true

        //set body background color
        document.body.style.backgroundColor = "#171a26";
        return () => { //unmount
            document.body.style.backgroundColor = "#f5f6f9";
        }
    }, [] );

    const handleSubmit = (e) => {
        e.preventDefault(); //will prevent default page load
        
        setIsPending(true);

        handleSetSignInMessage(''); //set message to empty

        const signin = { email, password };

        fetch('/api/signin', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(signin)
        })
        .then( (result) => result.json() )
        .then( (result) =>  {
            if( 'err_msg' in result ) { //check if invalid credentials
                setError( result.err_msg );
                setIsPending(false);
            }
            else { //successful sign in
                history.push('./user/menu');
            }
        });
    }

    return (
        <div className="sign-in d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Sign in to your account</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="sign-in-form p-4 border rounded">
                    { error &&
                        <div className="error mb-2">
                            <span>{error}</span>
                        </div>
                    }
                    { signInMessage &&
                        <div className="success-message mb-2">
                            <span>{signInMessage}</span>
                        </div>
                    }

                    <form onSubmit={ handleSubmit }>
                        <div className="d-flex flex-column">
                            <label htmlFor="email">Email Address</label>
                            <input type="text" id="email" className="input-text" value={ email } onChange={ (e) => setEmail(e.target.value) } required />
                        </div>

                        <div className="d-flex flex-column mt-3">
                            <label htmlFor="password">Password</label>
                            <input type="password" id="password" className="input-text" value={ password } onChange={ (e) => setPassword(e.target.value) } required />
                        </div>

                        <div className="d-flex flex-column mt-4">
                            { !isPending && 
                                <button className="btn btn-primary">
                                    <i className="bi bi-box-arrow-in-right"></i> Sign In
                                </button> 
                            }
                            { isPending && 
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                                </button>
                            }
                        </div>

                        <hr />

                        <div className="d-flex flex-column flex-sm-row">
                            <Link to="./reset-password" className="me-sm-auto">Forgot your password?</Link>
                            <Link to="./register" className="">No account? Register now</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
 
export default SignIn;