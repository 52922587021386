import { useState } from "react";

const UserOrderDetails = ({orders, index}) => {
    const [deliveryName] = useState(orders.delivery_name);
    const [deliveryAddress] = useState(orders.delivery_address);
    const [deliveryPhone] = useState(orders.delivery_phone);
    const [paymentStatus] = useState(orders.order_status);
    const [paymentMethod] = useState(orders.payment_method);

    return(
        <>
            <button type="button" className="btn btn-primary btn-sm mb-1 ml-3" style={{width: "150px"}} data-bs-toggle="modal" data-bs-target={"#edit-product-modal-" + index}>
                <small>Order Details</small>
            </button>
            <div className="modal fade" id={"edit-product-modal-" + index} tabIndex="-1" aria-labelledby={"edit-product-label-" + index} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm ">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title edit-title-limit" id={"edit-product-label-" + index}>Order Details</h5>
                        <button type="button" id={"btn-close-modal-" + index} className="btn-close btn-close-white btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <small>
                            <div className="row">
                                <strong>Payment Status:</strong>
                            </div>
                            <div className="row lh-1">
                                <span className="ml-3 badge rounded-pill bg-success status-pill">{paymentStatus}</span>
                            </div>
                            <div className="row mt-1">
                                <strong>Payment Method:</strong>
                            </div>
                            <div className="row lh-1">
                                {/* <span><strong className="text-primary text-capitalize">{ paymentMethod }</strong></span> */}
                                <span className="ml-3 badge rounded-pill bg-primary status-pill">{paymentMethod}</span>
                            </div>
                            
                            <div className="row mt-1">
                                <strong>Delivery Name:</strong>
                            </div>
                            <div className="row lh-1">
                                <span>{ deliveryName }</span>
                            </div>
                            <div className="row mt-2">
                                <strong>Delivery Address:</strong>
                            </div>
                            <div className="row lh-1">
                                <span>{ deliveryAddress }</span>
                            </div>
                            <div className="row mt-2">
                                <strong>Contact Information:</strong>
                            </div>
                            <div className="row lh-1">
                                { deliveryPhone === null &&
                                    <span>- No contact details provided -</span>
                                }
                                { deliveryPhone === '' &&
                                    <span>- No contact details provided -</span>
                                }
                                { deliveryPhone !== '' &&
                                    <span>{ deliveryPhone }</span>
                                }
                            </div>
                        </small>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><small>Close</small></button>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserOrderDetails;