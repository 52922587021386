import { useState } from "react";
import { Link } from "react-router-dom";
import isEmail from 'validator/lib/isEmail';
import isStrongPassword from 'validator/lib/isStrongPassword';

import useFetch from "../useFetch";

import '../../css/mobile/register-and-signin.css';

const Register = () => {
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ password2, setPassword2 ] = useState('');

    const [ errors, setErrors ] = useState([]); //set to empty array
    const [ message, setMessage ] = useState('');
    const [ isPending, setIsPending ] = useState(false);

    const [ q1, setQ1 ] = useState('');
    const [ q2, setQ2 ] = useState('');
    const [ q3, setQ3 ] = useState('');

    const [ a1, setA1 ] = useState('');
    const [ a2, setA2 ] = useState('');
    const [ a3, setA3 ] = useState('');

    const { data: secQListandUserQA } = useFetch('/api/security-question-list?page=reg'); //get security question list

    const handleSubmit = (e) => {
        e.preventDefault(); //will prevent default page load

        if( firstName !== '' && lastName !== '' && email !== '' && password !== '' && password2 !== '' && q1 && q2 && q3 && a1 && a2 && a3 ) { //check required fields
            if( isEmail(email) ) { //check if email format is valid
                if( password === password2 ) {
                    if( isStrongPassword(password) ) {
                        const register = { first_name: firstName, last_name: lastName, email, password, password2, q1, q2, q3, a1, a2, a3 };
        
                        setIsPending(true);
            
                        fetch('/api/user-registration', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(register)
                        })
                        .then( (result) => result.json() )
                        .then( (result) =>  {
                            if( Array.isArray(result) ) { //setErrors to result only if returned is an array (of error messages)
                                setErrors( result );
                                setMessage('');
                                window.scrollTo(0, 0);
                            }
                            else { //no error, registered successfully
                                setErrors([]); //set to empty array
                                setFirstName('');
                                setLastName('');
                                setEmail('');
                                setPassword('');
                                setPassword2('');
                                setQ1('');
                                setQ2('');
                                setQ3('');
                                setA1('');
                                setA2('');
                                setA3('');
                                setMessage( result.message );
                                window.scrollTo(0, 0); //scroll to top to see message
                            }
                            
                            setIsPending(false);
                        });
                    } else {
                        setErrors([ { err_msg: 'Your password must be at least 8 characters in length with at least one lowercase, uppercase, number, and special character.' } ]);
                        setMessage('');
                        window.scrollTo(0, 0); //scroll to top to see message
                    }
                } else {
                    setErrors([ { err_msg: 'Your passwords do not match.' } ]);
                    setMessage('');
                    window.scrollTo(0, 0); //scroll to top to see message
                }
            } else { //invalid email format
                setErrors([ { err_msg: 'Please enter a valid email.' } ]);
                setMessage('');
                window.scrollTo(0, 0); //scroll to top to see message
            }

        } else { //not all fields have value
            setErrors([ { err_msg: 'All fields are required.' } ]);
            setMessage('');
            window.scrollTo(0, 0); //scroll to top to see message
        }

        
    }

    return (
        <div className="register d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Register for new account</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="register-form p-4 border rounded">
                    <form onSubmit={ handleSubmit }>
                        { errors &&
                            errors.map( (e) => (
                                <div className="error mb-2" key={e.err_msg}>
                                    <span>{e.err_msg}</span>
                                </div>
                            ) )
                        }
                        { message &&
                            <div className="success-message mb-2">
                                <span>{message}</span>
                            </div>
                        }

                        <div className="d-flex flex-column flex-sm-row">
                            <div className="me-sm-3">
                                <label htmlFor="first-name">First Name</label>
                                <input type="text" id="first-name" className="input-text" value={ firstName } onChange={ (e) => setFirstName(e.target.value) } required />
                            </div>
                            
                            <div className="mt-3 mt-sm-0">
                                <label htmlFor="last-name">Last Name</label>
                                <input type="text" id="last-name" className="input-text" value={ lastName } onChange={ (e) => setLastName(e.target.value) } required />
                            </div>
                        </div>

                        <div className="d-flex flex-column mt-3">
                            <label htmlFor="email">Email</label>
                            <input type="text" id="email" className="input-text" value={ email } onChange={ (e) => setEmail(e.target.value) } required />
                        </div>

                        <div className="d-flex flex-column mt-3">
                            <label htmlFor="password">Password</label>
                            <input type="password" id="password" className="input-text" value={ password } onChange={ (e) => setPassword(e.target.value) } required />
                        </div>

                        <div className="d-flex flex-column mt-3">
                            <label htmlFor="password2">Confirm Password</label>
                            <input type="password" id="password2" className="input-text" value={ password2 } onChange={ (e) => setPassword2(e.target.value) } required />
                        </div>


                        <h5 className="mt-3 mb-3">
                            Please select 3 different questions and supply them with answers. 
                            Make sure not to forget your answers since these will be used to reset in case you forget your password.
                        </h5>

                        { secQListandUserQA &&
                            <select className="form-select my-1" aria-label="Security Question 1" onChange={ e => setQ1( e.target.value ) } value={q1}>
                                <option value="">Security Question 1</option>
                                { secQListandUserQA.secQuestList && secQListandUserQA.secQuestList.map( (question) => (
                                    <option key={ question.descr } value={question.descr}>
                                        {question.descr}
                                    </option>
                                ) ) }
                            </select>
                        }
                        <input type="text" value={a1} onChange={ e => setA1( e.target.value ) } placeholder="Answer 1" className="input-text my-1" />

                        { secQListandUserQA &&
                            <select className="form-select my-1 mt-3" aria-label="Security Question 2" onChange={ e => setQ2( e.target.value ) } value={q2}>
                                <option value="">Security Question 2</option>
                                {  secQListandUserQA.secQuestList && secQListandUserQA.secQuestList.map( (question) => (
                                    <option key={ question.descr } value={question.descr}>
                                        {question.descr}
                                    </option>
                                ) ) }
                            </select>
                        }
                        <input type="text" value={a2} onChange={ e => setA2( e.target.value ) } placeholder="Answer 2" className="input-text my-1" />

                        { secQListandUserQA &&
                            <select className="form-select my-1 mt-3" aria-label="Security Question 3" onChange={ e => setQ3( e.target.value ) } value={q3}>
                                <option value="">Security Question 3</option>
                                { secQListandUserQA && secQListandUserQA.secQuestList && secQListandUserQA.secQuestList.map( (question) => (
                                    <option key={ question.descr } value={question.descr}>
                                        {question.descr}
                                    </option>
                                ) ) }
                            </select>
                        }
                        <input type="text" value={a3} onChange={ e => setA3( e.target.value ) } placeholder="Answer 3" className="input-text my-1" />



                        
                        <div className="d-flex flex-column mt-4">
                            { !isPending && 
                                <button className="btn btn-primary">
                                    <i className="bi bi-person-lines-fill"></i> Register
                                </button> 
                            }
                            { isPending && 
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                                </button>
                            }
                        </div>

                        <hr />

                        <Link to="./signin" className="me-md-auto">Already have an account? Sign In</Link>
                    </form>
                </div>
            </div>
        </div>
    );
}
 
export default Register;