import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

// import paypalLogo from '../../img/logos/paypal.png';

const UserCheckout = () => {
    const location = useLocation();
    let checkedProducts, currency, totalAmount;

    if( location.state !== undefined ) {
        checkedProducts = location.state.checkedProducts;
        currency = location.state.currency;
        totalAmount = location.state.totalAmount;
    }

    const [ fullName, setFullName ] = useState('');
    const [ contactNumber, setContactNumber ] = useState('');
    const [ addresses, setAddresses ] = useState([]);
    const [ defaultAddress, setDefaultAddress ] = useState('');
    const [ paymentMethod, setPaymentMethod ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);

    const [ showModalChangeAddress, setShowModalChangeAddress ] = useState(false);
    const handleCloseModalChangeAddress = () => setShowModalChangeAddress(false);
    const handleShowModalChangeAddress = () => setShowModalChangeAddress(true);

    const handleChangeAddress = (index) => setDefaultAddress( addresses[index] );

    useEffect( () => {
        //set body background color
        document.body.style.backgroundColor = "#171a26";
        return () => { //unmount
            document.body.style.backgroundColor = "#f5f6f9";
        }
    }, [] );

    useEffect( () => {
        fetch('/api/user-checkout')
            .then( res => { return res.json(); } )
            .then( data => { 
                if( 'name' in data && data.name ) setFullName(data.name); //set name
                if( 'contact_number' in data && data.contact_number ) setContactNumber(data.contact_number); //set contact number
                if( 'addresses' in data && data.addresses ) { //address is not null
                    let defAddress = null;
                    if( data.addresses.length === 1) defAddress = data.addresses[0]; //only one address
                    else { //multiple addresses
                        for( let i = 0; i < data.addresses.length; i++ ) {
                            if( data.addresses[i].is_default === 1 ) {
                                defAddress = data.addresses[i];
                                break; //end the loop
                            }
                        }
                        if( defAddress === null ) defAddress = data.addresses[0]; //no default address; set default to the first address in the array
                    }
                    setDefaultAddress( defAddress );
                    setAddresses(data.addresses); //set addresses
                }
                if( 'payment' in data && data.payment ) setPaymentMethod(data.payment); //set payment

            } );
    }, [] );

    const handlePlaceOrder = () => {
        setIsLoading(true);

        let address = "";
        address = address + ( defaultAddress.address1 !== "" ? defaultAddress.address1 : "" );
        address = address + ( defaultAddress.address2 !== "" ? ", " + defaultAddress.address2 : "" );
        address = address + ( defaultAddress.city !== "" ? ", " + defaultAddress.city : "" );
        address = address + ( defaultAddress.state_territory !== "" ? ", " + defaultAddress.state_territory : "" );
        address = address + ( defaultAddress.country !== "" ? ", " + defaultAddress.country : "" );
        address = address + ( defaultAddress.postal_code !== "" ? ", " + defaultAddress.postal_code : "" );

        let productIDs = [], prodQTY = [];
        for( let i = 0; i < checkedProducts.length; i++ ) {
            productIDs.push( checkedProducts[i].product_id );
            prodQTY.push( { id: checkedProducts[i].product_id, qty: checkedProducts[i].qty } );
        }
        
        const data = { productIDs, prodQTY, fullName, phoneNumber: contactNumber, address, paymentMethod };

        fetch('/api/user-checkout', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then( (result) => result.json() )
        .then( (result) =>  {
            if( 'approvalURL' in result ) window.location.href = result.approvalURL;
            else alert("Unable to process request.");
        });
    }

    return (
        <div className="user-checkout mobile-container d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Checkout</h3>
            {checkedProducts && 
                <div className="d-flex flex-column mt-3">
                    
                        <div className="alert" role="alert">
                            <div className="d-flex flex-row">
                                <div className="mr-1">
                                    <b><i className="bi bi-geo-alt"></i></b>
                                </div>
                                <div>
                                    <div className="mb-1"><b>Delivery Details</b></div>
                                    <div className="mb-1">{fullName}</div>
                                    <div className="mb-1">{contactNumber}</div>
                                    <div>
                                        { defaultAddress.address1 && <span>{defaultAddress.address1}</span> }
                                        { defaultAddress.address2 && <span>, {defaultAddress.address2}</span> }
                                        { defaultAddress.city && <span>, {defaultAddress.city}</span> }
                                        { defaultAddress.state_territory && <span>, {defaultAddress.state_territory}</span> }
                                        { defaultAddress.country && <span>, {defaultAddress.country}</span> }
                                        { defaultAddress.postal_code && <span>, {defaultAddress.postal_code}</span> }

                                        { addresses && addresses.length > 1 && 
                                            <div style={{display: "inline"}}>
                                                <button onClick={handleShowModalChangeAddress} className="btn btn-primary change ml-2">
                                                    <i class="bi bi-pencil-square"></i>
                                                </button>

                                                <Modal centered show={showModalChangeAddress} onHide={handleCloseModalChangeAddress} className="user-checkout-change-address mobile-modal">
                                                    <Modal.Header>
                                                        <h5>Change delivery address</h5>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                            { addresses.map( (a, index) => (
                                                                <div key={index} className="my-3">
                                                                    <input className="form-check-input" type="radio" name="select-payment-options" id={"select-address-" + index} 
                                                                        value={index} onChange={ () => handleChangeAddress(index) } checked={ defaultAddress.address_id === a.address_id } />
                                                                    <label className="form-check-label ml-2" htmlFor={"select-address-" + index}>
                                                                        { a.address1 && <span>{a.address1}</span> }
                                                                        { a.address2 && <span>, {a.address2}</span> }
                                                                        { a.city && <span>, {a.city}</span> }
                                                                        { a.state_territory && <span>, {a.state_territory}</span> }
                                                                        { a.country && <span>, {a.country}</span> }
                                                                        { a.postal_code && <span>, {a.postal_code}</span> }
                                                                        { a.addr_type && <b> ({a.addr_type})</b> }
                                                                    </label>
                                                                </div>
                                                            ))
                                                        }
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <div className="d-flex justify-content-end">
                                                            <button className="btn btn-success" onClick={handleCloseModalChangeAddress}>
                                                                <i className="bi bi-check-circle"></i> Done
                                                            </button>
                                                        </div>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="d-flex flex-row">
                                <div className="mr-1">
                                    <i className="bi bi-currency-dollar"></i>
                                </div>
                                <div>
                                    Payment Method:
                                    { paymentMethod === "paypal" && 
                                        // <img src={paypalLogo} alt="Paypal" height="18" className="mx-1" alt="Paypal" style={{ backgroundColor: '#171a26' }} />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-paypal ml-1" viewBox="0 0 16 16">
                                            <path d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.351.351 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91.379-.27.712-.603.993-1.005a4.942 4.942 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.687 2.687 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.695.695 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016c.217.124.4.27.548.438.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.873.873 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.352.352 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32.845-5.214Z"/>
                                        </svg>
                                    }
                                </div>
                            </div>

                        </div>
                        
                        <div className="alert alert-primary mb-0" role="alert">
                            { checkedProducts.map( ( product, index ) => (
                                <div key={product.product_id}>
                                    <i className="bi bi-file-check-fill mr-2"></i>
                                    { product.prod_manufacturer + " " + 
                                        product.prod_name + " - " + 
                                        product.prod_currency + " " + 
                                        ( parseFloat(product.prod_price_with_fees) * product.qty ).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " (" +
                                        product.qty + " item"
                                    }
                                    { product.qty === 1 ? ")" : "s)" }
                                </div>
                            ))}
                            <hr />
                            <div className="d-flex flex-column">
                                <b className="mb-3">
                                    Total Amount: {currency} { totalAmount.toFixed(2) //set to 2 decimal places
                                        .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") // add commas if necessary
                                    }
                                </b>
                                { !isLoading && 
                                    <button className="btn btn-primary" onClick={handlePlaceOrder}>
                                        <i class="bi bi-cash-coin"></i> Place Order
                                    </button>
                                }
                                { isLoading && 
                                    <button className="btn btn-primary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                                    </button>
                                }
                            </div>
                        </div>
                    
                    
                </div>
            }
            
        </div>
    );
}
 
export default UserCheckout;