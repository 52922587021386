import { useHistory } from "react-router-dom";

import useFetch from "../../useFetch";

const Categories = () => {
    const history = useHistory();
    const { data: categoryList } = useFetch('/api/category-list-with-product-count'); //get category list with product count

    const handleCategoryClick = (cat) => {
        history.push(`/search?keyword=&category=${encodeURIComponent(cat)}`);
    }

    return (
        <div className="mobile-categories d-flex overflow-auto my-3 px-3">
            { categoryList && categoryList.map( (category) => (
                <button onClick={() => handleCategoryClick(category.category_name)} key={ category.category_name } className="btn-grad">
                    <span>{category.category_name}</span>
                </button>
            ) ) }
        </div>
    );
}
 
export default Categories;