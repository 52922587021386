import { useState, useEffect } from "react";

import "../../css/mobile/seller-center.css";

import ReceivedOrderItems from "./ReceivedOrderItems"
import UserReceivedOrderDetails from "./UserReceivedOrderDetails"

const UserReceivedOrders = () => {
    const [orders, setOrders] = useState([]);
    const [rerenderToggle, setRerenderToggle] = useState(true); //used to trigger rerender after an return insert or update

    useEffect(() => {
        fetch('/api/user-received-orders')
            .then( res => { return res.json(); } )
            .then( data => { setOrders(data); } );
    }, [rerenderToggle] );

    const triggerRerender = () => {
        setRerenderToggle( !rerenderToggle ); //set to opposite boolean value, just so state is changed and would trigger rerender
    }

    return(
        <div className="d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Received Orders</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="user-order p-3 border rounded">
                    {orders.length === 0 &&
                        <p className="text-center">No received orders found.</p>
                    }
                    {orders.length > 0 &&
                        <>
                            { orders.map( ( receivedOrders, index ) => (
                                <div className="p-2 border border-secondary rounded-3 mb-2" key={index}>
                                    <small>
                                        Order # <span className="fw-bold mr-3">{receivedOrders.order_id}</span><br/>
                                        Received Date: <span className="fw-bold">{receivedOrders.received_date}</span>
                                            <div>
                                                <div className="mb-2">
                                                    <ReceivedOrderItems receivedOrders={receivedOrders} triggerRerender={triggerRerender} receivedDate={receivedOrders.received_date}/>
                                                    <UserReceivedOrderDetails receivedOrders={receivedOrders} index={index} />
                                                </div>
                                            </div>
                                    </small>
                                </div>
                            ) ) }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default UserReceivedOrders;