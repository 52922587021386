import { useState } from "react";
import useFetch from "../useFetch";
import isEmail from 'validator/lib/isEmail';

import paypalLogo from '../../img/logos/paypal.png';

const UserPaymentOptions = () => {
    const [ paypalID, setPaypalID ] = useState(null);
    const [ paymentOption, setPaymentOption ] = useState(null);

    const [ message, setMessage ] = useState('');
    const [ errors, setErrors ] = useState([]);
    const [ isSaving, setIsSaving ] = useState('');

    const { data } = useFetch('/api/user-payment-options'); //get payment options

    if( data && paymentOption === null ) {
        if( data.paymentMethod === null ) setPaymentOption(''); //set to empty string to avoid infinite loop
        else setPaymentOption( data.paymentMethod );
    }
    if( data && paypalID === null ) {
        if( data.paypalID === null ) setPaypalID( '' );
        else setPaypalID( data.paypalID );
    }

    const handleSubmit = (e) => {
        e.preventDefault(); //will prevent default page load
        
        setErrors([]); //empty errors array
        setMessage(''); //empty message

        if( paymentOption !== "" ) { //check if payment option is selected
            if( paypalID === "" || ( paypalID !== "" && isEmail(paypalID) ) ) { //check if email format is valid
                setIsSaving(true);

                const data = { paymentOption, paypalID };

                fetch('/api/user-payment-options', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                })
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( result.successfulUpdate ) setMessage('Your payment method has been saved.');
                    else setErrors([{ err_msg: 'Unable to save payment method.' }]);

                    setIsSaving(false);
                });
            } else if( paypalID !== "" ) setErrors([{ err_msg: 'Please enter a valid email.' }]); //invalid email format
                
        } else setErrors([{ err_msg: 'No payment selected.' }]); //empty

        
    }

    const handleRadioOnChange = (e) => {
        setErrors([]); //empty errors array
        setPaypalID(''); //set to empty to avoid null as value
        setPaymentOption(e.target.value);
    }

    return (
        <div className="payment-options d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Payment</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="user-form p-4 border rounded">
                    <form onSubmit={handleSubmit}>
                        { errors &&
                            errors.map( (e) => (
                                <div className="error my-3" key={e.err_msg}>
                                    <span>{e.err_msg}</span>
                                </div>
                            ) )
                        }
                        { message &&
                            <div className="success-message mb-3">
                                <span>{message}</span>
                            </div>
                        }

                        { paypalLogo &&
                            <h5 className="mb-3">
                                Select payment option:
                            </h5>
                        }

                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="select-payment-options" id="pay-paypal" value="paypal" 
                                onChange={handleRadioOnChange} checked={ paymentOption === 'paypal' } />
                            <label className="form-check-label" htmlFor="pay-paypal">
                                Use Paypal
                                <img src={paypalLogo} alt="Paypal" height="18" className="mx-1" />
                            </label>
                        </div>

                        <p className="mt-3">
                            <small>
                                <a href="https://www.paypal.com" target="_blank" style={{textDecoration: 'underline'}}>Click here</a> to register an account with paypal.
                            </small>
                        </p>

                        { paymentOption === 'paypal' &&
                            <>
                                <hr />
                                <div className="mt-3">
                                    <h5>If you wish to sell, please provide your paypal email.</h5>
                                    <input type="text" value={paypalID} onChange={ e => setPaypalID( e.target.value ) } className="input-text mt-3" placeholder="Paypal email" />
                                </div>
                            </>
                        }

                        <div className="mt-4">
                            { !isSaving && 
                                <button className="btn btn-primary">
                                    <i className="bi bi-save"></i> Save
                                </button> 
                            }
                            { isSaving && 
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving ...
                                </button>
                            }
                        </div>
                    </form>
                    

                    <hr />
                    <h6>More payment options will be available soon.</h6>
                </div>
            </div>
        </div>
    );
}
 
export default UserPaymentOptions;