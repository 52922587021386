import { useState, useEffect } from "react";
import TextAreaCounter from "../TextAreaCounter";

import defaultProfileImg from '../../../../img/default-profile.png';

const Comments = ({ id, allowReply, hasComments, newCommentsCount, profilePicture }) => {
    const [ comment, setComment ] = useState("");
    const [ comments, setComments ] = useState([]);

    const [ showComments, setShowComments ] = useState(false);
    const [ otherProfilePic, setOtherProfilePic ] = useState(null);
    const [ isSaving, setIsSaving ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    const handleShowComments = () => {
        setIsLoading(true);

        fetch(`/api/services-bookings-comments/${id}`)
            .then( res => { 
                if(res.ok) return res.json();
                throw new Error('Unable to process request.');
            }).then( data => {
                if(data) { //check if data is not null
                    //set other profile pic
                    for( let i = 0; i < data.length; i++ ) {
                        if( data[i].from === 'not-me' && 'other_profile_pic' in data[i] && data[i].other_profile_pic !== undefined ) {
                            setOtherProfilePic(data[i].other_profile_pic);
                            i = data[i].length; //exit loop
                        }
                    }
                    setComments(data);
                    setShowComments(true);
                    setIsLoading(false);
                }
            }).catch( (e) => {
                alert(e.message);
                setIsLoading(false);
            })
        ;
    }

    const handlePostComment = () => {
        if( comment !== "" ) {
            setIsSaving(true);

            const data = { bid: id, comment };

            fetch('/api/services-bookings-comments-add', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then( (result) => result.json() )
            .then( (result) =>  {
                if( result ) {
                    setComments(result);

                    setShowComments(true);
                    setComment("");
                    setIsSaving(false);
                } else alert("Unable to process request.");
            });
        } else alert("Please write your comment.");
    }

    return (
        <div className="comments">
            { hasComments && !showComments &&
                <button type="button" className="btn btn-show-comments btn-services-gold position-relative" onClick={handleShowComments}>
                    Show Comments
                    { newCommentsCount > 0 &&
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {newCommentsCount}
                            <span className="visually-hidden">show comments</span>
                        </span>
                    }
                </button>
            }

            { showComments && comments && comments.map( (c, i) => (
                <div key={i} className={ c.from === 'me' ? 'comment-me d-flex flex-row-reverse' : 'comment-not-me d-flex' }>
                    { c.from === 'me' &&
                        <>
                            { profilePicture && <img src={profilePicture} className="sender-pic align-self-center rounded-circle" /> }
                            { !profilePicture && <img src={defaultProfileImg} className="sender-pic align-self-center rounded-circle" /> }
                        </>
                    }
                    { c.from === 'not-me' &&
                        <>
                            { otherProfilePic && <img src={otherProfilePic} className="sender-pic align-self-center rounded-circle" /> }
                            { !otherProfilePic && <img src={defaultProfileImg} className="sender-pic align-self-center rounded-circle" /> }
                        </>
                    }
                    
                    <div className="mx-1 text-break">{c.comment}</div>
                </div>
            ))}

            { allowReply &&
                <>
                    <div className="d-flex mt-2">
                        <input type="text" className="form-control input-text" maxLength="150" value={comment} onChange={(e)=>setComment(e.target.value)} placeholder="Write a comment..." style={{ fontSize: '0.8rem', borderRadius: '15px', padding: '4px 12px' }} />
                        { !isSaving && 
                            <button className="btn btn-send-comment font-color-gold" onClick={handlePostComment}>
                                <i className="fas fa-arrow-alt-circle-right"></i>
                            </button>
                        }
                        { isSaving &&
                            <button className="btn btn-send-comment font-color-gold">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                        }
                        
                    </div>
                    <h6 className="mt-1 ml-2 mb-0">
                        <TextAreaCounter text={comment} maxLength="150" />
                    </h6>
                </>
            }
        </div>
    );
}
 
export default Comments;