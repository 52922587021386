import { useState, useEffect } from "react";

import Comments from "../reusable/services/Comments";
import RatingAndReview from "../reusable/RatingAndReview";

const ClientMyBookings = ({ profilePicture }) => {
    const [ bookings, setBookings ] = useState([]);
    const [ expiredBookings, setExpiredBookings ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ showHideText, setShowHideText ] = useState("Show");

    useEffect( () => {
        setIsLoading(true);

        //get current date
        const curDate = new Date();
        const d = curDate.getDate(); //get date
        const m = curDate.getMonth() + 1; //get month
        const y = curDate.getFullYear(); //get year

        fetch(`/api/service-client-my-bookings?y=${y}&m=${m}&d=${d}`)
            .then( res => { 
                if(res.ok) return res.json();
                throw new Error('No booking found.');
            }).then( data => {
                if(data) { //check if data is not null
                    if( data.length > 0 ) {
                        let temp_bookings = [], temp_expired_bookings = [];
                        for( let i = 0; i < data.length; i++ ) {
                            data[i].isExpired ? temp_expired_bookings.push( data[i] ) : temp_bookings.push( data[i] );
                        }

                        setBookings(temp_bookings);
                        setExpiredBookings(temp_expired_bookings);
                    }
                    
                    setIsLoading(false);
                } else throw new Error('No booking found.'); //data is null, send error
            }).catch( (e) => {
                alert(e.message);
            })
        ;
    }, [] );

    const handleShowHide = () => {
        if( showHideText === "Show" ) setShowHideText("Hide");
        else if( showHideText === "Hide" ) setShowHideText("Show");
    }
    
    return (
        <div className="client-my-bookings book-service service-details services d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center mb-3">My Bookings</h3>

            { isLoading && 
                <div className="d-flex justify-content-center user-form rounded py-4">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </div>
            }

            { !isLoading && bookings.length === 0 &&
                <div className="d-flex justify-content-center user-form rounded py-4">
                    You have no available bookings.
                </div>
            }

            { !isLoading && bookings.length > 0 && bookings.map( (b, i) => (
                <div key={b.service_booking_id} className="service-details w-100">
                    <div className="alert" role="alert">
                        <div>
                            <div className="mb-1 font-color-gold"><b>{b.serv_name}</b></div>
                        </div>

                        <h6>
                            {b.serv_payment_currency} {b.serv_payment_rate} ({b.serv_payment_unit})
                        </h6>
                        
                        <h6>By {b.provider_name}</h6>

                        <hr />

                        { b.start_dt_2 &&
                            <div className="d-flex flex-row">
                                <div className="mr-1">
                                    <b><i className="bi bi-calendar-date-fill" style={{color: '#dc3545'}}></i></b>
                                </div>
                                <div>
                                    <div>
                                        {b.start_dt_2}
                                        {b.end_dt_2 && ' - ' + b.end_dt_2}
                                    </div>
                                </div>
                            </div>
                        }

                        { b.start_time &&
                            <div className="d-flex flex-row">
                                <div className="mr-1">
                                    <b><i class="bi bi-clock-fill" style={{color: '#dc3545'}}></i></b>
                                </div>
                                <div>
                                    <div>
                                        {b.start_time}
                                        {b.end_time && ' - ' + b.end_time}    
                                    </div>
                                </div>
                            </div>
                        }

                        <hr />
                        <Comments id={b.service_booking_id} allowReply={true} hasComments={b.hasComments} newCommentsCount={b.newCommentsCount} profilePicture={profilePicture} />
                        
                    </div>
                </div>
            ))}

            { expiredBookings.length > 0 &&
                <>
                    <hr className="my-4" />
                    <div className="alert d-flex justify-content-between" role="alert">
                        <h6 className="align-self-center">Previous bookings:</h6>
                        <button onClick={handleShowHide} className="btn btn-sm btn-services-gold" data-bs-toggle="collapse" data-bs-target="#other-bookings" aria-expanded="false" aria-controls="other-bookings">{showHideText}</button>
                    </div>
                </>
            }
            <div id="other-bookings" class="collapse">
                {  expiredBookings.map( (b, i) => (
                    <div key={b.service_booking_id} className="alert" role="alert"> 
                        <div>
                            <div className="mb-1 font-color-gold"><b>{b.serv_name}</b></div>
                        </div>

                        <h6>
                            {b.serv_payment_currency} {b.serv_payment_rate} ({b.serv_payment_unit})
                        </h6>
                        
                        <h6>By {b.provider_name}</h6>

                        <hr />

                        { b.start_dt_2 &&
                            <div className="d-flex flex-row">
                                <div className="mr-1">
                                    <b><i className="bi bi-calendar-date-fill" style={{color: '#dc3545'}}></i></b>
                                </div>
                                <div>
                                    <div>
                                        {b.start_dt_2}
                                        {b.end_dt_2 && ' - ' + b.end_dt_2}
                                    </div>
                                </div>
                            </div>
                        }

                        { b.start_time &&
                            <div className="d-flex flex-row">
                                <div className="mr-1">
                                    <b><i class="bi bi-clock-fill" style={{color: '#dc3545'}}></i></b>
                                </div>
                                <div>
                                    <div>
                                        {b.start_time}
                                        {b.end_time && ' - ' + b.end_time}    
                                    </div>
                                </div>
                            </div>
                        }

                        <hr />

                        <RatingAndReview category="service" id={b.service_booking_id} currentRating={b.rating} currentReview={b.review} title="Rate this service" />
                    </div>
                ))}
            </div>
        </div>
    );
}
 
export default ClientMyBookings;