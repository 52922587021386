// import { useState } from "react";

const SellerCenterDeleteProduct = ({ products, triggerRerender}) => {
    // const [productID, setProductID] = useState("")

    // const handleModal = () => {
    //     setProductID(products.product_id)
    //     document.getElementById("btn-modal").click()
    // }

    const handleDelete = (e) => {
        e.preventDefault(); //will prevent default page load

        if( window.confirm("Are you sure you want to delete this product?") ) {
        fetch(`/api/seller-center/delete-product/${products.product_id}`)
            .then( (result) => result.json() )
            .then( (result) =>  {
                if( 'successfulDelete' in result && result.successfulDelete ) { //successful delete
                    triggerRerender(); //trigger rerender of parent component
                }
                else { //unsuccessful delete
                     alert("Something went wrong!");
                }
            });
        }
    };

    return(
        <button type="button" className="mr-2 btn btn-danger btn-sm" onClick={handleDelete}>
            <small>Delete</small>
        </button>
    )
}

export default SellerCenterDeleteProduct;