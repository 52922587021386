import { useState } from "react";
import useFetch from "../useFetch";

const UserSecurityQuestions = () => {
    const [ q1, setQ1 ] = useState(null);
    const [ q2, setQ2 ] = useState(null);
    const [ q3, setQ3 ] = useState(null);

    const [ a1, setA1 ] = useState(null);
    const [ a2, setA2 ] = useState(null);
    const [ a3, setA3 ] = useState(null);
    
    const [ errors, setErrors ] = useState([]);
    const [ message, setMessage ] = useState('');
    const [ isSaving, setIsSaving ] = useState('');

    const { data: secQListandUserQA } = useFetch('/api/security-question-list'); //get security question list
    if( secQListandUserQA && secQListandUserQA.userSecQandA && a1 === null && a2 === null && a3 === null && q1 === null && q2 === null && q3 === null ) {
        if( secQListandUserQA.userSecQandA.length > 0 ) {
            setQ1( secQListandUserQA.userSecQandA[0].trans_code );
            setQ2( secQListandUserQA.userSecQandA[1].trans_code );
            setQ3( secQListandUserQA.userSecQandA[2].trans_code );

            setA1( secQListandUserQA.userSecQandA[0].answer );
            setA2( secQListandUserQA.userSecQandA[1].answer );
            setA3( secQListandUserQA.userSecQandA[2].answer );
        }
        
    }
    
    const handleSubmit = (e) => {
        e.preventDefault(); //will prevent default page load

        if( q1 && q2 && q3 && a1 && a2 && a3 ) {
            setIsSaving(true);
            
            const qA = { q1, q2, q3, a1, a2, a3 };
                
            fetch('/api/user-security-questions', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(qA)
            })
            .then( (result) => result.json() )
            .then( (result) =>  {
                if( result.successfulInsert || result.successfulUpdate ) {
                    setMessage('Your chosen security questions and answers have been saved.');
                    setErrors('');
                } else if( Array.isArray(result) ) {
                    setErrors(result);
                    setMessage('');
                } else {
                    setErrors([{ err_msg: 'Unable to process your request' }]);
                    setMessage('');
                }

                setIsSaving(false);
            });
        } else {
            setErrors([{ err_msg: 'All fields are required.' }]);
            setMessage('');
        }
    }

    return (
        <div className="security-questions d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Security Questions</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="user-form p-4 border rounded">
                    <h5 className="mb-3">
                        Please select 3 different questions and supply them with answers. 
                        Make sure not to forget your answers since these will be used to reset in case you forget your password.
                    </h5>
                    <form id="user-security-questions-form" onSubmit={handleSubmit}>
                        { errors &&
                            errors.map( (e) => (
                                <div className="error mb-3" key={e.err_msg}>
                                    <span>{e.err_msg}</span>
                                </div>
                            ) )
                        }
                        { message &&
                            <div className="success-message mb-3">
                                <span>{message}</span>
                            </div>
                        }

                        { secQListandUserQA &&
                            <select className="form-select my-1" aria-label="Security Question 1" onChange={ e => setQ1( e.target.value ) } defaultValue={ q1 }>
                                <option value="">Security Question 1</option>
                                { secQListandUserQA.secQuestList && secQListandUserQA.secQuestList.map( (question) => (
                                    <option key={ question.descr } value={question.descr}>
                                        {question.descr}
                                    </option>
                                ) ) }
                            </select>
                        }
                        <input type="text" value={a1} onChange={ e => setA1( e.target.value ) } placeholder="Answer 1" className="input-text my-1" />

                        { secQListandUserQA &&
                            <select className="form-select my-1 mt-3" aria-label="Security Question 2" onChange={ e => setQ2( e.target.value ) } defaultValue={ q2 }>
                                <option value="">Security Question 2</option>
                                { secQListandUserQA && secQListandUserQA.secQuestList && secQListandUserQA.secQuestList.map( (question) => (
                                    <option key={ question.descr } value={question.descr}>
                                        {question.descr}
                                    </option>
                                ) ) }
                            </select>
                        }
                        <input type="text" value={a2} onChange={ e => setA2( e.target.value ) } placeholder="Answer 2" className="input-text my-1" />

                        { secQListandUserQA &&
                            <select className="form-select my-1 mt-3" aria-label="Security Question 3" onChange={ e => setQ3( e.target.value ) } defaultValue={ q3 }>
                                <option value="">Security Question 3</option>
                                { secQListandUserQA && secQListandUserQA.secQuestList && secQListandUserQA.secQuestList.map( (question) => (
                                    <option key={ question.descr } value={question.descr}>
                                        {question.descr}
                                    </option>
                                ) ) }
                            </select>
                        }
                        <input type="text" value={a3} onChange={ e => setA3( e.target.value ) } placeholder="Answer 3" className="input-text my-1" />

                        { !isSaving && 
                            <button className="btn btn-primary mt-3">
                                <i className="bi bi-save"></i> Save
                            </button>
                        }
                        { isSaving && 
                            <button className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving ...
                            </button>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
}

export default UserSecurityQuestions;