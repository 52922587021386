import { useState } from "react";

const SkillAddEditDelete = ({ action, addSkill }) => {
    const [skill, setSkill] = useState("");
    const [level, setLevel] = useState("Beginner");

    const handleAdd = (e) => {
        e.preventDefault(); //will prevent default page load

        if( skill !== "" ) {
            addSkill(skill, level);

            setSkill("");
            setLevel("Beginner");
            document.getElementById("add-skill-close-modal").click(); //close the modal
        }
        
    }

    return (
        <>
            { action === 'add' &&
                <>
                    <button type="button" className="btn add" data-bs-toggle="modal" data-bs-target="#modal-add-new-skill" title="Add new skill">
                        <i className="bi bi-plus-circle-fill" style={{color: '#FFD700'}}></i>
                    </button>


                    <div className="modal" id="modal-add-new-skill" tabIndex="-1" aria-labelledby="modal-add-new-skill-title" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header mobile-modal-header">
                                    <h5 className="modal-title" id="modal-add-new-skill-title">Add a skill</h5>
                                    <button type="button" id="add-skill-close-modal" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body p-4">
                                    <input type="text" className="form-control mb-2" placeholder="Input skill" value={skill} onChange={ (e) => setSkill(e.target.value) } />
                                    <select id="skillLevel" name="skillLevel" className="form-select" aria-label="Choose skill level" value={level} onChange={ (e) => setLevel(e.target.value) }>
                                        <option value="Beginner">Beginner</option>
                                        <option value="Intermediate">Intermediate</option>
                                        <option value="Expert">Expert</option>
                                    </select>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-primary" onClick={handleAdd}>
                                        <i className="bi bi-plus-circle-fill"></i> Add Skill
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            }
            
        </>
    );
}
 
export default SkillAddEditDelete;