import { useState } from "react";

const UserAddressAdd = ({ triggerRerender, countries }) => {
    const [ addAddressType, setAddAddressType ] = useState('Home');
    const [ addAddressIsDefault, setAddAddressIsDefault ] = useState(false);

    const [ addAddressAddr1, setAddAddressAddr1 ] = useState('');
    const [ addAddressAddr2, setAddAddressAddr2 ] = useState('');
    const [ addAddressCity, setAddAddressCity ] = useState('');
    const [ addAddressState, setAddAddressState ] = useState('');
    const [ addAddressZip, setAddAddressZip ] = useState('');
    const [ addAddressCountry, setAddAddressCountry ] = useState('');
    
    const [ isPending, setIsPending ] = useState(false);

    const [ formUnsubmitted, setFormUnsubmitted ] = useState(true);

    const handleAddNewAddress = (e) => {
        e.preventDefault(); //will prevent default page load

        setFormUnsubmitted(false);

        //check required fields
        if( addAddressAddr1.trim() !== "" && addAddressCity.trim() !== "" && addAddressState.trim() !== "" && addAddressZip.trim() !== "" && addAddressCountry.trim() !== "" ) {
            setIsPending(true);

            const newAddress = {
                is_default: addAddressIsDefault,
                addr_type: addAddressType,
                addr1: addAddressAddr1,
                addr2: addAddressAddr2,
                city: addAddressCity,
                state: addAddressState,
                zip: addAddressZip,
                country: addAddressCountry
            };

            fetch('/api/user-add-address', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newAddress)
            })
            .then( (result) => result.json() )
            .then( (result) =>  {
                if( 'successfulInsert' in result && result.successfulInsert ) { //successful add
                    //set to default values
                    setAddAddressType('Home');
                    setAddAddressIsDefault(false);
                    setAddAddressAddr1('');
                    setAddAddressAddr2('');
                    setAddAddressCity('');
                    setAddAddressState('');
                    setAddAddressZip('');
                    setAddAddressCountry('');

                    document.getElementById("close-modal").click(); //close the modal
                    
                    //set default
                    setFormUnsubmitted(true);
                    setIsPending(false);

                    triggerRerender(); //trigger rerender of parent component
                }
                else { //unsuccessful add
                    alert("Something went wrong!");
                    setIsPending(false);
                }
            });
        }
    }

    return (
        <form onSubmit={handleAddNewAddress}>
            <div className="modal" id="modal-add-new-address" tabIndex="-1" aria-labelledby="modal-add-new-address-title" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header mobile-modal-header">
                            <h5 className="modal-title" id="modal-add-new-address-title">Add new address</h5>
                            <button type="button" id="close-modal" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex flex-column">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="is-default-address" checked={addAddressIsDefault} onChange={ (e) => setAddAddressIsDefault(e.target.checked) } />
                                    <label className="form-check-label" htmlFor="is-default-address">Set as default address</label>
                                </div>

                                <div className="address-type d-sm-flex mt-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="select-address-type" id="home-address" value="Home" onChange={ (e) => setAddAddressType(e.target.value) } defaultChecked />
                                        <label className="form-check-label" htmlFor="home-address">Home</label>
                                    </div>
                                    <div className="form-check ml-sm-4">
                                        <input className="form-check-input" type="radio" name="select-address-type" id="office-address" value="Office" onChange={ (e) => setAddAddressType(e.target.value) } />
                                        <label className="form-check-label" htmlFor="office-address">Office</label>
                                    </div>
                                    <div className="form-check ml-sm-4">
                                        <input className="form-check-input" type="radio" name="select-address-type" id="other-address" value="Other" onChange={ (e) => setAddAddressType(e.target.value) } />
                                        <label className="form-check-label" htmlFor="other-address">Other</label>
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <input type="text" id="add-address1" placeholder="Address 1" 
                                        className={ formUnsubmitted || addAddressAddr1 ? 'input-text' : 'input-text border-color-red' } 
                                        value={ addAddressAddr1 } onChange={ (e) => setAddAddressAddr1(e.target.value) }/>
                                </div>

                                <div className="mt-3">
                                    <input type="text" id="add-address2" placeholder="Address 2" className="input-text"
                                        value={ addAddressAddr2 } onChange={ (e) => setAddAddressAddr2(e.target.value) } />
                                </div>

                                <div className="mt-3">
                                    <input type="text" id="add-address-city" placeholder="City"
                                        className={ formUnsubmitted || addAddressCity ? 'input-text' : 'input-text border-color-red' }
                                        value={ addAddressCity } onChange={ (e) => setAddAddressCity(e.target.value) } />
                                </div>

                                <div className="mt-3">
                                    <input type="text" id="add-address-state" placeholder="State/Province"
                                        className={ formUnsubmitted || addAddressState ? 'input-text' : 'input-text border-color-red' }
                                        value={ addAddressState } onChange={ (e) => setAddAddressState(e.target.value) } />
                                </div>

                                <div className="mt-3">
                                    <input type="text" id="add-address-zip" placeholder="Postal Code"
                                        className={ formUnsubmitted || addAddressZip ? 'input-text' : 'input-text border-color-red' }
                                        value={ addAddressZip } onChange={ (e) => setAddAddressZip(e.target.value) } />
                                </div>

                                <div className="mt-3">
                                    { countries &&
                                        <select className="form-select my-1" aria-label="Select Country" onChange={ (e) => setAddAddressCountry(e.target.value) } value={ addAddressCountry }>
                                            <option value="">Select Country</option>
                                            { countries.map( (c) => (
                                                <option key={ c.country } value={c.country}>
                                                    {c.country}
                                                </option>
                                            ) ) }
                                        </select>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary">
                                <i className="bi bi-save"></i> Save Address
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    );
}
 
export default UserAddressAdd;