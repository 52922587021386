import { useState, useEffect } from "react";

const RatingStars = ({ fontSize, currentRating, setRating, allowChange = true, showRating = false, totalRatings = false }) => {
    const [ iconStar1, setIconStar1 ] = useState("bi-star");
    const [ iconStar2, setIconStar2 ] = useState("bi-star");
    const [ iconStar3, setIconStar3 ] = useState("bi-star");
    const [ iconStar4, setIconStar4 ] = useState("bi-star");
    const [ iconStar5, setIconStar5 ] = useState("bi-star");

    const [ isLock, setIsLock ] = useState(false);
    const [ lockNumber, setLockNumber ] = useState(null);

    const setStars = (stars) => {
        switch(true) {
            case ( stars >= 1 && stars < 1.25 ):
                setIconStar1("bi-star-fill");
                setIconStar2("bi-star");
                setIconStar3("bi-star");
                setIconStar4("bi-star");
                setIconStar5("bi-star");
                break;
            case ( stars >= 1.25 && stars < 2 ):
                setIconStar1("bi-star-fill");
                setIconStar2("bi-star-half");
                setIconStar3("bi-star");
                setIconStar4("bi-star");
                setIconStar5("bi-star");
                break;
            case ( stars >= 2 && stars < 2.25 ):
                setIconStar1("bi-star-fill");
                setIconStar2("bi-star-fill");
                setIconStar3("bi-star");
                setIconStar4("bi-star");
                setIconStar5("bi-star");
                break;
            case ( stars >= 2.25 && stars < 3 ):
                setIconStar1("bi-star-fill");
                setIconStar2("bi-star-fill");
                setIconStar3("bi-star-half");
                setIconStar4("bi-star");
                setIconStar5("bi-star");
                break;
            case ( stars >= 3 && stars < 3.25 ):
                setIconStar1("bi-star-fill");
                setIconStar2("bi-star-fill");
                setIconStar3("bi-star-fill");
                setIconStar4("bi-star");
                setIconStar5("bi-star");
                break;
            case ( stars >= 3.25 && stars < 4 ):
                setIconStar1("bi-star-fill");
                setIconStar2("bi-star-fill");
                setIconStar3("bi-star-fill");
                setIconStar4("bi-star-half");
                setIconStar5("bi-star");
                break;
            case ( stars >= 4 && stars < 4.25 ):
                setIconStar1("bi-star-fill");
                setIconStar2("bi-star-fill");
                setIconStar3("bi-star-fill");
                setIconStar4("bi-star-fill");
                setIconStar5("bi-star");
                break;
            case ( stars >= 4.25 && stars < 5 ):
                setIconStar1("bi-star-fill");
                setIconStar2("bi-star-fill");
                setIconStar3("bi-star-fill");
                setIconStar4("bi-star-fill");
                setIconStar5("bi-star-half");
                break;
            case ( stars === 5 ):
                setIconStar1("bi-star-fill");
                setIconStar2("bi-star-fill");
                setIconStar3("bi-star-fill");
                setIconStar4("bi-star-fill");
                setIconStar5("bi-star-fill");
                break;
            default:
                break;
        }
    }

    const highlightStars = (starNumber) => {
        setStars(starNumber);
    }

    const unHighlightStars = () => {
        if((isLock)) { //highlight instead if locked
            setStars(lockNumber);
        }
        else {
            setIconStar1("bi-star");
            setIconStar2("bi-star");
            setIconStar3("bi-star");
            setIconStar4("bi-star");
            setIconStar5("bi-star");
        }
    }

    const handleOnClick = (starNumber) => {
        if(isLock) {
            if( lockNumber === starNumber ) {
                setIsLock(!isLock); //unlock
                setLockNumber(null);
            }
            else setLockNumber(starNumber); //set lock number, remain locked
        } else { //not lock
            setIsLock(!isLock); //set to lock
            setLockNumber(starNumber); //set lock number
        }
    }

    useEffect( () => {
        //call setRating everytime lockNumber changes

        if( lockNumber !== null ) setRating(lockNumber);
        else {
            if( currentRating !== null ) setStars(currentRating);
            else {
                if(setRating !== undefined) setRating(0);
            }
        }
    }, [lockNumber] );

    return (
        <>
            { (currentRating || allowChange) &&
                <>
                    { showRating && <span style={{ fontSize }} className="font-color-gold mr-2 align-middle">{currentRating} out of 5</span> }
                    
                    <button 
                        className="btn p-0 mr-1" 
                        style={{ fontSize }} 
                        onClick={ allowChange ? () => handleOnClick(1) : undefined }
                        onMouseEnter={ allowChange ? () => highlightStars(1) : undefined } 
                        onMouseLeave={ allowChange ? unHighlightStars : undefined } >
                            <i className={`bi ${iconStar1} font-color-gold`}></i>
                    </button>
                    
                    <button 
                        className="btn p-0 mr-1" 
                        style={{ fontSize }} 
                        onClick={ allowChange ? () => handleOnClick(2) : undefined }
                        onMouseEnter={ allowChange ? () => highlightStars(2) : undefined } 
                        onMouseLeave={ allowChange ? unHighlightStars : undefined } >
                            <i className={`bi ${iconStar2} font-color-gold`}></i>
                    </button>

                    <button 
                        className="btn p-0 mr-1" 
                        style={{ fontSize }} 
                        onClick={ allowChange ? () => handleOnClick(3) : undefined }
                        onMouseEnter={ allowChange ? () => highlightStars(3) : undefined } 
                        onMouseLeave={ allowChange ? unHighlightStars : undefined } >
                            <i className={`bi ${iconStar3} font-color-gold`}></i>
                    </button>

                    <button 
                        className="btn p-0 mr-1" 
                        style={{ fontSize }} 
                        onClick={ allowChange ? () => handleOnClick(4) : undefined }
                        onMouseEnter={ allowChange ? () => highlightStars(4) : undefined } 
                        onMouseLeave={ allowChange ? unHighlightStars : undefined } >
                            <i className={`bi ${iconStar4} font-color-gold`}></i>
                    </button>

                    <button 
                        className="btn p-0 mr-1" 
                        style={{ fontSize }} 
                        onClick={ allowChange ? () => handleOnClick(5) : undefined }
                        onMouseEnter={ allowChange ? () => highlightStars(5) : undefined } 
                        onMouseLeave={ allowChange ? unHighlightStars : undefined } >
                            <i className={`bi ${iconStar5} font-color-gold`}></i>
                    </button>

                    { totalRatings && <span className="ml-2 align-middle" style={{ fontSize }}>{totalRatings} Rating{ totalRatings === '1' ? '' : 's' }</span> }
                </>
            }

            { !currentRating && !allowChange &&
                <span className="font-italic" style={{ fontSize: '0.8rem' }}>No ratings</span>
            }
            
        </>
    );
}
 
export default RatingStars;