
const Disclaimer = () => {
    return(
        <>
            <div className="d-flex flex-column pt-3 px-3">
                <h3 className="d-flex justify-content-center"><strong>Disclaimer</strong></h3>

                <div className="d-flex justify-content-center mt-2">
                    <div className="legal-page p-3 border rounded">
                        <p className="pt-2 pb-2">This disclaimer ("Disclaimer") sets forth the general guidelines, disclosures, and terms of your use of the https://trivut.com website ("Website" or "Service") and any of its related products and services (collectively, "Services"). This Disclaimer is a legally binding agreement between you ("User", "you" or "your") and this Website operator ("Operator", "we", "us" or "our"). By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Disclaimer. If you are entering into this Disclaimer on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Disclaimer, in which case the terms "User", "you" or "your" shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Disclaimer, you must not accept this Disclaimer and may not access and use the Website and Services. You acknowledge that this Disclaimer is a contract between you and the Operator, even though it is electronic and is not physically signed by you, and it governs your use of the Website and Services.</p>
                        <h4>Representation</h4>
                        <p className="pt-2 pb-2">Any views or opinions represented on the Website belong solely to the content creators and do not represent those of people, institutions or organizations that the Operator or creators may or may not be associated with in professional or personal capacity, unless explicitly stated. Any views or opinions are not intended to malign any religion, ethnic group, club, organization, company, or individual.</p>
                        <h4>Content and postings</h4>
                        <p className="pt-2 pb-2">You may print or copy any part of the Website and Services for your personal or non-commercial use.</p>
                        <p className="pt-2 pb-2">You may submit new content and comment on the existing content on the Website. By uploading or otherwise making available any information to the Operator, you grant the Operator the unlimited, perpetual right to distribute, display, publish, reproduce, reuse and copy the information contained therein. You may not impersonate any other person through the Website and Services. You may not post content that is defamatory, fraudulent, obscene, threatening, invasive of another person's privacy rights or that is otherwise unlawful. You may not post content that infringes on the intellectual property rights of any other person or entity. You may not post any content that includes any computer virus or other code designed to disrupt, damage, or limit the functioning of any computer software or hardware. By submitting or posting content on the Website, you grant the Operator the right to edit and, if necessary, remove any content at any time and for any reason.</p>
                        <h4>Reviews and testimonials</h4>
                        <p className="pt-2 pb-2">Testimonials are received in various forms through a variety of submission methods. The testimonials are not necessarily representative of all of those who will use Website and Services, and the Operator is not responsible for the opinions or comments available on the Website, and does not necessarily share them. People providing testimonials on the Website may have been compensated with free products or discounts for use of their experiences. All opinions expressed are strictly the views of the reviewers.</p>
                        <p className="pt-2 pb-2">Some testimonials may have been edited for clarity, or shortened in cases where the original testimonial included extraneous information of no relevance to the general public. Testimonials may be reviewed for authenticity before they are available for public viewing.</p>
                        <h4>Indemnification and warranties</h4>
                        <p className="pt-2 pb-2">While we have made every attempt to ensure that the information contained on the Website is correct, the Operator is not responsible for any errors or omissions, or for the results obtained from the use of this information. All information on the Website is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied. In no event will the Operator, or its partners, employees or agents, be liable to you or anyone else for any decision made or action taken in reliance on the information on the Website, or for any consequential, special or similar damages, even if advised of the possibility of such damages. Information on the Website is for general information purposes only and is not intended to provide any type of professional advice. Please seek professional assistance should you require it. Information contained on the Website are subject to change at any time and without warning.</p>
                        <h4>Changes and amendments</h4>
                        <p className="pt-2 pb-2">We reserve the right to modify this Disclaimer or its terms relating to the Website and Services at any time, effective upon posting of an updated version of this Disclaimer on the Website. When we do, we will revise the updated date at the bottom of this page. Continued use of the Website and Services after any such changes shall constitute your consent to such changes.</p>
                        <h4>Acceptance of this disclaimer</h4>
                        <p className="pt-2 pb-2">You acknowledge that you have read this Disclaimer and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Disclaimer. If you do not agree to abide by the terms of this Disclaimer, you are not authorized to access or use the Website and Services.</p>
                        <h4>Contacting us</h4>
                        <p className="pt-2 pb-3">If you would like to contact us to understand more about this Disclaimer or wish to contact us concerning any matter relating to it, you may do so via our <a href="mailto: &#109;&#97;&#114;&#107;&#101;&#116;&#105;&#110;&#103;&#116;&#114;&#105;&#118;&#117;&#116;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;"><u>email</u></a>.</p>
                        <p className="pt-2 pb-2 fw-light">This document was last updated on February 9, 2022</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Disclaimer;