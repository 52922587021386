import { Link } from "react-router-dom";

import "../../../css/mobile/services.css";

const ServicesMenu = ({path}) => {
    return (
        <div className="services-menu user-menu d-flex flex-column pt-3 px-3">
            
            <div class="accordion accordion-flush mt-3" id="accordionServicesMenu">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            Service Provider
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne">
                    <div class="accordion-body">
                        <Link to={path + 'services/provider/booked-clients'} className="menu-link">
                            Booked Clients <i class="bi bi-chevron-right"></i>
                        </Link>
                        <Link to={path + 'services/provider/booking-requests'} className="menu-link">
                            Booking Requests <i class="bi bi-chevron-right"></i>
                        </Link>
                        <Link to={path + 'services/provider/declined-clients'} className="menu-link">
                            Declined Clients <i class="bi bi-chevron-right"></i>
                        </Link>
                        <Link to={path + 'services/add'} className="menu-link">
                            Add Service <i class="bi bi-chevron-right"></i>
                        </Link>
                        <Link to={path + 'services/manage'} className="menu-link">
                            Manage Services <i class="bi bi-chevron-right"></i>
                        </Link>
                    </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Client
                    </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo">
                    <div class="accordion-body">
                        <Link to={path + 'services/client/my-bookings'} className="menu-link">
                            My Bookings <i class="bi bi-chevron-right"></i>
                        </Link>
                        <Link to={path + 'services/client/my-pending-requests'} className="menu-link">
                            My Pending Requests <i class="bi bi-chevron-right"></i>
                        </Link>
                        <Link to={path + 'services/client/declined-bookings'} className="menu-link">
                            Declined Bookings <i class="bi bi-chevron-right"></i>
                        </Link>
                    </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
 
export default ServicesMenu;