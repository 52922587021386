import { Link } from "react-router-dom";

const Header = ({ isHome }) => {
    return (
        <div className="mobile-header d-flex justify-content-center py-4" style={ isHome ? { borderBottom: '0' } : { borderBottom: '1px solid #414345' }}>
            <Link to="/" className="">
                <h4>
                    T<span className="smaller-font-size">RIVUT</span> M<span className="smaller-font-size">ARKETPLACE</span>
                </h4>
            </Link>
        </div>
    );
}

export default Header;