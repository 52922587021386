import { useState } from "react";

import "../../css/mobile/seller-center.css";

const UserOrderToReceiveDetails = ({orders, index}) => {
    const [deliveryName] = useState(orders.delivery_name);
    const [deliveryAddress] = useState(orders.delivery_address);
    const [deliveryPhone] = useState(orders.delivery_phone);
    const [courier] = useState(orders.delivery_type);
    const [trackingCode] = useState(orders.tracking_code);

    const twoGoLink = `https://supplychain.2go.com.ph/CustomerSupport/ttrace/track_status.asp?bl=${trackingCode}`;
    const air21Link = "https://www.air21.com.ph/main/shipment-tracking-dfs.php";
    const airSpeedLink = "https://airspeed.ph/track-cargo/";
    const apCargoLink = "https://apcargo.com.ph/#cargotracker";
    const dhlLink = `https://www.dhl.com/ph-en/home/tracking.html?tracking-id=${trackingCode}&submit=1`;
    const grabLink = "https://www.grab.com/ph/express/";
    const jtLink = "https://www.jtexpress.ph/index/query/gzquery.html";
    const jrsLink = "https://www.jrs-express.com/tracking/";
    const lalamoveLink = "https://www.lalamove.com/en-ph/";
    const lbcLink = "https://www.lbcexpress.com/track/";
    const ninjavanLink = `https://www.ninjavan.co/en-ph/tracking?id=${trackingCode}`;
    const upsLink = "https://www.ups.com/track?loc=en_PH&requester=ST/";
    const xdeLink = "https://www.xde.com.ph/track-trace";
    
    return(
        <>
            <button type="button" className="btn btn-primary btn-sm" style={{width: "120px"}} data-bs-toggle="modal" data-bs-target={"#edit-product-modal-" + index}>
                <small>Tracking Details</small>
            </button>

            <div className="modal fade" id={"edit-product-modal-" + index} tabIndex="-1" aria-labelledby={"edit-product-label-" + index} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5>Shipping Details</h5>
                        <button type="button" id={"btn-close-modal-" + index} className="btn-close btn-close-white btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <small>
                            <div className="row">
                                <strong>Delivery Name:</strong>
                            </div>
                            <div className="row lh-1">
                                <span>{ deliveryName }</span>
                            </div>
                            <div className="row mt-1">
                                <strong>Delivery Address:</strong>
                            </div>
                            <div className="row lh-1">
                                <span>{ deliveryAddress }</span>
                            </div>
                            <div className="row mt-1">
                                <strong>Contact Information:</strong>
                            </div>
                            <div className="row lh-1">
                            { deliveryPhone === null &&
                                <span>No contact details provided.</span>
                            }
                            { deliveryPhone === '' &&
                                <span>No contact details provided.</span>
                            }
                            { deliveryPhone !== '' &&
                                <span>{ deliveryPhone }</span>
                            }
                            </div>
                            <div className="row mt-1">
                                <strong>Delivery Method:</strong>
                            </div>
                            <div className="row lh-1">
                                <span>{ courier }</span>
                            </div>
                            { trackingCode !== "" &&
                                <>
                                    <div className="row mt-1">
                                        <strong>Tracking Code:<i class="bi bi-clipboard2"></i></strong>
                                    </div>
                                    <div className="row lh-1">
                                        <span>{ trackingCode } <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => {navigator.clipboard.writeText(trackingCode)}}><small>Copy to clipboard</small></button></span>
                                    </div>
                                    <div className="row mt-1">
                                        <strong>Courier Tracking:</strong>
                                    </div>
                                    <div className="row lh-1">
                                        { courier === '2GO Express' &&
                                            <a className="text-primary pt-2" rel="noopener noreferrer" href={ twoGoLink } target="_blank">https://supplychain.2go.com.ph</a>
                                        }
                                        { courier === 'Air 21' &&
                                             <a className="text-primary pt-2" rel="noopener noreferrer" href={ air21Link } target="_blank">https://www.air21.com.ph</a>
                                        }
                                        { courier === 'Airspeed' &&
                                            <a className="text-primary pt-2" rel="noopener noreferrer" href={ airSpeedLink } target="_blank">https://airspeed.ph</a>
                                        }
                                        { courier === 'AP Cargo' &&
                                            <a className="text-primary pt-2" rel="noopener noreferrer" href={ apCargoLink } target="_blank">https://apcargo.com.ph</a>
                                        }
                                        { courier === 'DHL' &&
                                            <a className="text-primary pt-2" rel="noopener noreferrer" href={ dhlLink } target="_blank">https://www.dhl.com</a>
                                        }
                                        { courier === 'Grab Express' &&
                                            <a className="text-primary pt-2" rel="noopener noreferrer" href={ grabLink } target="_blank">https://www.grab.com</a>
                                        }
                                        { courier === 'J&T Express' &&
                                            <a className="text-primary pt-2" rel="noopener noreferrer" href={ jtLink } target="_blank">Jhttps://www.jtexpress.ph</a>
                                        }
                                        { courier === 'JRS Express' &&
                                            <a className="text-primary pt-2" rel="noopener noreferrer" href={ jrsLink } target="_blank">https://www.jrs-express.com</a>
                                        }
                                        { courier === 'Lalamove' &&
                                            <a className="text-primary pt-2" rel="noopener noreferrer" href={ lalamoveLink } target="_blank">https://www.lalamove.com</a>                                            }
                                        { courier === 'LBC' &&
                                            <a className="text-primary pt-2" rel="noopener noreferrer" href={ lbcLink } target="_blank">https://www.lbcexpress.com</a>
                                        }
                                        { courier === 'Ninja Van' &&
                                            <a className="text-primary pt-2" rel="noopener noreferrer" href={ ninjavanLink } target="_blank">https://www.ninjavan.co</a>
                                        }
                                        { courier === 'UPS' &&
                                            <a className="text-primary pt-2" rel="noopener noreferrer" href={ upsLink } target="_blank">https://www.ups.com</a>
                                        }
                                        { courier === 'XDE Logistics' &&
                                            <a className="text-primary pt-2" rel="noopener noreferrer" href={ xdeLink } target="_blank">https://www.xde.com.ph</a>
                                        }
                                    </div>
                                </>
                            }
                            
                        </small>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><small>Close</small></button>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserOrderToReceiveDetails;