import { useState } from "react";

import useFetch from "../../useFetch";

import ExperienceAddEditDelete from "../reusable/services/ExperienceAddEditDelete";
import ReferenceAddEditDelete from "../reusable/services/ReferenceAddEditDelete";
import SkillAddEditDelete from "../reusable/services/SkillAddEditDelete";

const AddService = () => {
    const { data: categoryList } = useFetch('/api/services/category-list'); //get category list
    
    const [serviceName, setServiceName] = useState("");
    const [serviceDescr, setServiceDescr] = useState("");
    const [serviceCategory, setServiceCategory] = useState("Cleaning");
    const [currency, setCurrency] = useState("PHP");
    const [payRate, setPayRate] = useState("");
    const [unit, setUnit] = useState("");

    const [deductibleFees, setDeductibleFees] = useState("");
    //const [allowRefund, setAllowRefund] = useState(null);

    const [contractTerms, setContractTerms] = useState("");

    const [skills, setSkills] = useState([]);
    const [experiences, setExperiences] = useState([]);
    const [references, setReferences] = useState([]);

    const [descrCount, setDescrCount] = useState(0);
    const [contTermsCount, setContTermsCount] = useState(0);

    const [ isSaving, setIsSaving ] = useState(false);
    const [ errors, setErrors ] = useState([]);
    const [ message, setMessage ] = useState('');
    

    const descriptionCount = (descr) => {
        setServiceDescr(descr);
        setDescrCount(descr.length);
    }
    const contractTermsCount = (ct) => {
        setContractTerms(ct);
        setContTermsCount(ct.length);
    }

    const addSkill = (skill, level) => {
        const skill_json = {skill, level};
        setSkills([...skills, skill_json]); //push json to array
    }
    const handleRemoveSkill = (index) => setSkills(sks => sks.filter((s, i) => i !== index)); //remove skill

    const addExperience = (experience, years) => {
        const experience_json = {experience, years};
        setExperiences([...experiences, experience_json]); //push json to array
    }
    const handleRemoveExperience = (index) => setExperiences(exps => exps.filter((e, i) => i !== index)); //remove experience

    const addReference = (reference) => setReferences([...references, reference]); //push reference to array
    const handleRemoveReference = (index) => setReferences(refs => refs.filter((r, i) => i !== index)); //remove reference

    const handleSubmit = (e) => {
        e.preventDefault(); //prevent default page load

        if( serviceName !== "" && serviceDescr !== "" && serviceCategory !== "" && currency !== "" && payRate !== "" ) {

            const addServiceForm = document.getElementById('add-service-form');
            let formData = new FormData(addServiceForm);
            
            setIsSaving(true);

            fetch('/api/services-add', {
                method: 'POST',
                body: formData
            })
            .then( (result) => result.json() )
            .then( (result) =>  {
                if(result.successfulInsert) {
                    setMessage('You have successfully added a service.');
                    clearFields();
                    setErrors('');
                    window.scrollTo(0, 0); //scroll to top to see message
                } else if( Array.isArray(result) ) { //returned array of errors
                    setErrors(result);
                    setMessage('');
                    window.scrollTo(0, 0); //scroll to top to see message
                } else {
                    setErrors([{ err_msg: 'Unable to add a service.' }]);
                    setMessage('');
                    window.scrollTo(0, 0); //scroll to top to see message
                }
                setIsSaving(false);
            });
        }
        else {
            setErrors([{ err_msg: 'Fields marked with asterisk (*) are required.' }]);
            setMessage('');
            window.scrollTo(0, 0); //scroll to top to see message
        }
    }

    const clearFields = () => {
        setServiceName("");
        setServiceDescr("");
        setServiceCategory("Cleaning");
        setCurrency("PHP");
        setPayRate("");    
        setUnit("");
        setDeductibleFees("");
        setContractTerms("");
        setSkills([]);
        setExperiences([]);
        setReferences([]);

        setDescrCount(0);
        setContTermsCount(0);
    }


    return (
        <div className="add-service services d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Add Service</h3>
            
            <div className="d-flex justify-content-center mt-2">
                <div className="user-form p-4 border rounded">

                    { errors &&
                        errors.map( (e) => (
                            <div className="error mb-2" key={e.err_msg}>
                                <span>{e.err_msg}</span>
                            </div>
                        ) )
                    }
                    { message &&
                        <div className="success-message mb-2">
                            <span>{message}</span>
                        </div>
                    }

                    <form id="add-service-form" onSubmit={handleSubmit} encType="multipart/form-data">
                        
                        <div className="mt-2">
                            <label htmlFor="name">Service name <span className="text-danger"><strong>*</strong></span></label>
                            <input id="name" name="name" type="text" className="form-control input-text" maxLength="500" value={ serviceName } onChange={ (e) => setServiceName(e.target.value) } required />
                        </div>{/* name */}

                        <div className="mt-2">
                            <div className="d-flex justify-content-between">
                                <label htmlFor="description">Description <span className="text-danger"><strong>*</strong></span></label>
                                <p>{descrCount}/2000</p>
                            </div>
                            <textarea id="description" name="description" className="form-control" rows="2" maxLength="2000" value={ serviceDescr } onChange={ (e) => descriptionCount(e.target.value) } required></textarea>
                        </div>{/* description */}

                        <div className="mt-2">
                            <label htmlFor="category1">Service Category <span className="text-danger"><strong>*</strong></span></label>
                            <select id="category1" name="category1" className="form-select" aria-label="Choose service category" value={ serviceCategory } onChange={ (e) => setServiceCategory(e.target.value) } required>
                                { categoryList && categoryList.map( (category, i) => (
                                    <option key={ i }>{category.descr}</option>
                                ) ) }
                            </select>
                        </div>{/* category */}

                        <div>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="mt-2 mr-md-2 flex-fill">
                                    <label htmlFor="currency">Currency <span className="text-danger"><strong>*</strong></span></label>
                                    <select id="currency" name="currency" className="form-select" value={ currency } onChange={ (e) => setCurrency(e.target.value) } required>
                                        <option value="PHP">PHP</option>
                                        <option value="USD">USD</option>
                                    </select>
                                </div>
                                <div className="mt-2 mr-md-2 flex-fill">
                                    <label htmlFor="payRate">Pay Rate <span className="text-danger"><strong>*</strong></span></label>
                                    <input id="payRate" name="payRate" type="number" aria-label="product-price" className="form-control" value={ payRate } onChange={ (e) => setPayRate(e.target.value) } required/>
                                </div>
                                <div className="mt-2 flex-fill">
                                    <label htmlFor="unit">Unit <span className="text-danger"><strong>*</strong></span></label>
                                    <select id="unit" name="unit" className="form-select" required>
                                        <option value="per hour">per hour</option>
                                        <option value="per day">per day</option>
                                        <option value="per week">per week</option>
                                        <option value="per month">per month</option>
                                        <option value="per year">per year</option>
                                        <option value="per project">per project</option>
                                        <option value="one time">one time</option>
                                    </select>
                                </div>
                            </div>
                        </div>{/* rate */}

                        <div className="mt-2">
                            <label htmlFor="profilePicture">Profile picture (personal or logo)</label>
                            <input id="profilePicture" name="profilePicture" className="form-control" type="file" accept=".jpeg,.jpg,.png" />
                        </div>{/* profile picture */}

                        <div>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="mt-2 mr-md-2 flex-fill">
                                    <label htmlFor="city">Preferred City</label>
                                    <input id="city" name="city" type="text" placeholder="Town or City" className="form-control" />
                                </div>
                                <div className="mt-2 mr-md-2 flex-fill">
                                    <label htmlFor="province">Preferred Province</label>
                                    <input id="province" name="province" type="text" placeholder="State or Province" className="form-control" />
                                </div>
                                <div className="mt-2 flex-fill">
                                    <label htmlFor="country">Preferred Country</label>
                                    <input id="country" name="country" type="text" placeholder="Country" className="form-control" />
                                </div>
                            </div>
                        </div>{/* preferred location */}

                        <div className="mt-2">
                            <label htmlFor="timeline">Timeline or deadline</label>
                            <input id="timeline" name="timeline" type="text" className="form-control input-text" maxLength="500" />
                        </div>{/* timeline */}

                        <div className="mt-2">
                            <div className="d-flex justify-content-between">
                                <label htmlFor="contract_terms">Contract terms</label>
                                <p>{contTermsCount}/2000</p>
                            </div>
                            <textarea id="contract_terms" name="contract_terms" className="form-control" rows="2" maxLength="2000" value={ contractTerms } onChange={ (e) => contractTermsCount(e.target.value) }></textarea>
                        </div>{/* contract terms */}

                        <div className="user-form p-3 border rounded mt-3">
                            <label htmlFor="deductibleFees">Deductible fees</label>
                            <select id="deductibleFees" name="deductibleFees" className="form-select" value={deductibleFees} onChange={ (e) => setDeductibleFees(e.target.value) }>
                                <option value=""></option>
                                <option value="Advance fee">Advance fee/Down payment</option>
                                <option value="Reservation fee">Reservation fee</option>
                            </select>
                            { deductibleFees && 
                                <div className="mt-2">
                                    <div className="d-flex justify-content-between">
                                        <label htmlFor="feeAmount">Fee</label>
                                        { deductibleFees === 'Reservation fee' && 
                                            <div class="form-check">
                                                <input id="allow_refund" name="allow_refund" type="checkbox" class="form-check-input" style={{width: '1em'}} />
                                                <label class="form-check-label" for="allow_refund">
                                                    Allow refund
                                                </label>
                                            </div>
                                        }
                                    </div>
                                    <input id="fee_amount" name="fee_amount" type="number" placeholder="0" className="form-control" />
                                </div>
                            }
                        </div>{/* deductible fees */}

                        <div className="user-form p-3 border rounded mt-3">
                            <label>Portfilio images (photos of your best work)</label>
                            <input id="portfolioImages" name="portfolioImages" className="form-control" type="file" accept=".jpeg,.jpg,.png" multiple />
                            <span className="font-italic font-size-point8rem">You may upload up to three (3) images</span>
                        </div>{/* portfolio images */}

                        <div className="user-form p-3 border rounded mt-3">
                            <label>Skills</label>
                            { skills.length < 5 &&
                                <SkillAddEditDelete action="add" addSkill={addSkill} />
                            }
                            
                            { skills && skills.map( (s, i) => (
                                <div class="alert alert-warning alert-dismissible fade show" role="alert" key={i}>
                                    <span className="mr-2">{s.skill}</span>
                                    <span class="badge bg-danger">{s.level}</span>
                                    <button type="button" class="btn-close" aria-label="Close" onClick={ () => handleRemoveSkill(i) }></button>

                                    <input type="hidden" name="skills[][skill]" value={ s.skill } />
                                    <input type="hidden" name="skills[][level]" value={ s.level } />
                                </div>
                            ) ) }

                        </div>{/* skills */}
                        
                        <div className="user-form p-3 border rounded mt-3">
                            <label>Experiences</label>
                            { experiences.length < 5 &&
                                <ExperienceAddEditDelete action="add" addExperience={addExperience} />
                            }

                            { experiences && experiences.map( (e, i) => (
                                <div class="alert alert-warning alert-dismissible fade show" role="alert" key={i}>
                                    <span className="mr-2">{e.experience}</span>
                                    <span class="badge bg-danger">{e.years}</span>
                                    <button type="button" class="btn-close" aria-label="Close" onClick={ () => handleRemoveExperience(i) }></button>
                                    
                                    <input type="hidden" name="experiences[][experience]" value={ e.experience } />
                                    <input type="hidden" name="experiences[][years]" value={ e.years } />
                                </div>
                            ) ) }
                        </div>{/* experiences */}

                        <div className="user-form p-3 border rounded mt-3">
                            <label>References</label>
                            { references.length < 5 &&
                                <ReferenceAddEditDelete action="add" addReference={addReference} />
                            }
                            
                            { references && references.map( (r, i) => (
                                <div class="alert alert-warning alert-dismissible fade show" role="alert" key={i}>
                                    <span className="mr-2">{r}</span>
                                    <button type="button" class="btn-close" aria-label="Close" onClick={ () => handleRemoveReference(i) }></button>

                                    <input type="hidden" name="references[]" value={ r } />
                                </div>
                            ) ) }
                        </div>{/* references */}

                        { !isSaving && 
                            <button id="btn-submit" type="submit" className="btn btn-primary mt-3">
                                <i className="fas fa-tools mx-2"></i> Add Service
                            </button>
                        }
                        { isSaving &&
                            <button type="submit" className="btn btn-primary mt-3" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving
                            </button>
                        }
                    </form>
                </div>

            </div>
        </div>
    );
}

export default AddService;