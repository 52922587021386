import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import isStrongPassword from 'validator/lib/isStrongPassword';

const ResetPasswordSave = ({ handleSetSignInMessage, handleSetSignInMessageShown }) => {
    const { code } = useParams();
    const history = useHistory();

    const [ questions, setQuestions ] = useState([]);
    const [ answers, setAnswers ] = useState(['', '', '']);

    const [ newPass, setNewPass ] = useState('');
    const [ newPass2, setNewPass2 ] = useState('');
    const [ passStrength, setPassStrength ] = useState(null);
    const [ isMatch, setIsMatch ] = useState(null);

    const [ errors, setErrors ] = useState([]);
    const [ isSaving, setIsSaving ] = useState('');

    useEffect( () => {
        fetch(`/api/reset-password-verify?code=${code}`)
            .then( (result) => result.json() )
            .then( (result) => {
                if( 'isCodeValid' in result && result.isCodeValid ) { //valid code
                    setQuestions( result.secQuestions );
                }
                else history.push('/'); //invalid code
            })
        ;
    }, [] );

    const handleSubmit = (e) => {
        e.preventDefault(); //will prevent default page load
        
        setErrors([]); //empty errors array
        
        if( newPass !== "" && newPass2 !== "" && answers[0] !== "" && answers[1] !== "" && answers[2] !== "" ) { //no empty fields
            if( passStrength && isMatch ) { //passwords match and strong
                setIsSaving(true);
    
                const data = { newPass, newPass2, answers };
                
                fetch(`/api/reset-password-save?code=${code}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                })
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( 'errors' in result && Array.isArray(result.errors) ) setErrors(result.errors);
                    else if( 'successfulUpdate' in result && result.successfulUpdate ) {
                        //successful update
                        handleSetSignInMessage('Your password has been reset successfully. You can now use your new password to sign in.');
                        handleSetSignInMessageShown(false);
                        history.push('/signin'); //redirect to sign in
                    }
                    else if( 'isCodeValid' in result && !result.isCodeValid ) history.push('/'); //invalid code
                    else setErrors([{ err_msg: 'Unable to change your password.' }]);

                    setIsSaving(false);
                });
            }
        } else {
            setErrors([{ err_msg: 'All fields are required.' }]);
        }
    }
    const handleNewPasswordChange = (e) => {
        const pass = e.target.value;
        setNewPass(pass);
        if( pass.length > 0 ) setPassStrength( isStrongPassword(pass) ); //set password strength
        else setPassStrength(null); //set to null if password is empty

        if( newPass2 === pass ) setIsMatch(true); //confirmation password matches new password
        else setIsMatch(false); //does not match
    }
    const handleConfirmNewPasswordChange = (e) => {
        const pass = e.target.value;
        setNewPass2(pass);
        if( pass.length === 0 ) setIsMatch(null); //set back to null if empty
        else if( newPass === pass ) setIsMatch(true); //confirmation password matches new password
        else setIsMatch(false); //does not match
    }
    const handleTextChange = (e, i) => {
        if( i === 0 ) setAnswers([ e.target.value, answers[1], answers[2] ]);
        else if( i === 1 ) setAnswers([ answers[0], e.target.value, answers[2] ]);
        else if( i === 2 ) setAnswers([ answers[0], answers[1], e.target.value ]);
    }

    return (
        <div className="reset-password-code d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Reset Password</h3>
            <div className="d-flex justify-content-center mt-2">
                <div className="reset-password-form p-4 border rounded">
                    <h5 className="mb-3">
                        Please enter your new password along with the answers to your chosen security questions.
                    </h5>

                    { errors &&
                        errors.map( (e) => (
                            <div className="error mb-2" key={e.err_msg}>
                                <span>{e.err_msg}</span>
                            </div>
                        ) )
                    }
                    
                    { questions &&
                        <form onSubmit={ handleSubmit }>
                            <div className="d-flex flex-column my-2">
                                <label htmlFor="new-password">
                                    <span className="mr-2">New Password</span>
                                    { passStrength !== null && passStrength && <span className="pass-strength strong">(Your password is strong)</span> }
                                    { passStrength !== null && !passStrength && <span className="pass-strength weak">(Your password is weak)</span> }
                                </label>
                                <input type="password" id="new-password" className="input-text" value={ newPass } onChange={handleNewPasswordChange} />
                            </div>
                            <div className="d-flex flex-column my-2">
                                <label htmlFor="confirm-new-password">
                                    <span className="mr-2">Confirm New Password</span>
                                    { !isMatch && newPass2.length > 0 && <span className="pass-not-match">(Passwords do not match)</span> }
                                </label>
                                <input type="password" id="confirm-new-password" className="input-text" value={ newPass2 } onChange={handleConfirmNewPasswordChange} />
                            </div>

                            {
                                questions.map( (q, i) => (
                                    <div className="d-flex flex-column my-2 mb-2" key={q.trans_code}>
                                        <span className="mr-2">Q{i+1}: {q.trans_code}</span>
                                        <input type="text" value={answers[i]} onChange={ e => handleTextChange(e, i) } placeholder={ "Answer " + (i + 1) } className="input-text" />
                                    </div>
                                ) )
                            }

                            <div className="mt-3">
                                { !isSaving && 
                                    <button className="btn btn-primary">
                                        <i className="bi bi-save"></i> Save Password
                                    </button> 
                                }
                                { isSaving && 
                                    <button className="btn btn-primary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving ...
                                    </button>
                                }
                            </div>
                        </form>
                    }
                </div>
            </div>
        </div>
    );
}
 
export default ResetPasswordSave;