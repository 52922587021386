import { useState } from "react";

const ReferenceAddEditDelete = ({ action, addReference }) => {
    const [reference, setReference] = useState("");

    const handleAdd = (e) => {
        e.preventDefault(); //will prevent default page load

        if( reference !== "" ) {
            addReference(reference);
            setReference("");
            document.getElementById("add-reference-close-modal").click(); //close the modal
        }
    }

    return (
        <>
            { action === 'add' &&
                <>
                    <button type="button" className="btn add" data-bs-toggle="modal" data-bs-target="#modal-add-new-reference" title="Add new reference">
                        <i className="bi bi-plus-circle-fill" style={{color: '#FFD700'}}></i>
                    </button>

                    <div className="modal" id="modal-add-new-reference" tabIndex="-1" aria-labelledby="modal-add-new-reference-title" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header mobile-modal-header">
                                    <h5 className="modal-title" id="modal-add-new-reference-title">Add a Reference</h5>
                                    <button type="button" id="add-reference-close-modal" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body p-4">
                                    <input type="text" className="form-control mb-2" placeholder="Input reference" value={reference} onChange={ (e) => setReference(e.target.value) } />
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-primary" style={{ maxWidth: '200px' }} onClick={handleAdd}>
                                        <i className="bi bi-plus-circle-fill"></i> Add Reference
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}
 
export default ReferenceAddEditDelete;