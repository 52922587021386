const UserSCartWListFavoritesDelete = ({ deleteFrom, product, service_id, triggerRerender }) => {

    const handleDeleteFromShoppingCart = () => {
        if( window.confirm("Are you sure you want to remove this product from your shopping cart?") ) {
            fetch(`/api/product/remove-from-shopping-cart/${product.product_id}`)
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( 'isRemoved' in result && result.isRemoved ) { //successfully removed
                        //see if checkbox is checked
                        const cbox = document.getElementById(`cbox-${product.product_id}`);
                        if( cbox.checked ) { 
                            cbox.click(); //simulate checkbox click to uncheck product and remove from total amount & items
                        }

                        triggerRerender(); //trigger rerender of parent component
                    }
                    else { //unsuccessful remove from wish list
                        alert("Something went wrong!");
                    }
                })
            ;
        }
    }

    const handleDeleteFromWishlist = () => {
        if( window.confirm("Are you sure you want to remove this product from your wish list?") ) {
            fetch(`/api/product/remove-from-wish-list/${product.product_id}`)
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( 'isRemoved' in result && result.isRemoved ) { //successfully removed
                        triggerRerender(); //trigger rerender of parent component
                    }
                    else { //unsuccessful remove from wish list
                        alert("Something went wrong!");
                    }
                })
            ;
        }
    }

    const handleDeleteFromFavorites = () => {
        if( window.confirm("Are you sure you want to remove this service from your favorites?") ) {
            fetch(`/api/service/remove-from-favorites/${service_id}`)
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( 'isRemoved' in result && result.isRemoved ) { //successfully removed
                        triggerRerender(); //trigger rerender of parent component
                    }
                    else { //unsuccessful remove from wish list
                        alert("Something went wrong!");
                    }
                })
            ;
        }
    }

    return (
        <div>
            { deleteFrom === "shopping-cart" &&
                <button type="button" className="btn btn-remove" onClick={handleDeleteFromShoppingCart} title="Remove">
                    <i className="bi bi-trash"></i>
                </button>
            }
            { deleteFrom === "wish-list" &&
                <button type="button" className="btn btn-remove" onClick={handleDeleteFromWishlist} title="Remove">
                    <i className="bi bi-trash"></i>
                </button>
            }
            { deleteFrom === "favorites" &&
                <button type="button" className="btn btn-remove" onClick={handleDeleteFromFavorites} title="Remove">
                    <i className="bi bi-trash"></i>
                </button>
            }
        </div>
    );
}
 
export default UserSCartWListFavoritesDelete;