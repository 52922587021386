import { useState, useEffect } from "react";

import UserShipReturnItem from "./UserShipReturnItem";
import UserReturnsTrackingDetails from "./UserReturnsTrackingDetails";

const UserReturns = () => {
    const [returns, setReturns] = useState([]);
    const [rerenderToggle, setRerenderToggle] = useState(true); //used to trigger rerender after processing orders

    useEffect(() => {
        fetch('/api/user-returns')
            .then( res => { return res.json(); } )
            .then( data => { setReturns(data); } );
    }, [rerenderToggle] );

    const triggerRerender = () => {
        setRerenderToggle( !rerenderToggle ); //set to opposite boolean value, just so state is changed and would trigger rerender
    }

    return(
        <div className="d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Returns</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="user-order p-3 border rounded">
                    {returns.length === 0 &&
                        <p className="text-center">No returns found.</p>
                    }
                    {returns.length > 0 &&
                        <>
                            { returns.map( ( returns, index ) => (
                                <div className="p-2 border border-secondary rounded-3 mb-2" key={index}>
                                    <small>
                                        Order # <span className="fw-bold mr-3">{returns.order_id}</span><br/>
                                        Return Date: <span className="fw-bold">{returns.return_date}</span>
                                        <div className="p-2 border border-secondary mb-2">
                                            <div className="p-1">
                                                <img src={returns.return_thumbnail} className="rounded float-start product-thumb ml-1 mr-2" alt={returns.return_product + " image"} title={returns.return_product} />
                                                <span>{returns.return_product}</span>
                                            </div>
                                            <div className="row mt-3">
                                                <span className="ml-1"><strong>Reason:</strong> {returns.return_reason}</span>
                                                <span className="ml-1"><strong>Description:</strong> {returns.return_description}</span>
                                            </div>
                                        </div>
                                            {returns.return_status === 'received' &&
                                                <><strong>Status: </strong><span className="badge bg-success">Item Received by Seller</span></>
                                            }
                                            {returns.return_status === 'shipped' &&
                                                <><strong>Status: </strong><span className="badge bg-info text-dark">Item shipped to seller</span></>
                                            }
                                            {returns.return_status === 'approved' &&
                                                <>
                                                    { returns.return_reason !== 'Item not received' &&
                                                        <UserShipReturnItem returns={returns} index={returns.return_id} triggerRerender={triggerRerender} />
                                                    }
                                                    { returns.return_reason === 'Item not received' &&
                                                        <><strong>Status: </strong><span className="badge bg-success">Return/Refund request approved</span></>
                                                    }
                                                </>
                                            }
                                            { returns.return_status === 'submitted' &&
                                                <><strong>Status: </strong><span className="badge bg-light text-dark">Return/Refund request submitted</span></>
                                            }
                                            { returns.return_status === 'declined' &&
                                                <>
                                                    { returns.decline_reason === 'I will ship missing item/accessory' &&
                                                        <>
                                                            <strong>Status: </strong> Missing Item/Accessory has been shipped by Seller<br />
                                                            <UserReturnsTrackingDetails returnID={returns.return_id} index={returns.return_id} />
                                                        </>
                                                    }
                                                    { returns.decline_reason === 'Updated tracking details' &&
                                                        <>
                                                            <strong>Status: </strong><span className="badge bg-warning text-dark">Tracking Details have been updated</span> Please click "Tracking Details" for more info.<br />
                                                            <UserReturnsTrackingDetails returnID={returns.return_id} index={returns.return_id} />
                                                        </>
                                                    }
                                                    { returns.decline_reason === 'Shipped product still in transit' &&
                                                        <>
                                                            <strong>Status: </strong><span className="badge bg-warning text-dark">Return/Refund request declined</span><br />
                                                            <strong>Reason: </strong>The shipped product is still in transit.
                                                        </>
                                                    }
                                                    { returns.decline_reason === 'Issue is specified in product page' &&
                                                        <>
                                                            <strong>Status: </strong><span className="badge bg-warning text-dark">Return/Refund request declined</span><br />
                                                            <strong>Reason: </strong>The issue is specified in product description.
                                                        </>
                                                    }
                                                    { returns.decline_reason === 'Product for parts out only' &&
                                                        <>
                                                            <strong>Status: </strong><span className="badge bg-warning text-dark">Return/Refund request declined</span><br />
                                                            <strong>Reason: </strong>As specified in product description, the sold item is for spare parts only and is not sold as functional.
                                                        </>
                                                    }
                                                </>
                                            }
                                    </small>
                                </div>
                            ) ) }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default UserReturns;