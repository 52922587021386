import { Link } from "react-router-dom";

import SearchForm from "./SearchForm";

import defaultProfileImg from '../../../img/default-profile.png';

const Footer = ({ page, path, isAuthenticated, firstName, profilePicture, isProd, searchFor="product" }) => {
    

    return (
        <div className="mobile-footer d-flex justify-content-around">
            <Link to={ path } className={ page === 'home' ? 'active' : '' }>
                <i className="bi bi-house-fill"></i>
            </Link>

            <SearchForm path={path} sFor={searchFor} />

            {/* { !isProd &&  */}
                <Link to={ path + 'services/menu' } className={ page === 'services-menu' ? 'active' : '' }>
                    <i class='fas fa-tools'></i>
                </Link>
            {/* } */}
            

            <Link to={ path + 'legal-menu' } className={ page === 'legal-menu' ? 'active' : '' }>
                <i class="bi bi-list"></i>
            </Link>            

            <Link to={ path + 'user/wish-list-and-favorites' } className={ page === 'user-wish-list-and-favorites' ? 'active' : '' }>
                <i className="bi bi-star"></i>
            </Link>
            <Link to={ path + 'user/shopping-cart' } className={ page === 'user-shopping-cart' ? 'active' : '' }>
                <i className="bi bi-cart4"></i>
            </Link>

            { isAuthenticated && <>
                <Link to={ path + 'seller-center/menu' } className={ page === 'seller-menu' ? 'active' : '' }>
                    <i class="bi bi-shop-window"></i>
                </Link>

                <Link to={ path + 'user/menu' } className={ page === 'user-menu' ? 'active' : '' }>
                    { profilePicture && <img src={profilePicture} alt={firstName} className="profile-pic rounded-circle" /> }
                    { !profilePicture && <img src={defaultProfileImg} alt={firstName} className="profile-pic rounded-circle" /> }
                </Link>

                {/* <Link to={ path + 'signout' }>
                    <i class="bi bi-box-arrow-right"></i>
                </Link> */}
            </> }

            { !isAuthenticated && <>
                <Link to={ path + 'register' } className={ page === 'register' ? 'active' : '' }>
                    <i className="bi bi-person-lines-fill"></i>
                </Link>
                <Link to={ path + 'signin' } className={ page === 'signin' ? 'active' : '' }>
                    <i className="bi bi-box-arrow-in-right"></i>
                </Link>
            </> }

            
        </div>
    );
}
 
export default Footer;