import { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import queryString from 'query-string';

// import imgFeatured001 from '../img/featured-001.jpg';

const Search = ({ path }) => {
    const history = useHistory();

    let queries = queryString.parse( useLocation().search ); //query string

    const [ searchKeywords, setSearchKeywords ] = useState('');
    const [ searchCategories, setSearchCategories ] = useState('');
    const [ products, setProducts ] = useState([]);
    
    const [ numberOfPages, setNumberOfPages ] = useState(0);
    const [ currentPage, setCurrentPage ] = useState(1);
    
    const [ isLoading, setIsLoading ] = useState(false);

    if( searchKeywords !== queries.keyword ) setSearchKeywords(queries.keyword);
    if( searchCategories !== queries.category ) setSearchCategories(queries.category);

    useEffect( () => {
        setIsLoading(true);

        const k = queries.keyword;
        const c = queries.category;
        const p = Number.isInteger( parseInt(queries.page) ) ? parseInt(queries.page) : 1;

        fetch(`/api/search?k=${encodeURIComponent(k)}&c=${encodeURIComponent(c)}&p=${p}`)
            .then( res => { return res.json(); } )
            .then( result => { 
                if( 'products' in result && Array.isArray(result.products) ) { //if returned an array of results
                    setProducts(result.products);

                    setIsLoading(false);
                }

                if( 'num_of_pages' in result ) setNumberOfPages( result.num_of_pages ); //set number of pages
            })
        ;
    }, [searchKeywords, searchCategories, currentPage] );

    //set current page on first load or when keyword/category changes
    useEffect( () => {        
        const p = Number.isInteger( parseInt(queries.page) ) ? parseInt(queries.page) : 1;
        setCurrentPage(p);
    }, [searchKeywords, searchCategories] );

    const handleNextButtonClick = (btnClicked, e) => {
        e.preventDefault(); //prevent page load
        
        let cp = currentPage;

        if( btnClicked === 'next' ) cp = cp + 1;
        else if( btnClicked === 'previous' ) cp = cp - 1;
        
        history.push(`${path}search?keyword=${encodeURIComponent(searchKeywords)}&category=${encodeURIComponent(searchCategories)}&page=${cp}`);
        setCurrentPage(cp);
    }
    
    return (
        <div className="search mobile-container d-flex flex-column mt-3">
            { !isLoading && 
                <div className="d-flex flex-column mt-3 mx-3">
                    { products && products.length > 0 &&
                        <div className="d-flex flex-column">
                            <h4 className="d-flex justify-content-center mb-3">
                                { searchKeywords && <span>Search result for <strong>{searchKeywords}</strong></span>}
                                { !searchKeywords && <span>Search result for <strong>{searchCategories}</strong></span>}
                            </h4>

                            <div className="d-flex flex-wrap justify-content-between">
                                { products.map( (p) => (
                                    <Link to={`${path}product/` + p.product_id} className="prod-link my-2" key={p.product_id}>
                                        <div className="product" style={{height: '100%'}}>
                                            <img src={p.prod_image} alt="" className="src mb-2" />
                                            <span>{ p.prod_name.slice(0, 20) } { p.prod_name.length > 20 && '...' }</span>
                                            <br />
                                            <span>{p.prod_currency}</span> <span>{p.prod_price_with_fees.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </Link>
                                ) ) }
                            </div>

                            <div className="d-flex justify-content-center">
                                { numberOfPages > 1 && currentPage > 1 && currentPage <= numberOfPages && 
                                    <div className="d-flex mt-3 mx-2">
                                        <Link onClick={ (e) => handleNextButtonClick('previous', e) }>
                                            <h3><i class="bi bi-arrow-left-circle-fill"></i></h3>
                                        </Link>
                                    </div>
                                }
                                { numberOfPages > 1 && currentPage < numberOfPages && currentPage > 0 &&
                                    <div className="d-flex mt-3 mx-2">
                                        <Link onClick={ (e) => handleNextButtonClick('next', e) }>
                                            <h3><i class="bi bi-arrow-right-circle-fill"></i></h3>
                                        </Link>
                                    </div>
                                }
                            </div>
                            
                        </div>
                        
                    }
                    { products && products.length === 0 &&
                        <h4 className="d-flex justify-content-center my-5">
                            { searchKeywords && <span>No products found for <strong>{searchKeywords}</strong></span>}
                            { !searchKeywords && <span>No products found for <strong>{searchCategories}</strong></span>}
                        </h4>
                    }
                </div>
                
            }
            { isLoading && 
                <div>
                    <div className="d-flex justify-content-center my-5">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>                    
                </div>
            }
        </div>
    );
}
 
export default Search;