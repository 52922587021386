import { useState,useEffect } from "react";

import "../../css/mobile/styles.css";

const OrderItems = ({orders, index, triggerRerender}) => {
    const [orderItems, setOrderItems] = useState([]);
    const [orderID] = useState(orders.order_id);

    useEffect(() => {
        fetch(`/api/seller-center-order-items/${orderID}`)
            .then( res => { return res.json(); } )
            .then( data => { setOrderItems(data); } );
    }, [] );

    return(
        <>
            {orderItems.length === 0 &&
                <p className="text-center">No order items found.</p>
            }
            {orderItems.length > 0 &&
                <>
                    { orderItems.map( ( orders, index ) => (
                        <div key={index}>
                            <div className="p-1 pb-2">
                                <img src={orders.order_thumbnail} className="rounded float-start product-thumb ml-2 mr-2" alt={orders.order_name + " image"} title={orders.order_name} />
                                <span className="title-limit">{orders.order_name}</span> <br /><span className="fw-light">{orders.order_currency} {orders.order_amount_base_price}</span>
                            </div>
                        </div>
                    ) ) }
                </>
            }         
        </>
    )
}

export default OrderItems;