import { useState } from "react";

const SellerCenterReceiveReturns = ({returns, triggerRerender}) => {
    const [isPending, setIsPending] = useState(false);

    const handleReceive = (e) => {
        e.preventDefault(); //will prevent default page load

        setIsPending(true);

        if( window.confirm("I confirm I received return item from the customer.") ) {
            fetch(`/api/seller-center/receive-returns/${returns.return_id}`)
            .then( (result) => result.json() )
            .then( (result) =>  {
                if( 'successfulUpdate' in result && result.successfulUpdate ) { //successful insert
                    setIsPending(false);
                    // triggerRerender(); //trigger rerender of parent component
                    window.location.href = "/seller-center/return-refund-requests"
                }
                else { //unsuccessful update
                     alert("Something went wrong!");
                }
            });
        } else {
            setIsPending(false);
        };
    }

    return(
        <>
            { !isPending && 
                <button type="button" className="btn btn-warning btn-sm mr-2" onClick={handleReceive}>
                    <small>Return Item Received</small>
                </button>
            }
            { isPending && 
                <button className="mr-2 btn btn-warning btn-sm" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                </button>
            }
        </>
    );
}

export default SellerCenterReceiveReturns;