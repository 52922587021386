import { useEffect } from "react";
import { Redirect } from 'react-router-dom';

const EnsureAuthenticated = ({ isAuthenticated, recheckAuthentication, path }) => {
    useEffect( () => recheckAuthentication(), [] ); //trigger to recheck authentication

    if( isAuthenticated === false ) return <Redirect to={ path + 'signin' } />;

    return null;
}
 
export default EnsureAuthenticated;