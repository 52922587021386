import { useState } from "react";

const SellerCenterReceiveReturns = ({returns, triggerRerender}) => {
    const [isPending, setIsPending] = useState(false);

    const handleAcceptRefund = (e) => {
        e.preventDefault(); //will prevent default page load

        setIsPending(true);

        const returnDetails = {
            return_id: returns.return_id,
            product: returns.return_product,
            return_reason: returns.return_reason
        }

        if( window.confirm("I accept the customer return request and am willing to refund.") ) {
            // fetch(`/api/seller-center/refund-approve/${returns.return_id}`)
            // .then( (result) => result.json() )
            // .then( (result) =>  {
                fetch(`/paypal/seller-refund/${returns.sales_order_id}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(returnDetails)
                })
                .then( (result) => result.json() )
                .then( (result) =>  {
                if( 'successfulUpdate' in result && result.successfulUpdate ) { //successful insert
                    setIsPending(false);
                    // triggerRerender(); //trigger rerender of parent component
                    window.location.href = "/seller-center/return-refund-requests";
                }
                else { //unsuccessful update
                    setIsPending(false);
                    alert("Something went wrong!");
                    window.location.href = "/seller-center/return-refund-requests";
                }
            });
        } else {
            setIsPending(false);
        };
    }

    return(
        <>
            { !isPending && 
            <button type="button" className="mr-2 btn btn-success btn-sm" style={{width: "130px", height: "35px"}} onClick={handleAcceptRefund}>
                <small>Accept</small>
            </button>
            }
            { isPending && 
                <button className="mr-2 btn btn-warning btn-sm" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                </button>
            }
        </>
    );
}

export default SellerCenterReceiveReturns;