import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import Comments from "../reusable/services/Comments";
import TextAreaCounter from '../reusable/TextAreaCounter';

const ProviderBookingRequests = ({ profilePicture }) => {
    const [ bookings, setBookings ] = useState([]);
    const [ acceptDeclineBookingID, setAcceptDeclineBookingID ] = useState(null);
    const [ acceptOrDecline, setAcceptOrDecline ] = useState(null);
    const [ reason, setReason ] = useState("");

    const [ reloadListToggle, setReloadListToggle ] = useState(true);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isSaving, setIsSaving ] = useState(false);

    useEffect( () => {
        setIsLoading(true);

        fetch(`/api/service-provider-requests`)
            .then( res => { 
                if(res.ok) return res.json();
                throw new Error('No service found.');
            }).then( data => {
                if(data) { //check if data is not null
                    
                    setBookings(data);
                    setIsLoading(false);
                    
                } else throw new Error('No service found.'); //data is null, send error
            }).catch( (e) => {
                alert(e.message);
            })
        ;
    }, [reloadListToggle] );

    const handleAccept = (service_booking_id, client_name) => {
        setAcceptOrDecline('accept');
        setAcceptDeclineBookingID( service_booking_id );
        setShowDeclineMessage(`Are you sure you want to accept this service request from ${client_name}?`);
    }

    const handleDecline = (service_booking_id, client_name) => {
        setAcceptOrDecline('decline');
        setAcceptDeclineBookingID( service_booking_id );
        setShowDeclineMessage(`Are you sure you want to decline this service request from ${client_name}?`);
    }

    const handleCancel = () => {
        setAcceptDeclineBookingID(null);
        setShowDeclineMessage(null);
    }

    const handleProceedAccept = () => {
        setIsSaving(true);

        const data = { bid: acceptDeclineBookingID };

        fetch('/api/service-provider-request-accept', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then( (result) => result.json() )
        .then( (result) =>  {
            if( 'successfulUpdate' in result && result.successfulUpdate ) {
                setReloadListToggle( !reloadListToggle ); //set to opposite value, to reload booking list

                setIsSaving(false);
                setAcceptDeclineBookingID(null);
                setAcceptOrDecline(null);
                setShowDeclineMessage(null);
            } else alert("Unable to process request.");
        });
    }

    const handleProceedDecline = () => {
        setIsSaving(true);

        const data = { bid: acceptDeclineBookingID, reason };

        fetch('/api/service-provider-request-decline', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then( (result) => result.json() )
        .then( (result) =>  {
            if( 'successfulUpdate' in result && result.successfulUpdate ) {
                setReloadListToggle( !reloadListToggle ); //set to opposite value, to reload booking list

                setIsSaving(false);
                setAcceptDeclineBookingID(null);
                setReason("");
                setAcceptOrDecline(null);
                setShowDeclineMessage(null);
            } else alert("Unable to process request.");
        });
    }

    const [ showDeclineMessage, setShowDeclineMessage ] = useState(null);
    

    return (
        <div className="provider-booking-requests book-service service-details services d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center mb-3">Booking Requests</h3>

            { isLoading && 
                <div className="d-flex justify-content-center user-form rounded py-4">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </div>
            }

            { !isLoading && bookings.length === 0 &&
                <div className="d-flex justify-content-center user-form rounded py-4">
                    No available requests.
                </div>
            }

            { !isLoading && bookings && bookings.length > 0 && bookings.map( (b, i) => (
                <div key={b.service_booking_id} className="service-details w-100">
                    <div className="alert" role="alert">
                        <div>
                            <div className="mb-1 font-color-gold"><b>{b.serv_name}</b></div>
                        </div>

                        <h6>
                            {b.serv_payment_currency} {b.serv_payment_rate} ({b.serv_payment_unit})
                        </h6>

                        <h6>Request date: { b.booking_update_dt_mdy && <>{b.booking_update_dt_mdy}</> }
                            { !b.booking_update_dt_mdy && <>{b.booking_insert_dt}</> }
                        </h6>

                        <hr />

                        <div className="d-flex flex-row">
                            <div className="mr-1">
                                <b><i className="bi bi-person-fill" style={{color: '#dc3545'}}></i></b>
                            </div>
                            <div>
                                <div>{b.client_name}</div>
                            </div>
                        </div>
                        <div className="d-flex flex-row">
                            <div className="mr-1">
                                <b><i class="bi bi-telephone-fill" style={{color: '#dc3545'}}></i></b>
                            </div>
                            <div>
                                <div>{b.client_contact_num}</div>
                            </div>
                        </div>
                        <div className="d-flex flex-row">
                            <div className="mr-1">
                                <b><i class="bi bi-geo-alt-fill" style={{color: '#dc3545'}}></i></b>
                            </div>
                            <div>
                                <div>{b.client_address}</div>
                            </div>
                        </div>

                        { b.start_dt_2 &&
                            <div className="d-flex flex-row">
                                <div className="mr-1">
                                    <b><i className="bi bi-calendar-date-fill" style={{color: '#dc3545'}}></i></b>
                                </div>
                                <div>
                                    <div>
                                        {b.start_dt_2}
                                        {b.end_dt_2 && ' - ' + b.end_dt_2}
                                    </div>
                                </div>
                            </div>
                        }

                        { b.start_time &&
                            <div className="d-flex flex-row">
                                <div className="mr-1">
                                    <b><i class="bi bi-clock-fill" style={{color: '#dc3545'}}></i></b>
                                </div>
                                <div>
                                    <div>
                                        {b.start_time}
                                        {b.end_time && ' - ' + b.end_time}    
                                    </div>
                                </div>
                            </div>
                        }

                        { b.note_to_provider &&
                            <div className="d-flex flex-row">
                                <div className="mr-1">
                                    <b><i class="bi bi-card-text" style={{color: '#dc3545'}}></i></b>
                                </div>
                                <div className="text-break">Note: {b.note_to_provider}</div>
                            </div>
                        }
                        
                        <hr />

                        <Comments id={b.service_booking_id} allowReply={true} hasComments={b.hasComments} newCommentsCount={b.newCommentsCount} profilePicture={profilePicture} />

                        <hr />

                        <button className="btn btn-services-gold ml-2" onClick={ () => handleAccept(b.service_booking_id, b.client_name) }>
                            <i class="bi bi-check-circle-fill"></i> Accept
                        </button>
                        <button className="btn btn-services-gold ml-2" onClick={ () => handleDecline(b.service_booking_id, b.client_name) }>
                            <i class="bi bi-x-circle-fill"></i> Decline
                        </button>
                        
                    </div>
                </div>
            ))}

            <Modal backdrop="static" show={showDeclineMessage} onHide={handleCancel} centered className="mobile-modal">
                <Modal.Body>
                    <h5 className="mb-4 mt-2">{showDeclineMessage}</h5>
                    
                    { acceptOrDecline === 'decline' &&
                        <div className="mt-2">
                            <textarea rows="2" maxLength="2000" value={ reason } onChange={ (e) => setReason(e.target.value) } placeholder="Reason for declining" className="form-control w-100"></textarea>
                            <h6 className="mt-1">
                                <TextAreaCounter text={reason} maxLength="2000" />
                            </h6>
                        </div>
                    }

                    <hr />
                    <div className="d-flex justify-content-end">
                        { isSaving && 
                            <button className="btn btn-services-gold mr-2" style={{minWidth: '90px'}} disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                        }
                        { !isSaving && acceptOrDecline === 'accept' &&
                            <Link className="btn btn-services-gold mr-2" onClick={handleProceedAccept} style={{minWidth: '90px'}}>
                                <i class="bi bi-check-circle-fill"></i> Yes
                            </Link>
                        }
                        { !isSaving && acceptOrDecline === 'decline' &&
                            <Link className="btn btn-services-gold mr-2" onClick={handleProceedDecline} style={{minWidth: '90px'}}>
                                <i class="bi bi-check-circle-fill"></i> Yes
                            </Link>
                        }
                        <Link className="btn btn-services-gold" onClick={handleCancel} style={{minWidth: '90px'}}>
                            <i class="bi bi-x-circle-fill"></i> No
                        </Link>
                    </div>
                </Modal.Body>
            </Modal>
            
        </div>
    );
}
 
export default ProviderBookingRequests;