import { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import "../../css/mobile/seller-center.css";

const SellerCenterAddProduct = () => {

    const [show, setShow] = useState(false);
    const [showWarrantyExpiry, setShowWarrantyExpiry] = useState(false);
    const [productName, setProductName] = useState("");
    const [productDescription, setProductDescription] = useState("");
    const [productCategory, setProductCategory] = useState("Appliances");
    const [productPrice, setProductPrice] = useState("");
    const [currency, setCurrency] = useState("PHP");
    const [productOriginalPrice, setProductOriginalPrice] = useState(null);
    const [productOriginalPriceCurrency, setOriginalPriceCurrency] = useState("PHP");
    const [productQuantity, setProductQuantity] = useState(1);
    const [productSku, setProductSku] = useState("");
    const [productLocation, setProductLocation] = useState("Abra");
    const [productManufacturer, setProductManufacturer] = useState("");
    const [productSerialNumber, setProductSerialNumber] = useState("");
    const [productModelName, setProductModelName] = useState("");
    const [productModelNumber, setProductModelNumber] = useState("");
    const [productWarrantyType, setProductWarrantyType] = useState("no-warranty");
    const [productWarrantyExpiry, setProductWarrantyExpiry] = useState("");
    const [productCondition, setProductCondition] = useState("new");
    var   [productColor, setProductColor] = useState([]);
    const [productDimensionWidth, setProductDimensionWidth] = useState("");
    const [productDimensionLength, setProductDimensionLength] = useState("");
    const [productDimensionHeight, setProductDimensionHeight] = useState("");
    const [productWeight, setProductWeight] = useState("");
    const [selectedFile, setSelectedFile] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showCoverPreview, setShowCoverPreview] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showCoverError, setCoverError] = useState(false);
    const [showGalleryError, setGalleryError] = useState(false);    
    const [coverMessage, setCoverMessage] = useState("");
    const [galleryMessage, setGalleryMessage] = useState("");
    const [count, setCount] = useState(0);
    const [isDisabled, setIsDisabled] = useState(true);
    const [checked, setChecked] = useState(false);
    const [isPending, setIsPending] = useState(false);
    

    const descriptionCount = (descr) => {
        setProductDescription(descr);
        setCount(descr.length);
    }    

    const canBeSubmitted = () => {
      return checked ? setIsDisabled(true) : setIsDisabled(false);
    };

    const onCheckboxClick = () => {
        setChecked(!checked);
        return canBeSubmitted();
      };

    const maxSelect = (e) => {
        let files = e.target.files // create file object
        if (e.target.name === "cover") {    //cover field upload
            if (files.length > 1) {
              const msg = "Only 1 cover image can be uploaded at a time"
              e.target.value = null // discard selected file
              setCoverError(true);
              setShowCoverPreview(false);
              setCoverMessage(msg);
              console.log(msg);
      
              return false;
            }
            return true;
        } else {    //gallery field uploads
            if (files.length > 6) { 
              const msg = "Only 6 products images can be selected at a time"
              e.target.value = null // discard selected file
              setGalleryError(true);
              setShowPreview(false);
              setGalleryMessage(msg);
              console.log(msg)
      
              return false;
            }
            return true;
        }
     }
    
    const checkMimeType=(e)=>{
        let files = e.target.files     //getting file object
        let err = ""     //define message container
        const types = ["image/png", "image/jpeg", "image/gif"] // list allow mime type
        for(var x = 0; x<files.length; x++) {  // loop access array
            if (types.every(type => files[x].type !== type)) {
            err += files[x].type+" is not a supported format\n";
            }
        };
        if (err !== "") {
            e.target.value = null
            if (e.target.name === "cover") {    //cover field upload
                setCoverError(true);
                setShowCoverPreview(false);
                setCoverMessage(err);
                console.log(err);
                
                return false;
            } else {    //gallery field upload
                setGalleryError(true);
                setShowPreview(false);
                setGalleryMessage(err);
                console.log(err);

                return false;
            }
        }
        return true;
    }

    const checkFileSize=(e)=>{
        let files = e.target.files
        let size = 10000000
        let err = "";
        for(var x = 0; x<files.length; x++) {
            if (files[x].size > size) {
            err += files[x].type+" is too large, please select a smaller sized file\n";
            }
        };
        if (err !== "") {
            e.target.value = null
            if (e.target.name === "cover") {    //cover input field upload
                setCoverError(true);
                setShowCoverPreview(false);
                setCoverMessage(err);
                console.log(err);
                return false
            } else {    //gallery input field upload
                setGalleryError(true);
                setShowPreview(false);
                setGalleryMessage(err);
                console.log(err)
                return false
            }
        }
        return true;
    }

	const handleProductImages = (e) => {
		if (e.target.files) {
            if(maxSelect(e) && checkMimeType(e) && checkFileSize(e) ) {
                setShowPreview(true);
                setGalleryError(false);
                const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));
    
                // setSelectedFiles((prevImages) => prevImages.concat(filesArray));
                setSelectedFiles(filesArray);
                Array.from(e.target.files).map(
                    (file) => URL.revokeObjectURL(file) // avoid memory leak
                );
            }
		}
	};

    const handleCoverImage = (e) => {
		if (e.target.files[0]) {
            if(maxSelect(e) && checkMimeType(e) && checkFileSize(e) ) {
                setShowCoverPreview(true);
                setCoverError(false);

                const coverImg = URL.createObjectURL(e.target.files[0]);
                setSelectedFile(coverImg);
            }
		}
	};

    const renderCoverImage = (photo) => {
		return <img className="product-cover-preview" src={photo} alt="" key={photo} />;
	};

	const renderProductImages = (source) => {
		return source.map((photo) => {
			return <img className="product-image-preview" src={photo} alt="" key={photo} />;
		});
	};

    const changeCondition = (newCondition) => {
        if (newCondition === "used") {
            setShow(true);
        } else {
            setShow(false);
        };
        setProductCondition(newCondition);
    }

    const changeWarrantyType = (newWarrantyType) => {
        if (newWarrantyType !== "no-warranty") {
            setShowWarrantyExpiry(true);
        } else {
            setShowWarrantyExpiry(false);
        }
        setProductWarrantyType(newWarrantyType);
    }

    const changeCategory = (newCategory) => { setProductCategory(newCategory) }

    const changeLocation = (newLocation) => { setProductLocation(newLocation) }

    const changeOriginalCurrency = (originalCurrency) => { setOriginalPriceCurrency(originalCurrency) }

    const changeCurrency = (currency) => { setCurrency(currency) }

    const closePopup = () => {
        window.location.href = "/seller-center/add-product"
    }

    const productColors = [
        {
            value: "white",
            label: "White"
        },
        {
            value: "silver",
            label: "Silver"
        },
        {
            value: "gray",
            label: "Gray"
        },
        {
            value: "black",
            label: "Black"
        },
        {
            value: "red",
            label: "Red"
        },
        {
            value: "maroon",
            label: "Maroon"
        },
        {
            value: "yellow",
            label: "Yellow"
        },
        {
            value: "gold",
            label: "Gold"
        },
        {
            value: "olive",
            label: "Olive"
        },
        {
            value: "lime",
            label: "Lime"
        },
        {
            value: "green",
            label: "Green"
        },
        {
            value: "aqua",
            label: "Aqua"
        },
        {
            value: "teal",
            label: "Teal"
        },
        {
            value: "blue",
            label: "Blue"
        },
        {
            value: "navy",
            label: "Navy"
        },
        {
            value: "fuchsia",
            label: "Fuchsia"
        },
        {
            value: "purple",
            label: "Purple"
        },
        {
            value: "other",
            label: "Other"
        }
    ];

    const handleColors = (e) => {
        setProductColor(Array.isArray(e)?e.map(x=>x.value + ","):[]);
    }

    const handleSubmit = (e) => {
        e.preventDefault(); //will prevent default page load

        //validate required fields
        if(productName.trim() !== "" && productDescription.trim() !== "" && productPrice !== "" && productCategory !== "" && productCondition !== "" && productPrice !== "" && productQuantity !== "" && selectedFile !== "" && selectedFiles !== "") {            
            const productForm = document.getElementById("product-form");
            const formData = new FormData(productForm);

            setIsPending(true);

            fetch("/api/seller-center/add-products", {
                method: "POST",
                body: formData
            })
            .then( (result) => result.json() )
            .then( (result) => {
                if( "successfulInsert" in result && result.successfulInsert ) {
                    document.getElementById("btn-modal").click();
                    setIsPending(false);
                } else {
                    alert("An error has occurred!");
                }
                console.log(result);
            });
        }
    }

    return(
        <div className="d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Add Product</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="seller-form p-4 border rounded">
                    <small><strong>Note:</strong> Please make sure your <Link to="/user/payment-options" className="text-decoration-underline">payment option</Link> is already setup in order for your product to be available for purchase.</small>
                    <form id="product-form" onSubmit={handleSubmit} encType="multipart/form-data">
                        <small>
                        <div className="d-flex flex-column mt-2">
                            <label htmlFor="name">Product name <span className="text-danger"><strong>*</strong></span></label>
                            <input id="name" name="name" type="text" className="form-control input-text" maxLength="200" value={ productName } onChange={ (e) => setProductName(e.target.value) } required />
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <label htmlFor="description">Product description <span className="text-danger"><strong>*</strong></span></label>
                            <textarea id="description" name="description" className="form-control" rows="2" maxLength="2000" value={ productDescription } onChange={ (e) => descriptionCount(e.target.value) } required></textarea>
                            <p className="text-end">{count}/2000</p>
                        </div>
                        <div className="d-flex flex-column">
                            <label htmlFor="category">Product Category <span className="text-danger"><strong>*</strong></span></label>
                            <select id="category" name="category" className="form-select" aria-label="Choose product category" value={ productCategory } onChange={ (e) => changeCategory(e.target.value) } required>
                                <option value="Appliances">Appliances</option>
                                <option value="Audio">Audio</option>
                                <option value="Babies & Kids">Babies & Kids</option>
                                <option value="Beauty & Personal Care">Beauty & Personal Care</option>
                                <option value="Cameras">Cameras</option>
                                <option value="Cars for Sale">Cars for Sale</option>
                                <option value="Car Accessories">Car Accessories</option>
                                <option value="Cell Phones & Accessories">Cell Phones & Accessories</option>
                                <option value="Computers/Tablets & Networking">Computers/Tablets & Networking</option>
                                <option value="Food & Drinks">Food & Drinks</option>
                                <option value="Furnitures">Furnitures</option>
                                <option value="Health & Nutrition">Health & Nutrition</option>
                                <option value="Hobbies & Toys">Hobbies & Toys</option>
                                <option value="Home & Living">Home & Living</option>
                                <option value="Home Improvement">Home Improvement</option>
                                <option value="Men's Fashion">Men's Fashion</option>
                                <option value="Pet Supplies">Pet Supplies</option>
                                <option value="Services">Services</option>
                                <option value="Sports Equipment & Apparel">Sports Equipment & Apparel</option>
                                <option value="Video Games & Consoles">Video Games & Consoles</option>
                                <option value="Women's Fashion">Women's Fashion</option>
                            </select>
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <label htmlFor="condition">Product Condition <span className="text-danger"><strong>*</strong></span></label>
                            <select id="condition" name="condition" className="form-select" value={ productCondition } onChange={ (e) => changeCondition(e.target.value) } required>
                                <option value="new">New</option>
                                <option value="used">Used</option>
                                <option value="digital">Digital Good</option>
                                <option value="refurbished">Refurbished</option>
                                <option value="broken">For parts/not working</option>
                            </select>
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <label htmlFor="currency">Selling Price <span className="text-danger"><strong>*</strong></span></label>
                            <div className="input-group">
                                <select id="currency" name="currency" className="form-select" value={ currency } onChange={ (e) => changeCurrency(e.target.value) } required>
                                    <option value="PHP">PHP</option>
                                    <option value="USD">USD</option>
                                </select>
                                <input id="price" name="price" type="number" aria-label="product-price" className="form-control price-field" value={ productPrice } onChange={ (e) => setProductPrice(e.target.value) } required/>
                                <p><small><i><strong>Note: </strong>Please include potential shipping expenses to your price.</i></small></p>
                            </div>
                        </div>
                        <div className="d-flex flex-row mt-2">
                            <div className="col-4 mr-2">
                                <label htmlFor="quantity">Qty <span className="text-danger"><strong>*</strong></span></label>
                                <input id="quantity" name="quantity" type="number" className="form-control" value={ productQuantity } onChange={ (e) => setProductQuantity(e.target.value) } required/>
                            </div>
                            <div className="col-8">
                                <label htmlFor="sku">Product SKU</label>
                                <input id="sku" name="sku" type="text" className="form-control" maxLength="20" value={ productSku } onChange={ (e) => setProductSku(e.target.value) }  />
                            </div>
                        </div>
                        {
                            show?
                                <div className="d-flex flex-column mt-2">
                                    <label htmlFor="originalprice">Original Item Price</label>
                                        <div className="input-group">
                                            <select id="originalcurrency" name="originalcurrency" className="form-select" value={ productOriginalPriceCurrency } onChange={ (e) => changeOriginalCurrency(e.target.value) } required>
                                                <option value="PHP">PHP</option>
                                                <option value="USD">USD</option>
                                            </select>
                                            <input id="originalprice" name="originalprice" type="number" aria-label="original-price" className="form-control price-field" value={ productOriginalPrice } onChange={ (e) => setProductOriginalPrice(e.target.value) } />
                                        </div>
                                </div>
                            :null
                        }
                        <div className="d-flex flex-column mt-2">
                            <label htmlFor="cover">Product Cover Image <span className="text-danger"><strong>* </strong></span></label><small>(You may upload one (1) as a product cover image in jpeg, jpg and png image formats.)</small>
                            <input id="cover" name="cover" className="form-control" type="file" accept=".jpeg,.jpg,.png" onChange={handleCoverImage} required/>
                            {
                                showCoverPreview?
                                    <div className="mb-2">
                                        <h5 className="text-center mt-2">Cover Preview</h5>
                                        <div className="result">{renderCoverImage(selectedFile)}</div>
                                    </div>
                                :null
                            }
                            {
                                showCoverError?
                                    <div className="alert alert-danger mt-3" role="alert">
                                        {coverMessage}
                                    </div>
                                :null
                            }
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <label htmlFor="gallery">Product Gallery <span className="text-danger"><strong>* </strong></span></label><small>(You may upload up to six (6) images in jpeg, jpg and png formats.)</small>
                            <input id="gallery" name="gallery" className="form-control" type="file" accept=".jpeg,.jpg,.png" onChange={handleProductImages} multiple required/>
                                {
                                    showPreview?
                                        <div className="mb-2">
                                            <h5 className="text-center mt-2">Gallery Preview</h5>
                                            <div className="result">{renderProductImages(selectedFiles)}</div>
                                        </div>
                                    :null
                                }
                                {
                                    showGalleryError?
                                        <div className="alert alert-danger mt-3" role="alert">
                                            {galleryMessage}
                                        </div>
                                    :null
                                }
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <label htmlFor="province">Product Location <span className="text-danger"><strong>*</strong></span></label>
                            <select id="province" name="province" className="form-select" value={productLocation} onChange={ (e) => changeLocation(e.target.value) } required>
                                <option value="Abra">Abra</option>
                                <option value="Agusan del Norte">Agusan del Norte</option>
                                <option value="Agusan del Sur">Agusan del Sur</option>
                                <option value="Aklan">Aklan</option>
                                <option value="Albay">Albay</option>
                                <option value="Antique">Antique</option>
                                <option value="Apayao">Apayao</option>
                                <option value="Aurora">Aurora</option>
                                <option value="Basilan">Basilan</option>                                                <option value="Bataan">Bataan</option>
                                <option value="Batanes">Batanes</option>
                                <option value="Batangas">Batangas</option>
                                <option value="Benguet">Benguet</option>
                                <option value="Biliran">Biliran</option>
                                <option value="Bohol">Bohol</option>
                                <option value="Bukidnon">Bukidnon</option>
                                <option value="Bulacan">Bulacan</option>
                                <option value="Cagayan">Cagayan</option>
                                <option value="Camarines Norte">Camarines Norte</option>
                                <option value="Camarines Sur">Camarines Sur</option>
                                <option value="Camiguin">Camiguin</option>
                                <option value="Capiz">Capiz</option>
                                <option value="Catanduanes">Catanduanes</option>
                                <option value="Cavite">Cavite</option>
                                <option value="Cebu">Cebu</option>
                                <option value="Cotabato">Cotabato</option>
                                <option value="Davao de Oro">Davao de Oro</option>
                                <option value="Davao del Norte">Davao del Norte</option>
                                <option value="Davao del Sur">Davao del Sur</option>
                                <option value="Davao Occidental">Davao Occidental</option>
                                <option value="Davao Oriental">Davao Oriental</option>
                                <option value="Dinagat Islands">Dinagat Islands</option>
                                <option value="Eastern Samar">Eastern Samar</option>
                                <option value="Guimaras">Guimaras</option>
                                <option value="Ifugao">Ifugao</option>
                                <option value="Ilocos Norte<">Ilocos Norte</option>
                                <option value="Ilocos Sur">Ilocos Sur</option>
                                <option value="Iloilo">Iloilo</option>
                                <option value="Isabela">Isabela</option>                                    <option value="Kalinga">Kalinga</option>                                                
                                <option value="La Union">La Union</option>
                                <option value="Laguna">Laguna</option>
                                <option value="Lanao del Norte">Lanao del Norte</option>
                                <option value="Lanao del Sur">Lanao del Sur</option>
                                <option value="Leyte">Leyte</option>
                                <option value="Maguindanao">Maguindanao</option>
                                <option value="Marinduque">Marinduque</option>
                                <option value="Masbate">Masbate</option>
                                <option value="Misamis Occidental">Misamis Occidental</option>                                    <option value="Misamis Oriental">Misamis Oriental</option>
                                <option value="Mountain Province">Mountain Province</option>
                                <option value="Negros Occidental">Negros Occidental</option>
                                <option value="Negros Oriental">Negros Oriental</option>
                                <option value="Northern Samar">Northern Samar</option>
                                <option value="Nueva Ecija">Nueva Ecija</option>
                                <option value="Nueva Vizcaya">Nueva Vizcaya</option>
                                <option value="Occidental Mindoro">Occidental Mindoro</option>
                                <option value="Oriental Mindoro">Oriental Mindoro</option>
                                <option value="Palawan">Palawan</option>
                                <option value="Pampanga">Pampanga</option>
                                <option value="Pangasinan">Pangasinan</option>
                                <option value="Quezon">Quezon</option>
                                <option value="Quirino">Quirino</option>
                                <option value="Rizal">Rizal</option>
                                <option value="Romblon">Romblon</option>
                                <option value="Samar">Samar</option>
                                <option value="Sarangani">Sarangani</option>
                                <option value="Siquijor">Siquijor</option>
                                <option value="Sorsogon">Sorsogon</option>
                                <option value="South Cotabato">South Cotabato</option>
                                <option value="Southern Leyte">Southern Leyte</option>
                                <option value="Sultan Kudarat">Sultan Kudarat</option>
                                <option value="Sulu">Sulu</option>
                                <option value="Surigao del Norte">Surigao del Norte</option>
                                <option value="Surigao del Sur">Surigao del Sur</option>
                                <option value="Tarlac">Tarlac</option>
                                <option value="Tawi-Tawi">Tawi-Tawi</option>                                    <option value="Zambales">Zambales</option>
                                <option value="Zamboanga del Norte">Zamboanga del Norte</option>
                                <option value="Zamboanga del Sur">Zamboanga del Sur</option>
                                <option value="Zamboanga Sibugay">Zamboanga Sibugay</option>
                                <option value="Metro Manila">Metro Manila</option>
                            </select>
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <label htmlFor="manufacturer">Manufacturer</label>
                            <input id="manufacturer" name="manufacturer" type="text" className="input-text" maxLength="200" value={ productManufacturer } onChange={ (e) => setProductManufacturer(e.target.value) } />
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <label htmlFor="serial">Serial Number</label>
                            <input id="serial" name="serial" type="text" className="input-text" maxLength="200" value={ productSerialNumber } onChange={ (e) => setProductSerialNumber(e.target.value) } />
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <label htmlFor="model">Model Name</label>
                            <input id="model" name="model" type="text" className="form-control" maxLength="200" value={ productModelName } onChange={ (e) => setProductModelName(e.target.value) } />
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <label htmlFor="number">Model Number</label>
                            <input id="number" name="number" type="text" className="form-control" maxLength="200" value={ productModelNumber } onChange={ (e) => setProductModelNumber(e.target.value) } />
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <label htmlFor="warranty">Warranty Type</label>
                            <select id="warranty" name="warranty" className="form-select" value={ productWarrantyType } onChange={ (e) => changeWarrantyType(e.target.value) }>
                                <option value="no-warranty">No Warranty</option>
                                <option value="international">International Warranty</option>
                                <option value="manufacturer">Manufacturer Warranty</option>
                                <option value="supplier">Supplier Warranty</option>
                                <option value="personal">Personal Warranty</option>
                            </select>
                        </div>
                        {
                            showWarrantyExpiry?
                                <div className="d-flex flex-column mt-2">
                                    <label htmlFor="expiry">Warranty Expiry</label>
                                    <input id="expiry" name="expiry" placeholder="Select date" type="date" className="form-control" value={ productWarrantyExpiry } onChange={ (e) => setProductWarrantyExpiry(e.target.value) } />
                                </div>
                            :null
                        }
                        <div className="d-flex flex-column mt-2">
                            <label htmlFor="color" className="form-label">Color/s</label>
                            <Select id="color" name="color" isMulti options={productColors} onChange={handleColors}></Select>
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <label htmlFor="width">Dimension:</label>
                            <div className="input-group">
                                <input id="width" name="width" type="number" step=".01" className="form-control" placeholder="Width (cm)" aria-label="Width (cm)" aria-describedby="width-addon" value={ productDimensionWidth } onChange={ (e) => setProductDimensionWidth(e.target.value) } />
                                <span className="input-group-text" id="width-addon">cm</span>
                            </div>
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <div className="input-group">
                                <input id="length" name="length" type="number" step=".01" className="form-control" placeholder="Length (cm)" aria-label="Length (cm)" aria-describedby="length-addon" value={ productDimensionLength } onChange={ (e) => setProductDimensionLength(e.target.value) } />
                                <span className="input-group-text" id="length-addon">cm</span>
                            </div>
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <div className="input-group">
                                <input id="height" name="height" type="number" step=".01" className="form-control" placeholder="Height (cm)" aria-label="Height (cm)" aria-describedby="height-addon" value={ productDimensionHeight } onChange={ (e) => setProductDimensionHeight(e.target.value) } />
                                <span className="input-group-text" id="height-addon">cm</span>
                            </div>
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <label htmlFor="weight">Weight</label>
                            <div className="input-group">
                                <input id="weight" name="weight" type="number" step=".01" className="form-control" placeholder="Weight (kg)" aria-label="Weight (kg)" aria-describedby="weight-addon" value={ productWeight} onChange={ (e) => setProductWeight(e.target.value) } />
                                <span className="input-group-text" id="weight-addon">kg</span>
                            </div>
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <div className="form-check pb-4">
                                <input className="form-check-input" type="checkbox" value="" id="agreeItemReturn" onClick={onCheckboxClick}/>
                                <label className="form-check-label" htmlFor="agreeItemReturn">
                                    {/* <small>As a seller, I agree to a 7 day return/refund if buyer received wrong item (does not match product description or photos), damaged or faulty item, counterfeit item, expired item, incomplete item/missing accessories (if missing items are included in product description) or if buyer has not received the item.</small> */}
                                    <small>As a seller, I agree to a return and refund if buyer received wrong item (does not match product description or photos), damaged or faulty item, counterfeit item, expired item, incomplete item/missing accessories (if missing items are included in product description) or if buyer has not received the item.</small>
                                </label>
                            </div>
                            { !isPending && 
                                <button id="btn-submit" type="submit" className="btn btn-primary" disabled={isDisabled}>Submit</button>
                            }
                            { isPending && 
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                                </button>
                            }
                        </div>
                        <button id="btn-modal" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalSuccess" hidden></button>
                        {/* Modal */}
                        <div className="modal fade" id="modalSuccess" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalSuccessLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="modalSuccessLabel">Success!</h5>
                                    <button type="button" className="btn-close btn-close-white btn-sm" data-bs-dismiss="modal" onClick={closePopup} aria-label="Close"></button>
                                </div>
                                <div className="modal-body mt-2 mb-2">
                                    You have added a new product!
                                </div>
                                <div className="modal-footer pt-1 pb-1">
                                    <button type="button" className="btn btn-secondary btn-sm" onClick={closePopup} data-bs-dismiss="modal">Close</button>
                                </div>
                                </div>
                            </div>
                        </div>
                        </small>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SellerCenterAddProduct;

