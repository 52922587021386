import { useState } from "react";

const SellerCenterProcessOrder = ({orders, index, triggerRerender}) => {
    const [courier, setCourier] = useState("");
    const [trackingCode, setTrackingCode] = useState("");
    const [orderID] = useState(orders.order_id);
    const [isPending, setIsPending] = useState(false);
    const [showTrackingCode, setShowTrackingCode] = useState(true);

    const changeCourier = (newCourier) => {
        if (newCourier === "Personal Delivery") {
            setShowTrackingCode(false)
        } else {
            setShowTrackingCode(true)
        }
        setCourier(newCourier) 
    }

    const handleShippedOrder = (e) => {
        e.preventDefault(); //will prevent default page load

        if(courier !== "") {
            setIsPending(true);

            const shippedOrderDetails = {
                order_id: orderID,
                delivery_type: courier,
                tracking_code: trackingCode
            };

            fetch(`/api/seller-center/orders/${orderID}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(shippedOrderDetails)
            })
            .then( (result) => result.json() )
            .then( (result) =>  {
                if( "successfulInsert" in result && result.successfulInsert ) {
                    document.getElementById(`btn-close-modal-${index}`).click(); //close the modal
                    setIsPending(false);
                    // triggerRerender(); //trigger rerender of parent component
                    window.location.href = "/seller-center/orders";
                }
                else { //unsuccessful insert
                    alert("Something went wrong!");
                }
            });
        }
    }
    return(
        <>
            <form onSubmit={handleShippedOrder}>
                { orders.order_status === 'paid' && 
                    <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target={"#process-order-modal-" + index}>
                        <small><i className="bi bi-truck"></i> Manage order</small>
                    </button>
                }
                <div className="modal fade" id={"process-order-modal-" + index} tabIndex="-1" aria-labelledby={"process-order-label-" + index} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mr-1" id={"process-order-label-" + index}>Details for </h5> Order #&nbsp;{orders.order_id}
                                <button type="button" id={"btn-close-modal-" + index} className="btn-close btn-close-white btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-start">
                                <small>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <strong>Name:</strong>
                                        </div>
                                        <div className="col-md-8">
                                            {orders.delivery_name}
                                        </div>
                                    </div>
                                    {orders.delivery_phone !== '' &&
                                        <div className="row mt-1">
                                            <div className="col-md-4">
                                                <strong>Phone:</strong>
                                            </div>
                                            <div className="col-md-8">
                                                <span>{orders.delivery_phone}</span>
                                            </div>
                                        </div>
                                    }
                                    <div className="row mt-1">
                                        <div className="col-md">
                                            <strong>Address:</strong>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md">
                                            {orders.delivery_address}
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <strong>Delivery Method:</strong>
                                            <select id="courier" name="courier" className="form-select form-select-sm ml-2" style={{height: '38px', width: '90%'}} value={ courier } onChange={ (e) => changeCourier(e.target.value) } required>
                                                <option defaultValue value="">Select Courier...</option>
                                                <option value="Personal Delivery">Personal Delivery</option>
                                                <option value="2GO Express">2GO Express</option>
                                                <option value="Air 21">Air 21</option>
                                                <option value="Airspeed">Airspeed</option>
                                                <option value="AP Cargo">AP Cargo</option>
                                                <option value="DHL">DHL</option>
                                                <option value="Grab Express">Grab Express</option>
                                                <option value="J&T Express">J&T Express</option>
                                                <option value="JRS Express">JRS Express</option>
                                                <option value="Lalamove">Lalamove</option>
                                                <option value="LBC">LBC</option>
                                                <option value="Ninja Van">Ninja Van</option>
                                                <option value="UPS">UPS</option>
                                                <option value="XDE Logistics">XDE Logistics</option>
                                            </select>
                                    </div>
                                    {
                                        showTrackingCode?
                                            <div className="row mt-1 lh-base">
                                                <strong>Tracking Code:</strong>
                                                <input id="tracking-code" name="tracking-code" type="text" className="form-control form-control-sm input-text ml-2" maxLength="200" style={{height: '38px', fontSize: '.875rem', width: '90%'}} value={ trackingCode } onChange={ (e) => setTrackingCode(e.target.value) } required />
                                            </div>
                                        :null
                                    }
                                    
                                </small>
                            </div>
                            <div className="modal-footer">
                                { !isPending && 
                                    <button type="submit" className="btn btn-primary btn-sm"><small><i className="bi bi-check-lg"> </i>Confirm Delivery</small></button>
                                }
                                { isPending && 
                                    <button className="btn btn-primary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading ...
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default SellerCenterProcessOrder;