import { useState, useEffect } from "react";

import ReturnImages from "./SellerCenterRefundImages";
import AcceptRefundRequest from "./SellerCenterRefundAccept";
import DeclineRefundRequest from "./SellerCenterRefundDecline";
import ReceiveReturns from "./SellerCenterReceiveReturns";
import ReturnDetails from "./SellerCenterReturnDetails";

const SellerCenterRefundRequests = () => {
    const [sellerReturns, setSellerReturns] = useState([]);

    useEffect(() => {
        fetch('/api/seller-center/returns')
            .then( res => { return res.json(); } )
            .then( data => { setSellerReturns(data); } );
    }, [] );

    return(
        <div className="d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">Manage Returns</h3>

            <div className="d-flex justify-content-center mt-2">
                <div className="user-order p-3 border rounded">
                    {sellerReturns.length === 0 &&
                        <p className="text-center">No pending returns found.</p>
                    }
                    {sellerReturns.length > 0 &&
                        <>
                            { sellerReturns.map( ( returns, index ) => (
                                <>
                                    <div className="p-2 border border-secondary rounded-3 mt-2 mb-2" key={index}>
                                        <small>
                                            Order # <span className="fw-bold mr-3">{returns.sales_order_id}</span><br/>
                                            Return Requested: <span className="fw-bold">{returns.return_date}</span>
                                            <div className="p-1 border border-secondary mb-1">
                                                <div className="p-1">
                                                    <img src={returns.return_thumbnail} className="rounded float-start product-thumb ml-1 mr-2" alt={returns.return_product + " image"} title={returns.return_product} />
                                                    <span>{returns.return_product}</span>
                                                </div>
                                                <div className="row mt-3">
                                                    <span className="ml-1"><strong>Name: </strong>{returns.name}</span>
                                                    <span className="ml-1"><strong>Address: </strong>{returns.address}</span>
                                                </div>
                                                <div className="row mt-2">
                                                    <span className="ml-1"><strong>Reason:</strong> {returns.return_reason}</span>
                                                    <span className="ml-1"><strong>Description:</strong> {returns.return_description}</span>
                                                </div>
                                                <ReturnImages returnId={returns.return_id} />
                                            </div>
                                        </small>
                                    </div>
                                    <small>
                                        {returns.return_status === 'approved' &&
                                            <><strong>Status: </strong> <span className="badge bg-success">Return/Refund Approved</span><br /></>
                                        }
                                        {returns.return_status === 'submitted' &&
                                            <>
                                                <AcceptRefundRequest returns={returns} />
                                                <DeclineRefundRequest returns={returns} />
                                            </>
                                        }
                                        {returns.return_status === 'shipped' &&
                                            <>
                                                <ReceiveReturns returns={returns} />
                                                <ReturnDetails returnId={returns.return_id} index={index} />
                                            </>
                                        }
                                        {returns.return_status === 'received' &&
                                            <><strong>Status: </strong><span className="badge bg-success">Return Item Received</span></>
                                        }
                                        {returns.return_status === 'declined' &&
                                            <>
                                                <strong>Status: </strong><span className="badge bg-danger">You have declined this refund request</span><br />
                                                <strong>Reason: </strong>{returns.decline_reason}
                                            </>
                                        }
                                    </small>
                                </>
                            ) ) }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default SellerCenterRefundRequests;