import { useEffect } from "react";
import { Link } from "react-router-dom";

const UserMenu = ({ path, firstName }) => {
    useEffect( () => {
        //set body background color
        document.body.style.backgroundColor = "#171a26";
        return () => { //unmount
            document.body.style.backgroundColor = "#f5f6f9";
        }
    }, [] );

    return (
        <div className="user-menu d-flex flex-column pt-3 px-3">
            <h3 className="d-flex justify-content-center">
                { firstName && <span>Hi, {firstName}</span>}
            </h3>

            <div className="mt-3">
                <Link to={ path + 'user/profile' } className="menu-link">
                    Profile <i class="bi bi-chevron-right"></i>
                </Link>
                <Link to={ path + 'user/addresses' } className="menu-link">
                    Address <i class="bi bi-chevron-right"></i>
                </Link>
                <Link to={ path + 'user/payment-options' } className="menu-link">
                    Payment <i class="bi bi-chevron-right"></i>
                </Link>
                <Link to={ path + 'user/security-questions' } className="menu-link">
                    Security Questions <i class="bi bi-chevron-right"></i>
                </Link>
                <Link to={ path + 'user/change-password' } className="menu-link">
                    Change Password <i class="bi bi-chevron-right"></i>
                </Link>
                <Link to={ path + 'user/orders' } className="menu-link">
                    My Orders <i class="bi bi-chevron-right"></i>
                </Link>
                <Link to={ path + 'user/shipped-orders' } className="menu-link">
                    Shipped Orders <i class="bi bi-chevron-right"></i>
                </Link>
                <Link to={ path + 'user/received-orders' } className="menu-link">
                    Received Orders <i class="bi bi-chevron-right"></i>
                </Link>
                <Link to={ path + 'user/returns' } className="menu-link">
                    Returns <i class="bi bi-chevron-right"></i>
                </Link>
                <Link to={ path + 'signout' } className="menu-link">
                    Sign Out <i class="bi bi-chevron-right"></i>
                </Link>
            </div>
        </div>
    );
}
 
export default UserMenu;