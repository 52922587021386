import { useState,useEffect } from "react";

import "../../css/mobile/styles.css";

const OrderItems = ({orders, index}) => {
    const [orderItems, setOrderItems] = useState([]);
    const [orderID] = useState(orders.order_id);

    // useEffect(() => {
    //     fetch(`/api/received-order-items/${orderID}`)
    //         .then( res => { return res.json(); } )
    //         .then( data => { setOrderItems(data); } );
    // }, [] );

    useEffect(() => {
        fetch(`/api/seller-center/shipped-items/${orderID}`)
            .then( res => { return res.json(); } )
            .then( data => { setOrderItems(data); } );
    }, [] );

    return(
        <>
            {orderItems.length === 0 &&
                <p className="text-center">No order items found.</p>
            }
            {orderItems.length > 0 &&
                <>
                    { orderItems.map( ( orders, index ) => (
                        <div className="p-2 border border-secondary rounded mb-2" key={index}>
                            <div className="p-1">
                                <img src={orders.order_thumbnail} className="rounded float-start product-thumb ml-1 mr-2" alt={orders.order_name + " image"} title={orders.order_name} />
                                <span className="title-limit">{orders.order_name}</span> <br /><span className="fw-light">{orders.order_currency} {orders.order_amount_base_price}</span>
                            </div>
                            <div className="row">
                                <span className="ml-1">Condition: {orders.order_condition}</span>
                                <span className="ml-1">Warranty: {orders.order_warranty_type}</span>
                                { orders.order_warranty_period !== null &&
                                    <span className="ml-1">Warranty Period: {orders.order_warranty_period}</span>
                                }
                            </div>
                        </div>
                    ) ) }
                </>
            }         
        </>
    )
}

export default OrderItems;