import { useState, useEffect, forwardRef } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-bootstrap/Modal';
import { isNumeric, isInt } from 'validator';

import TextAreaCounter from '../reusable/TextAreaCounter';

const UserBookService = ({ path }) => {
    const { id } = useParams(); //get service id
    const [ service, setService ] = useState(null);
    const [ addresses, setAddresses ] = useState([]);
    const [ defaultAddress, setDefaultAddress ] = useState('');

    const [ showModalSetTime, setShowModalSetTime ] = useState(false);
    const handleSetAndClose = () => {

        if( isNumeric(startHour, { no_symbols: true }) && isInt(startHour, { min: 1, max: 12 } )
                && isNumeric(startMinute, { no_symbols: true }) && isInt(startMinute, { min: 0, max: 59 } )
                && (startAmPm === 'AM' || startAmPm === 'PM' ) ) {

            //set start time and add beginning 0 if needed
            setStartTime( (startHour.length === 1 ? '0' + startHour : startHour)
                + ':' + (startMinute.length === 1 ? '0' + startMinute : startMinute)
                + ' ' + startAmPm );

            if( isNumeric(endHour, { no_symbols: true }) && isInt(endHour, { min: 1, max: 12 } )
                    && isNumeric(endMinute, { no_symbols: true }) && isInt(endMinute, { min: 0, max: 59 } )
                    && (endAmPm === 'AM' || endAmPm === 'PM' ) ) {

                //set end time and add beginning 0 if needed
                setEndTime( (endHour.length === 1 ? '0' + endHour : endHour)
                    + ':' + (endMinute.length === 1 ? '0' + endMinute : endMinute)
                    + ' ' + endAmPm );
            } else setEndTime('');
        } else {
            setStartTime('');
            setEndTime('');
        }
        setShowModalSetTime(false);
    }
    const handleShowModalSetTime = () => setShowModalSetTime(true);

    const [ startTime, setStartTime ] = useState('');
    const [ endTime, setEndTime ] = useState('');

    const [ startHour, setStartHour ] = useState('');
    const [ startMinute, setStartMinute ] = useState('');
    const [ startAmPm, setStartAmPm ] = useState('--');

    const [ endHour, setEndHour ] = useState('');
    const [ endMinute, setEndMinute ] = useState('');
    const [ endAmPm, setEndAmPm ] = useState('--');

    const [ startDate, setStartDate ] = useState('');
    const [ endDate, setEndDate ] = useState('');

    const ButtonDate = forwardRef(({ val, onClick, defLabel }, ref) => (
        <button className="btn btn-services-gold mt-2" onClick={onClick} ref={ref} style={{minWidth: '170px'}}>
            <i className="bi bi-calendar-date-fill"></i> { val === '' ? defLabel : val }
        </button>
    ));

    const [ note, setNote ] = useState('');

    useEffect( () => {
        fetch(`/api/user-book-service/${id}`)
            .then( res => { 
                if(res.ok) return res.json();
                throw new Error('No service found.');
            }).then( data => {
                if(data) { //check if data is not null
                    setService(data);

                    if( 'addresses' in data && data.addresses ) { //address is not null
                        let defAddress = null;
                        if( data.addresses.length === 1) defAddress = data.addresses[0]; //only one address
                        else { //multiple addresses
                            for( let i = 0; i < data.addresses.length; i++ ) {
                                if( data.addresses[i].is_default === 1 ) {
                                    defAddress = data.addresses[i];
                                    break; //end the loop
                                }
                            }
                            if( defAddress === null ) defAddress = data.addresses[0]; //no default address; set default to the first address in the array
                        }
                        setDefaultAddress( defAddress );
                        setAddresses(data.addresses); //set addresses
                        
                        if( !defAddress ) { //no address
                            setShowMessage("Address is required. Please provide one.");
                            setMessageLink( path + 'user/addresses' );
                        } else if( !data.contact_number ) { //no contact number
                            setShowMessage("Please provide your contact number.");
                            setMessageLink( path + 'user/profile' );
                        }
                    }
                } else throw new Error('No service found.'); //data is null, send error
            }).catch( (e) => {
                alert(e.message);
            })
        ;
    }, [] );

    const [ isSending, setIsSending ] = useState(false);

    const handleSendBookingRequest = () => {
        if( defaultAddress && service.contact_number ) {
            if( startDate !== "" ) {
                setIsSending(true);

                let address = "";
                address = address + ( defaultAddress.address1 !== "" ? defaultAddress.address1 : "" );
                address = address + ( defaultAddress.address2 !== "" ? ", " + defaultAddress.address2 : "" );
                address = address + ( defaultAddress.city !== "" ? ", " + defaultAddress.city : "" );
                address = address + ( defaultAddress.state_territory !== "" ? ", " + defaultAddress.state_territory : "" );
                address = address + ( defaultAddress.country !== "" ? ", " + defaultAddress.country : "" );
                address = address + ( defaultAddress.postal_code !== "" ? ", " + defaultAddress.postal_code : "" );

                const data = { name: service.name, contactNumber: service.contact_number, address, startDate, endDate, startTime, endTime, note };

                fetch(`/api/user-book-service/${id}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                })
                .then( (result) => result.json() )
                .then( (result) =>  {
                    if( 'successfulInsert' in result && result.successfulInsert ) {
                        setShowMessage("Your booking request has been sent to the service provider.");
                        setMessageLink( path + 'service/' + id );
                    } else {
                        if( 'err_msg' in result ) alert( result.err_msg );
                        else alert("Unable to process request.");
                    }
                    setIsSending(false);
                });
            } else {
                alert("Start date is required.");
            }
        } else alert("Please setup your address and/or contact number in your profile.");
    }

    const [ showMessage, setShowMessage ] = useState(null);
    const [ messageLink, setMessageLink ] = useState("");
    const handleCloseMessage = () => setShowMessage(null);

    const formatDate = (date) => {
        const y = date.getFullYear();
        let m = date.getMonth() + 1;
        let d = date.getDate();

        m = ( m > 9 ? '' : '0' ) + m; //add 0 if single digit
        d = ( d > 9 ? '' : '0' ) + d; //add 0 if single digit

        return m + '/' + d + '/' + y;
    }

    const handleSetHour = (val, type) => {
        if( val === '' || ( isNumeric(val, { no_symbols: true }) && isInt(val, { min: 1, max: 12 } ) && val.length <= 2 ) ) {
            if( type === 'start' ) setStartHour(val);
            else if( type === 'end' ) setEndHour(val);
        }
    }

    const handleSetMinute = (val, type) => {
        if( val === '' || ( isNumeric(val, { no_symbols: true }) && isInt(val, { min: 0, max: 59 } ) && val.length <= 2 ) ) {
            if( type === 'start' ) setStartMinute(val);
            else if( type === 'end' ) setEndMinute(val);
        }
    }

    return (
        <div className="book-service pt-3 px-3">
            { service &&
                <div className="service-details">
                    { service.serv_status !== 'Active'  && 
                        <h6 className="prod-unavailable alert alert-danger mb-3 text-center">This service is currently unavailable.</h6>
                    }

                    <div className="alert" role="alert">
                        <div className="d-flex flex-row">
                            <div className="mr-1">
                                <b><i className="fas fa-tools" aria-hidden="true" style={{color: '#dc3545'}}></i></b>
                            </div>
                            <div>
                                <div className="mb-1"><b>Service Details</b></div>
                                <div>{service.serv_name}</div>
                                <div>By {service.first_name} {service.last_name}</div>
                                <p>{service.serv_descr}</p>
                            </div>
                        </div>

                        <hr />

                        <div className="d-flex flex-row">
                            <div className="mr-1">
                                <b><i className="bi bi-cash-coin" style={{color: '#dc3545'}}></i></b>
                            </div>
                            <div className="">
                                {service.serv_payment_currency} {service.serv_payment_rate} ({service.serv_payment_unit})
                            </div>
                        </div>
                    </div>

                    <div className="alert" role="alert">
                        <div className="d-flex flex-row">
                            <div className="mr-1">
                                <b><i className="bi bi-person-fill" style={{color: '#dc3545'}}></i></b>
                            </div>
                            <div>
                                <div className="mb-1"><b>Client Details</b></div>
                                <div>{service.name}</div>
                                <div>{service.contact_number}</div>
                                <div>
                                    { defaultAddress && defaultAddress.address1 && <span>{defaultAddress.address1}</span> }
                                    { defaultAddress && defaultAddress.address2 && <span>, {defaultAddress.address2}</span> }
                                    { defaultAddress && defaultAddress.city && <span>, {defaultAddress.city}</span> }
                                    { defaultAddress && defaultAddress.state_territory && <span>, {defaultAddress.state_territory}</span> }
                                    { defaultAddress && defaultAddress.country && <span>, {defaultAddress.country}</span> }
                                    { defaultAddress && defaultAddress.postal_code && <span>, {defaultAddress.postal_code}</span> }
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div className="d-flex flex-row">
                            <div className="mr-1">
                                <b><i className="bi bi-calendar-check" style={{color: '#dc3545'}}></i></b>
                            </div>
                            <div className="d-flex flex-column w-100">
                                <div className="mb-1"><b>Set Date & Time</b></div>
                                <div className="">
                                    <DatePicker onChange={ (date) => setStartDate( formatDate(date) ) } customInput={<ButtonDate val={startDate} defLabel="Start Date" />} />

                                    <DatePicker onChange={ (date) => setEndDate( formatDate(date) ) } customInput={<ButtonDate val={endDate} defLabel="End Date" />} />

                                    <button className="btn btn-services-gold mt-2" onClick={handleShowModalSetTime} style={{minWidth: '170px'}}>
                                        <i class="bi bi-clock-fill"></i> { startTime === '' ? 'Set Time' : startTime } { endTime === '' ? '' : ' - ' + endTime }
                                    </button>

                                    
                                    <Modal centered backdrop="static" show={showModalSetTime} onHide={handleSetAndClose} className="user-checkout-change-address mobile-modal">
                                        <Modal.Body>
                                            <div>Start Time</div>
                                            <div class="input-group mb-2">
                                                <input type="number" class="form-control" placeholder="HH" value={ startHour } onChange={ (e) => handleSetHour(e.target.value, 'start') } />

                                                <span class="input-group-text" style={{paddingLeft: '2px', paddingRight: '2px'}}>:</span>
                                                <input type="number" class="form-control" placeholder="MM" value={ startMinute } onChange={ (e) => handleSetMinute(e.target.value, 'start') } />
                                                
                                                <select class="form-select" value={startAmPm} onChange={ (e) => setStartAmPm(e.target.value) }>
                                                    <option value="--">--</option>
                                                    <option value="AM">AM</option>
                                                    <option value="PM">PM</option>
                                                </select>
                                            </div>

                                            <div>End Time</div>
                                            <div class="input-group mb-2">
                                                <input type="number" class="form-control" placeholder="HH" value={ endHour } onChange={ (e) => handleSetHour(e.target.value, 'end') } />

                                                <span class="input-group-text" style={{paddingLeft: '2px', paddingRight: '2px'}}>:</span>
                                                <input type="number" class="form-control" placeholder="MM" value={ endMinute } onChange={ (e) => handleSetMinute(e.target.value, 'end') } />
                                                
                                                <select class="form-select" value={endAmPm} onChange={ (e) => setEndAmPm(e.target.value) }>
                                                    <option value="--">--</option>
                                                    <option value="AM">AM</option>
                                                    <option value="PM">PM</option>
                                                </select>
                                            </div>

                                            <div className="mt-3">
                                                <button className="btn btn-services-gold" onClick={handleSetAndClose} style={{minWidth: '150px'}}>
                                                    <i className="bi bi-check-circle"></i> Set
                                                </button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                </div>
                                <div className="mt-2">
                                    <textarea name="txtNote" id="txtNote" rows="2" maxLength="2000" value={ note } onChange={ (e) => setNote(e.target.value) } placeholder="Note to Service Provider" className="form-control w-100"></textarea>
                                    <h6 className="mt-1">
                                        <TextAreaCounter text={note} maxLength="2000" />
                                    </h6>
                                </div>
                            </div>
                            
                        </div>

                        <hr />

                        <div className="d-flex flex-row">
                            { !isSending && 
                                <button className="btn btn-services-gold w-100" onClick={handleSendBookingRequest}>
                                    <i class="bi bi-book"></i> Send Booking Request
                                </button>
                            }
                            { isSending &&
                                <button className="btn btn-services-gold w-100" type="button" disabled>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Sending ...
                                </button>
                            }
                        </div>
                    </div>
                    
                    <Modal backdrop="static" show={showMessage} onHide={handleCloseMessage} centered className="mobile-modal">
                        <Modal.Body>
                            <h5 className="mb-4 mt-2">{showMessage}</h5>
                            <hr />
                            <div className="d-flex justify-content-end">
                                <Link className="btn btn-success btn-services-gold" to={ messageLink } onClick={handleCloseMessage}>
                                    <i class="bi bi-check-circle"></i> Okay
                                </Link>
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>
            }
        </div>
    );
}
 
export default UserBookService;