import { Helmet } from 'react-helmet';

const TitlesAndDescriptions = ({ page }) => {
    const titles = {
        'home' : 'Trivut Marketplace',
        'legal-menu' : 'Legal Menu | Trivut Marketplace',
        'disclaimer' : 'Disclaimer | Trivut Marketplace',
        'privacy-policy' : 'Privacy Policy | Trivut Marketplace',
        'terms-and-conditions' : 'Terms and Conditions | Trivut Marketplace',
        'product-details' : 'Product Details | Trivut Marketplace',
        'register' : 'Register | Trivut Marketplace',
        'reset-password' : 'Reset Password | Trivut Marketplace',
        'search' : 'Search | Trivut Marketplace',
        'seller-add-product' : 'Add Product | Seller Center | Trivut Marketplace',
        'seller-menu' : 'Menu | Seller Center | Trivut Marketplace',
        'seller-orders' : 'Orders | Seller Center | Trivut Marketplace',
        'seller-products' : 'Products | Seller Center | Trivut Marketplace',
        'seller-shipped-orders' : 'Shipped Orders | Seller Center | Trivut Marketplace',
        'services-menu' : 'Services Menu | Trivut Marketplace',
        'signin' : 'Sign In | Trivut Marketplace',
        'user-addresses' : 'Addresses | Trivut Marketplace',
        'user-change-password' : 'Change Password | Trivut Marketplace',
        'user-checkout' : 'Checkout | Trivut Marketplace',
        'user-menu' : 'User Menu | Trivut Marketplace',
        'user-orders' : 'Orders | Trivut Marketplace',
        'user-payment-options' : 'Payment Options | Trivut Marketplace',
        'user-profile' : 'User Profile | Trivut Marketplace',
        'user-received-orders' : 'Received Orders | Trivut Marketplace',
        'user-security-questions' : 'Security Questions | Trivut Marketplace',
        'user-shipped-orders' : 'Shipped Orders | Trivut Marketplace',
        'user-shopping-cart' : 'Shopping Cart | Trivut Marketplace',
        'user-wish-list-and-favorites' : 'Wish List and Favorites | Trivut Marketplace',
        'error-404' : 'Error 404 - Page Not Found | Trivut Marketplace',
    };
    const descriptions = {
        'home' : 'Trivut Marketplace',
        'legal-menu' : 'Trivut Marketplace',
        'disclaimer' : 'Trivut Marketplace',
        'privacy-policy' : 'Trivut Marketplace',
        'terms-and-conditions' : 'Trivut Marketplace',
        'product-details' : 'Trivut Marketplace',
        'register' : 'Trivut Marketplace',
        'reset-password' : 'Trivut Marketplace',
        'search' : 'Trivut Marketplace',
        'seller-add-product' : 'Trivut Marketplace',
        'seller-menu' : 'Trivut Marketplace',
        'seller-orders' : 'Trivut Marketplace',
        'seller-products' : 'Trivut Marketplace',
        'seller-shipped-orders' : 'Trivut Marketplace',
        'services-menu' : 'Trivut Marketplace',
        'signin' : 'Trivut Marketplace',
        'user-addresses' : 'Trivut Marketplace',
        'user-change-password' : 'Trivut Marketplace',
        'user-checkout' : 'Trivut Marketplace',
        'user-menu' : 'Trivut Marketplace',
        'user-orders' : 'Trivut Marketplace',
        'user-payment-options' : 'Trivut Marketplace',
        'user-profile' : 'Trivut Marketplace',
        'user-received-orders' : 'Trivut Marketplace',
        'user-security-questions' : 'Trivut Marketplace',
        'user-shipped-orders' : 'Trivut Marketplace',
        'user-shopping-cart' : 'Trivut Marketplace',
        'user-wish-list-and-favorites' : 'Trivut Marketplace',
        'error-404' : 'Trivut Marketplace',
    };

    const title = titles[page];
    const metaDescr = descriptions[page];

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescr} />
        </Helmet>
    );
}
 
export default TitlesAndDescriptions;